<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div class="div-box">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->

            <!-- 暂时关闭 -->
            <el-button type="primary" @click="routeraddrClick('/handwithdrawal')">提现</el-button>

            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
  mainthreetableData.slice(
    (currentPage - lastpage) * PageSize,
    currentPage * PageSize
  )
" style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
  mytabletitle.prop === 'status' ||
  mytabletitle.prop === 'payway' ||
  mytabletitle.prop === 'remark' ||
  mytabletitle.prop === 'nickname'
">
                <!-- 提现方式-渲染数据 -->
                <div v-if="mytabletitle.prop === 'payway'">
                  <div>
                    <span>{{ scope.row.payway == 1 ? "微信" : scope.row.payway == 2 ? "支付宝" : "银行卡" }}{{ scope.row.is_auto
    == 1 ? "自动提现" : "手动提现"
}}</span>
                  </div>
                </div>
                <!-- 状态-渲染数据 -->
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.textstatus">
                    <span>{{ scope.row.textstatus }}</span>
                  </div>
                </div>
                <!-- 提现账户-渲染数据 -->
                <div v-if="mytabletitle.prop === 'nickname'">
                  <div v-if="scope.row.Member && scope.row.payway != 2">
                    <span>{{ scope.row.Member.nickname }}</span>
                  </div>
                  <div v-if="(scope.row.payway == 2)">
                    <span>{{ scope.row.Member?.ali_nickname + '-' +  scope.row.Member?.ali_user_id }}</span>
                  </div>
                </div>
                <!-- 处理备注-渲染数据 -->
                <div v-if="mytabletitle.prop === 'remark'">
                  <div>
                    <el-popover placement="top" trigger="hover" title="处理备注" width="400px">
                      <template #reference>
                        <div class="box-table-addr">
                          <span :ref="'btnShowNum' + scope.$index"><span>{{ scope.row.remark }}</span></span>
                        </div>
                      </template>
                      <template #default>
                        <div style="margin-top: 10px">
                          <span>{{ scope.row.remark }}</span>
                        </div>
                      </template>
                    </el-popover>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" :layout="Layout"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vwithdrawalrecord",
  data() {
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "提现记录",
          path: "/withdrawalrecord",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "提现单号查询",
              name: "trade_no",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "申请中",
                  value: "-1",
                },
                {
                  label: "提现成功",
                  value: "1",
                },
                {
                  label: "提现失败",
                  value: "2",
                },
                {
                  label: "处理中",
                  value: "3",
                },
                {
                  label: "已拒绝",
                  value: "4",
                },
              ],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-【筛选查询】-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 33, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 180, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "trade_no",
          label: "提现单号",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "amount",
          label: "提现金额",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "申请时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "finishtime",
          label: "完成时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "payway",
          label: "提现方式",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "nickname",
          label: "提现账户",
          width: "300",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "remark",
          label: "处理备注",
          width: "200",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1000, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      Layout: " sizes, prev, pager, next, jumper",

      //分页
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/withdraw/index",
          {
            rade_no: this.pagesdatas.trade_no,
            status: this.pagesdatas.status,
            thirdparty: this.pagesdatas.thirdparty,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / this.PageSize == this.lastpage) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage += 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/withdraw/index",
          {
            trade_no: this.pagesdatas.trade_no,
            status: this.pagesdatas.status,
            thirdparty: this.pagesdatas.thirdparty,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / val == this.PageSize) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage = this.totalCount / this.PageSize;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch((error) => error);
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/shop/withdraw/index",
          {
            trade_no: this.pagesdatas.trade_no,
            status: this.pagesdatas.status,
            thirdparty: this.pagesdatas.thirdparty,
            export: 1,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
            console.log(response.data.message)
        //   ElMessage({ type: "success", message: response.data.message });
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;

      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/withdraw/index",
          {
            trade_no: data.trade_no,
            status: data.status,
            thirdparty: data.thirdparty,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata, flag) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //渲染-状态
        if (newdata[i].status) {
          if (newdata[i].status == -1) {
            newdata[i].textstatus = "申请中";
          }
          if (newdata[i].status == 1) {
            newdata[i].textstatus = "提现成功";
          }
          if (newdata[i].status == 2) {
            //提现失败，显示失败原因
            newdata[i].textstatus = "提现失败";
          }
          if (newdata[i].status == 3) {
            newdata[i].textstatus = "处理中";
          }
          if (newdata[i].status == 4) {
            newdata[i].textstatus = "已拒绝";
          }
        }

        //转换-申请时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "";
        }
        //转换-完成时间
        if (newdata[i].finishtime) {
          if (newdata[i].finishtime === 0 || newdata[i].finishtime === null) {
            newdata[i].finishtime = "";
          } else {
            newdata[i].finishtime = this.$utils.formatDate(
              newdata[i].finishtime
            );
          }
        } else {
          newdata[i].finishtime = "";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      // this.totalCount = successdata.total;
      // this.currentpage = successdata.current_page; //当前页面页数
      // this.lastpage = successdata.last_page;
      this.loading = false;

      if (successdata.total == null) {
        // 数据小于当前页数时判断
        if (newdata.length < this.PageSize) {
          if (newdata.length != 0 && this.currentPage == 1) {
            this.totalCount = this.PageSize - 1;
          } else {
            if (newdata.length == 0) {
              // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
              // this.totalCount = this.PageSize * (this.currentPage - 1)
              this.totalCount = this.PageSize * this.currentPage;
            } else {
              this.totalCount = this.PageSize * this.currentPage;
              this.lastpage = this.currentPage;
            }
          }
        } else {
          if (this.totalCount < this.PageSize) {
            // 重设置-初始值
            this.totalCount = 1000;
            this.Layout = "sizes, prev, pager, next, jumper";
          } else {
            if (flag == 1) {
              this.currentPage = 1;
            }
          }
        }
      } else {
        this.totalCount = successdata.total;
        this.currentpage = successdata.current_page; //当前页面页数
        this.lastpage = successdata.last_page;
        this.Layout = "total, sizes, prev, pager, next, jumper";
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 初始化-pagesdatas-维护字段
      this.pagesdatas = {
        thirdparty: 1,
        page: 1,
        page_size: this.PageSize,
      };

      //通过Ajax向后台获取数据
      axios
        .post("/shop/withdraw/index", this.pagesdatas, {
          headers: {
            Authen: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-table-addr {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.mytabletext,
.box-table-addr:hover {
  color: var(--el-color-primary);
  cursor: pointer;
}

.div-box {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 20px;
}
</style>