<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity"
      :myselectcityleftisshow="maintwoforminfo.myselectcityleftisshow"
      :myselectcityleft="maintwoforminfo.myselectcityleft" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div class="box-list">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData"
            :default-sort="{ prop: 'date', order: 'descending' }" @sort-change="sortchangeEvent">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width" :sortable="
                mytabletitle.prop == 'client_num' ||
                  mytabletitle.prop == 'total' ||
                  mytabletitle.prop == 'count'
                  ? 'custom'
                  : false
              ">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'bottom_num' ||
                mytabletitle.prop === 'client_num' ||
                mytabletitle.prop === 'total' ||
                mytabletitle.prop === 'count'
              ">
                <!-- 状态-渲染数据 -->
                <div v-if="mytabletitle.prop === 'status'">
                  <span>{{ scope.row.status == 1 ? "启用" : "禁用" }}</span>
                </div>
                <!-- 下级代理数量 :class="scope.row.level == 2 ? 'mytabletext' : ''"-->
                <div v-if="mytabletitle.prop === 'bottom_num'">
                  <div @click="routeraddrClick('/agentcensus', scope.row, 3)"
                    :class="scope.row.level == 2 ? 'mytabletext' : ''">
                    <span>{{ scope.row.bottom_num }}</span>
                  </div>
                </div>

                <!-- 设备数量-渲染数据 -->
                <div v-if="mytabletitle.prop === 'client_num'">
                  <div @click="routeraddrClick('/dvclist', scope.row)" class="mytabletext">
                    <span>{{ scope.row.client_num }}</span>
                  </div>
                </div>

                <!-- 订单金额-渲染数据 -->
                <div v-if="mytabletitle.prop === 'total'">
                  <div @click="routeraddrClick('/orderlist')" class="mytabletext">
                    <span>{{ scope.row.total }}</span>
                  </div>
                </div>

                <!-- 订单数量-渲染数据 -->
                <div v-if="mytabletitle.prop === 'count'">
                  <div @click="routeraddrClick('/orderlist')" class="mytabletext">
                    <span>{{ scope.row.count }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vagentcensus",
  data() {
    return {
      objparam: {},
      mainonebread: [
        {
          name: "统计",
        },
        {
          name: "代理商统计排行",
          path: "/agentcensus",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "代理商名称/手机号",
              name: "keywords",
              content: "",
            },
          ],
        },
        myselectcityleftisshow: true, //筛选查询-selectcity选框
        myselectcityleft: {
          selectwidth: "100%",
          select: {
            ismust: false,
            title: "选择区域：",
            name: "area_code",
            area_code: "",
            valuesheng: "",
            valueshi: "",
            valuequ: "",
            disshi: true,
            disqu: true,
          },
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "name",
          label: "代理商名称",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "username",
          label: "手机号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "bottom_num",
          label: "下级代理",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "client_num",
          label: "设备数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "total",
          label: "订单金额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "count",
          label: "订单数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "area_code",
          label: "所属区域",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "status_name",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "添加时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "代理商名称/手机号",
            name: "keywords",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "状态:",
            placeholder: "全部",
            name: "status",
            value: "",
            options: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "启用",
              },
              {
                value: "-1",
                label: "禁止",
              },
            ],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "添加时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselectcityisshow: true, //高级筛选-selectcity选框
      myselectcity: {
        selectwidth: "100%",
        select: {
          ismust: false,
          name: "area_code",
          area_code: "",
          valuesheng: "",
          valueshi: "",
          valuequ: "",
          disshi: true,
          disqu: true,
        },
      },
      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mytoken: localStorage.getItem("token"), //获取存储的token
      userinfo: JSON.parse(localStorage.getItem("userinfo")), //获取用户信息
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 排序
    sortchangeEvent(e) {
      console.log(e.prop);

      let orderval = "";
      if (e.order == "ascending") {
        orderval = 1;
      } else {
        orderval = -1;
      }

      this.pagesdatas.orderkey = e.prop;
      this.pagesdatas.orderval = orderval;
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/census/censusTop",
          {
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            area_code: this.pagesdatas.area_code,
            addtime: this.pagesdatas.addtime,
            orderkey: e.prop,
            orderval: orderval,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/census/censusTop",
          {
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            area_code: this.pagesdatas.area_code,
            addtime: this.pagesdatas.addtime,
            orderkey: this.pagesdatas.orderkey,
            orderval: this.pagesdatas.orderval,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/census/censusTop",
          {
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            area_code: this.pagesdatas.area_code,
            addtime: this.pagesdatas.addtime,
            orderkey: this.pagesdatas.orderkey,
            orderval: this.pagesdatas.orderval,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata, flag) {
      // 携带参数跳转
      if (rowdata) {
        // 跳转-设备列表
        if (routeraddr == "/dvclist") {
          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id, name: rowdata.name },
            })
            .catch((error) => error);
        }
        // 跳转-代理商统计(本页)
        if (routeraddr == "/agentcensus") {
          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id, flag: flag },
            })
            .catch((error) => error);
        }

      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-添加时间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getaddtime;

      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/census/censusTop",
          {
            keywords: data.keywords,
            status: data.status,
            area_code: data.area_code,
            addtime: data.addtime,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/census/censusTop",
          {
            keywords: data.keywords,
            area_code: data.area_code,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //转换添加时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无添加时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无添加时间";
        }
        // 转换状态
        if (newdata[i].status) {
          if (newdata[i].status == 1) {
            newdata[i].status_name = "启用";
          } else {
            newdata[i].status_name = "禁用";
          }
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 非一级代理商初次进入此页面，下级代理字段列无需显示
      if (this.userinfo.level != 1) {
        let getcontentdata1 = this.mainthreetabletitle.filter(
          (n) => n.prop != "bottom_num"
        );
        this.mainthreetabletitle = getcontentdata1;
      }

      // 定义
      this.pagesdatas = {
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.id) {
        this.pagesdatas = {
          parent_id: routerinfo.id,
          page_size: this.PageSize,
          page: 1,
        };
        if (routerinfo.flag == 3) {
          let getcontentdata1 = this.mainthreetabletitle.filter(
            (n) => n.prop != "bottom_num"
          );
          this.mainthreetabletitle = getcontentdata1;

          // mainonebread头部-导航
          let data = {
            name: "下级代理商统计",
            path: "",
            parameter: { id: routerinfo.id, flag: routerinfo.flag },
          };
          this.mainonebread.push(data);
        }
      }

      //通过Ajax向后台获取数据
      axios
        .post("/shop/census/censusTop", this.pagesdatas, {
          headers: {
            Authen: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}

.box-list {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 20px;
}
</style>
