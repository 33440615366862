<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <el-button type="primary" @click="submitForm(this.multipleSelection, 6)">获取在线时长</el-button>
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'status' || mytabletitle.prop === 'orderNum' || mytabletitle.prop === 'shop_code_name'">
                                <!-- 订单量-样式等渲染 -->
                                <div v-if="mytabletitle.prop === 'orderNum'" @click="routeraddrClick('/orderlist', scope.row)">
                                    <span class="mytabletext">{{ scope.row.orderNum }}</span>
                                </div>
                                <!-- 所属代理-样式等渲染 -->
                                <div v-if="mytabletitle.prop === 'shop_code_name'">
                                    <div @click="routeraddrClick('/agentlist', scope.row)" v-if="userinfo.level != 3">
                                        <span class="mytabletext">{{ scope.row.shop_code_name }}</span>
                                    </div>
                                    <div v-if="userinfo.level == 3">
                                        <span>{{ scope.row.shop_code_name }}</span>
                                    </div>
                                </div>

                                <!-- 状态-样式等渲染 -->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <el-button :type="scope.row.printstatus == 0 ? 'primary' : scope.row.printstatus == 1 ? 'success' : 'danger'" size="mini"
                                        >{{
                                            this.$utils.printStatusData(this.maintwoforminfo.selectleft.select[1].options, scope.row.printstatus != 0 ? scope.row.printstatus : '0')
                                        }}
                                    </el-button>
                                    <el-button :type="scope.row.is_online == 1 ? 'success' : 'danger'" size="mini">{{ scope.row.is_online == 1 ? '在线' : '离线' }}</el-button>
                                    <div v-if="scope.row.switchstatus === 4" id="switch">
                                        <el-switch
                                            v-model="scope.row.status"
                                            active-text="开启"
                                            inactive-text="关闭"
                                            :active-value="1"
                                            :inactive-value="-1"
                                            active-color="#13ce66"
                                            inactive-color="#cbcbcb"
                                        >
                                        </el-switch>
                                    </div>
                                    <div v-if="scope.row.textstatus">
                                        <span>{{ scope.row.textstatus }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-代理商列表-->
                                <div v-if="mainthreetableope === 2">
                                    <!-- 兼容（家庭版）新增家庭成员添加按钮【用is_family字段判断 1显示 2不显示】-->
                                    <el-button type="primary" size="mini" v-if="scope.row.is_family == 1" @click="routeraddrClick('/dvclfamilyedit', scope.row)"
                                        >家庭成员
                                    </el-button>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/dvcedit', scope.row)">编辑</el-button>
                                    <el-button type="primary" size="mini" @click="restore(scope.row)">状态恢复</el-button>
                                    <el-button type="primary" size="mini" @click="reboot(scope.row)">远程重启</el-button>
                                    <el-button type="primary" size="mini" @click="addTanClick(scope.row, 'downcode', (dialogVisible = true))">下载二维码</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-下载二维码-->
            <el-dialog v-model="dialogVisible" title="下载二维码" :width="this.searchwidth" :before-close="handleCloseSenior">
                <div class="dialog-box-flex">
                    <img :src="this.downcodeurl" alt="" style="width: 100%; margin-top: 10px; border: 1px solid #ececec" />
                    <div style="text-align: center">
                        <el-button type="primary" style="margin-top: 20px; width: 50%" @click="downCodeEvent(this.downcodeurl)"> 下载二维码</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vdvclist',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '设备'
                },
                {
                    name: '设备列表',
                    path: '/dvclist'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '230px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '设备编号/所属代理/店铺名',
                            name: 'name',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'is_online',
                            value: '',
                            options: [
                                {
                                    value: '',
                                    label: '全部'
                                },
                                {
                                    value: '1',
                                    label: '在线'
                                },
                                {
                                    value: '-1',
                                    label: '离线'
                                }
                            ]
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '打印机状态：',
                            placeholder: '全部',
                            name: 'printstatus',
                            value: '',
                            options: componentProperties.get('printstatusoption').options
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowset: true, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 2, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 380,
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'client',
                    label: '设备编号',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'storename',
                    label: '店铺名',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'orderNum',
                    label: '订单量',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'shop_code_name',
                    label: '所属代理',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'address',
                    label: '设备地址',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'uptime',
                    label: '版本更新时间',
                    width: '200'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '380px',

            //【下载二维码】弹窗相关数据
            dialogVisible: ref(false), //弹窗显示&隐藏
            downcodeurl: '', // 下载二维码-当前行信息

            mytoken: localStorage.getItem('token'), //获取存储的token
            userinfo: JSON.parse(localStorage.getItem('userinfo')), //获取用户信息
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 下载二维码
        downCodeEvent(url) {
            window.open(url, '_blank')
        },
        // 按钮-[更新程序][更新辅程序][添加云打印设备][远程重启][状态恢复]-提交表单
        submitForm(datas, flag) {
            // 获取并转换批量选中的设备数据
            var selectedIds = []
            for (var m = 0; m < datas.length; m++) {
                selectedIds.push(datas[m].id)
            }
            // 在线时长
            if (flag == 6) {
                if (selectedIds.length > 0 && selectedIds.length < 11) {
                    var data5 = datas.map(n=>{return n.id})
                    console.log(data5)
                    this.routeraddrClick('/dvcisonline', data5.join(','))
                } else {
                    if (selectedIds.length < 1) {
                        ElMessage({
                            type: 'error',
                            message: '至少选择一个设备,再查看在线时长'
                        })
                    }
                    if (selectedIds.length > 10) {
                        ElMessage({
                            type: 'error',
                            message: '最多选择10个设备,再查看在线时长'
                        })
                    }
                }
            }
        },
        //table表单-点击单位格-带参跳转等
        handleOneData(row, column, cell, event) {
            // console.log(row); //获取当前行数据
            // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
            // console.log(event); //获取当前指针事件
            console.log(event.path[1].id) //获取当前指针所指向的单元格id值，配合标签的id使用
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据

            axios
                .get('/shop/Equipment/clientList', {
                    headers: {
                        Authen: localStorage.getItem('token')
                    },
                    params: {
                        name: this.pagesdatas.name,
                        is_online: this.pagesdatas.is_online,
                        printstatus: this.pagesdatas.printstatus,
                        page: 1,
                        page_size: val
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/shop/Equipment/clientList', {
                    headers: {
                        Authen: localStorage.getItem('token')
                    },
                    params: {
                        name: this.pagesdatas.name,
                        is_online: this.pagesdatas.is_online,
                        printstatus: this.pagesdatas.printstatus,
                        page: val,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo, ide) {
            // ide标识- downcode下载二维码
            if (ide == 'downcode') {
                let client = taninfo.client
                let id = taninfo.id
                console.log(client, id)
                axios
                    .get('/shop/Equipment/clientQrcode', {
                        headers: {
                            Authen: localStorage.getItem('token')
                        },
                        params: {
                            client: client,
                            id: id
                        }
                    })
                    .then((response) => {
                        this.downcodeurl = response.data.result.src
                        // this.downcodeurl = 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg';
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata, ide) {
            if (rowdata) {
                // 携带参数跳转
                if (routeraddr === '/dvcedit') {
                    if (ide) {
                        this.$router
                            .push({
                                path: routeraddr,
                                query: {
                                    id: rowdata.id,
                                    goout: ide
                                }
                            })
                            .catch((error) => error)
                    } else {
                        this.$router
                            .push({
                                path: routeraddr,
                                query: {
                                    id: rowdata.id
                                }
                            })
                            .catch((error) => error)
                    }
                }
                // 跳转-设备在线时长
                if (routeraddr === '/dvcisonline') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                ids: rowdata
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-订单列表
                if (routeraddr === '/orderlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                clientnum: rowdata.client
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-代理商
                if (routeraddr === '/agentlist') {
                    // 计算-等级
                    let ids = rowdata.shop_code.split('-').filter((n) => n)
                    let pid = this.userinfo.id
                    let level = this.userinfo.level
                    if (ids.length > 1) {
                        pid = ids[ids.length - 2] // 父id
                        level = ids.length - 1 // 父级等级
                    }
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                name: rowdata.shop_code_end,
                                parent_id: pid,
                                downlevel: level
                            }
                        })
                        .catch((error) => error)
                }

                // 跳转-家庭成员
                if (routeraddr === '/dvclfamilyedit') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                cid: rowdata.id,
                                client: rowdata.client
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            axios
                .get('/shop/Equipment/clientList', {
                    headers: {
                        Authen: localStorage.getItem('token')
                    },
                    params: {
                        name: this.pagesdatas.name,
                        is_online: this.pagesdatas.is_online,
                        printstatus: this.pagesdatas.printstatus,
                        export: 1
                    }
                })
                .then((response) => {
                    console.log(response.data.message)
                    // ElMessage({ type: 'success', message: response.data.message })
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //状态恢复
        restore(data) {
            axios
                .post(
                    '/shop/Equipment/updateD',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data)
                    if (response.data.code == '0') {
                        ElMessage({
                            type: 'success',
                            message: data.client + ' : ' + response.data.message
                        })
                    } else {
                        ElMessage({
                            type: 'warning',
                            message: data.client + ' : ' + response.data.message
                        })
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //远程重启
        reboot(data) {
            axios
                .post(
                    '/shop/Equipment/updateC',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data)
                    if (response.data.code == '0') {
                        ElMessage({
                            type: 'success',
                            message: data.client + ' : ' + response.data.message
                        })
                    } else {
                        ElMessage({
                            type: 'warning',
                            message: data.client + ' : ' + response.data.message
                        })
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 400) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '380px'
            }
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/shop/Equipment/clientList', {
                    headers: {
                        Authen: localStorage.getItem('token')
                    },
                    params: {
                        name: data.name,
                        is_online: data.is_online,
                        printstatus: data.printstatus,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            console.log(successdata)
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //转换-版本更新时间
                if (newdata[i].uptime) {
                    if (newdata[i].uptime === 0 || newdata[i].uptime === null) {
                        newdata[i].uptime = '无添加时间'
                    } else {
                        newdata[i].uptime = this.$utils.formatDate(newdata[i].uptime)
                    }
                } else {
                    newdata[i].uptime = '无添加时间'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            // 判断是否具有routeinfo
            let routeinfo = this.$route.query
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            if (routeinfo.name) {
                this.pagesdatas = {
                    name: routeinfo.name,
                    page: 1,
                    page_size: this.PageSize
                }
                this.maintwoforminfo.myinputone.input[0].content = routeinfo.name
            }

            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            // // 通过Ajax向后台获取数据
            axios
                .get('/shop/Equipment/clientList', {
                    headers: {
                        Authen: localStorage.getItem('token')
                    },
                    params: this.pagesdatas
                })
                .then((response) => {
                    console.log(response)
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>
<style>
.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.dialog-box-flex {
    display: flex;
    flex-direction: column;
    justify-self: center;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>
