<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <!--内容-->
                <div class="box_content" id="switch">
                    <el-tabs
                        v-model="activeName"
                        @tab-click="
                            (tab, event) => {
                                handleClick(tab, event)
                            }
                        "
                    >
                        <!-- 设备详情 -->
                        <el-tab-pane label="设备详情" name="one">
                            <div style="width: 100%; margin-top: 10px">
                                <el-row>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="2" :xl="3">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <!-- 内容 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="15" :xl="15">
                                        <el-form
                                            ref="dvcinfoformdata"
                                            :rules="set_dvcinfo_rules"
                                            :model="this.set_dvcinfo.formdata"
                                            class="demo-ruleForm"
                                            label-width="auto"
                                            :label-position="right"
                                        >
                                            <el-form-item label="设备编号：">
                                                <div
                                                    style="
                            display: line-flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            height: 32px;
                          "
                                                >
                                                    <span style="margin-right: 20px; font-size: 13px">{{ this.set_dvcinfo.formdata.dvcnum }}</span>
                                                    <el-button
                                                        type="text"
                                                        size="small"
                                                        @click="addTanClick(this.set_dvcinfo, 'downcode', null, (this.set_dvcinfo.look_qrcode.dialogVisible = true))"
                                                        >查看二维码</el-button
                                                    >
                                                    <!-- <el-button
                                                        type="text"
                                                        size="small"
                                                        @click="addTanClick(this.set_dvcinfo, 'conductcode', null, (this.set_dvcinfo.look_conduct.dialogVisible = true))"
                                                        >查看宣传码</el-button
                                                    > -->
                                                    <el-button type="primary" plain size="small" @click="addTanClick(this.set_dvcinfo, 'downcode', 'printQrcode')"
                                                        >打印二维码</el-button
                                                    >
                                                    <!-- <el-button type="primary" plain size="small" @click="addTanClick(this.set_dvcinfo, 'conductcode', 'printPub')"
                                                        >打印宣传码</el-button
                                                    > -->
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="Teamviewer远程账号/密码：">
                                                <span style="margin-right: 20px; font-size: 13px">{{ this.set_dvcinfo.formdata.teamviewer }}</span>
                                            </el-form-item>
                                            <el-form-item label="远程控制：">
                                                <el-button type="text" size="small" @click="routeraddrClick('/dvcfrp', this.set_dvcinfo.formdata)">进入远程控制</el-button>
                                            </el-form-item>
                                            <el-form-item label="店铺名称：" prop="shopname">
                                                <el-input v-model="this.set_dvcinfo.formdata.shopname" placeholder="" clearable> </el-input>
                                            </el-form-item>
                                            <el-form-item label="店铺门头：" prop="shopimage">
                                                <mainimgesup
                                                    v-model="this.set_dvcinfo.formdata.shopimage"
                                                    :myimgesup="myimgesup"
                                                    :myimgesupisshow="myimgesupisshow"
                                                    :filelist="this.set_dvcinfo.formdata.shopimage_list"
                                                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                                                    v-on:deluploadfile="(data) => deluploadfileEvent(data, 1)"
                                                ></mainimgesup>
                                            </el-form-item>
                                            <el-form-item label="设备图片：" prop="dvcimage">
                                                <mainimgesup
                                                    v-model="this.set_dvcinfo.formdata.dvcimage"
                                                    :myimgesup="myimgesup2"
                                                    :myimgesupisshow="myimgesupisshow"
                                                    :filelist="this.set_dvcinfo.formdata.dvcimage_list"
                                                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 2)"
                                                    v-on:deluploadfile="(data) => deluploadfileEvent(data, 2)"
                                                ></mainimgesup>
                                            </el-form-item>
                                            <el-form-item label="联系方式：" prop="mobile">
                                                <el-input v-model="this.set_dvcinfo.formdata.mobile" placeholder="" clearable step="1" maxlength="11"> </el-input>
                                            </el-form-item>
                                            <el-form-item label="投放场景：" prop="scene">
                                                <el-select v-model="this.set_dvcinfo.formdata.scene" placeholder="请选择投放场景" style="width: 100%">
                                                    <el-option v-for="(item, index) in this.set_dvcinfo.scene_option" :key="index" :label="item.label" :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="设备地址：" :required="this.set_dvcinfo_rules.dvcaddr[0].required">
                                                <el-form-item label-width="0">
                                                    <el-radio-group v-model="this.set_dvcinfo.formdata.resource" @change="changeRadioEvent">
                                                        <el-radio :label="-1">手动填写地址</el-radio>
                                                        <el-radio :label="1">系统自动更新地址</el-radio>
                                                    </el-radio-group>
                                                </el-form-item>
                                                <el-form-item label-width="0" v-if="this.set_dvcinfo.formdata.resource == -1">
                                                    <!-- <el-form-item label-width="0" prop="dvcaddr" style="height:60px">
                            <mainselect ref="citycode" v-model="this.set_dvcinfo.formdata.dvcaddr"
                              :myselectcityleftisshow="myselectcityisshow" :myselectcity="myselectcity"
                              v-on:getcitycode="getCityCodeEvent"></mainselect>
                          </el-form-item> -->
                                                    <el-form-item label-width="0" prop="dvcaddrdetail">
                                                        <el-input v-model="this.set_dvcinfo.formdata.dvcaddrdetail" placeholder="详细地址" clearable> </el-input>
                                                    </el-form-item>
                                                </el-form-item>
                                            </el-form-item>
                                            <!-- 暂时关闭-停用地图选择按钮 -->
                                            <!-- <el-form-item label="设备地址：" prop="dvcaddr">
                        <div
                          style="
                            display: line-flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            height: 32px;
                          "
                        >
                          <el-button
                            type="primary"
                            plain
                            size="small"
                            style="margin-right: 20px"
                            @click="
                              addTanClick(
                                this.set_dvcinfo,
                                'addrmap',
                                (this.set_dvcinfo.addr_map.dialogVisible = true)
                              )
                            "
                            >地图选址</el-button
                          >
                          <span style="margin-right: 20px; font-size: 13px">{{
                            this.set_dvcinfo.formdata.dvcaddr
                          }}</span>
                        </div>
                        <div style="margin-top: 20px">
                          <el-radio-group
                            v-model="this.set_dvcinfo.formdata.resource"
                            @change="changeRadioEvent"
                          >
                            <el-radio :label="1">系统自动更新地址</el-radio>
                            <el-radio :label="-1">不自动更新地址</el-radio>
                          </el-radio-group>
                        </div>
                      </el-form-item> -->
                                            <el-form-item>
                                                <el-button
                                                    :disabled="this.button_display == 1"
                                                    type="primary"
                                                    style="width: 150px; margin-right: 10px"
                                                    @click="dvcinfoSubmit('dvcinfoformdata')"
                                                    >保存
                                                </el-button>
                                                <el-button @click="cancelEvent()">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="4" :xl="6">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 价格设置 -->
                        <el-tab-pane :disabled="disabledtabs == 1" label="价格设置项" name="two">
                            <div style="width: 100%; margin-top: 10px" id="price_css">
                                <el-form ref="pricetabledata" :model="this.set_price" class="demo-ruleForm" label-width="140px">
                                    <el-form-item label="价格设置：">
                                        <div style="margin-bottom: 10px">
                                            <el-button
                                                type="warning"
                                                plain
                                                size="small"
                                                @click="recoveryPriceEvent(this.set_price.id)"
                                                v-if="this.userinfo.otherAuth.is_save_attr == 1"
                                                >恢复出厂价格</el-button
                                            >
                                        </div>
                                        <div>
                                            <el-table :data="this.set_price.formdata" style="width: 100%" border>
                                                <el-table-column prop="medium" label="打印介质" fixed="left" width="120px">
                                                    <template #default="scope">
                                                        {{ scope.row.medium }}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="price_range" label="价格区间" width="320px">
                                                    <template #default="scope">
                                                        最低
                                                        <el-input v-model="scope.row.price_range.low" placeholder="" style="width: 100px" clearable disabled></el-input>
                                                        ~
                                                        <el-input v-model="scope.row.price_range.height" placeholder="" style="width: 100px" clearable disabled></el-input>
                                                        最高
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    v-for="(mytabletitle, index) in this.set_price.titledata"
                                                    :key="index"
                                                    :prop="mytabletitle.prop"
                                                    :label="mytabletitle.label"
                                                    :width="mytabletitle.width"
                                                >
                                                    <template #default="scope">
                                                        <div v-for="(childrendata, ide) in scope.row.children" :key="ide">
                                                            <div v-if="mytabletitle.prop === 'page_range'">
                                                                <div style="margin: 5px 0">
                                                                    >=
                                                                    <el-input
                                                                        style="width: 100px"
                                                                        v-model="childrendata.page_range"
                                                                        placeholder=""
                                                                        clearable
                                                                        :disabled="childrendata.addisshow ? true : false"
                                                                    ></el-input>
                                                                    页
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'bw_price_along'">
                                                                <div style="margin: 5px 0">
                                                                    <el-input
                                                                        v-if="scope.row.ide != 2 && scope.row.ide != 3 && scope.row.ide != 4"
                                                                        v-model="childrendata.bw_price_along"
                                                                        placeholder=""
                                                                        clearable
                                                                        :disabled="this.userinfo.otherAuth.is_save_attr == 1 ? false : true"
                                                                    ></el-input>
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'bw_price_double'">
                                                                <div style="margin: 5px 0">
                                                                    <el-input
                                                                        v-if="
                                                                            scope.row.ide != 2 &&
                                                                                scope.row.ide != 3 &&
                                                                                scope.row.ide != 4 &&
                                                                                scope.row.ide != 5 &&
                                                                                scope.row.ide != 6
                                                                        "
                                                                        v-model="childrendata.bw_price_double"
                                                                        placeholder=""
                                                                        clearable
                                                                        :disabled="this.userinfo.otherAuth.is_save_attr == 1 ? false : true"
                                                                    ></el-input>
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'colour_price_along'">
                                                                <div style="margin: 5px 0">
                                                                    <el-input
                                                                        v-model="childrendata.colour_price_along"
                                                                        placeholder=""
                                                                        clearable
                                                                        :disabled="this.userinfo.otherAuth.is_save_attr == 1 ? false : true"
                                                                    ></el-input>
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'colour_price_double'">
                                                                <div style="margin: 5px 0">
                                                                    <el-input
                                                                        v-if="
                                                                            scope.row.ide != 2 &&
                                                                                scope.row.ide != 3 &&
                                                                                scope.row.ide != 4 &&
                                                                                scope.row.ide != 5 &&
                                                                                scope.row.ide != 6
                                                                        "
                                                                        v-model="childrendata.colour_price_double"
                                                                        placeholder=""
                                                                        clearable
                                                                        :disabled="this.userinfo.otherAuth.is_save_attr == 1 ? false : true"
                                                                    ></el-input>
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'caozuo'">
                                                                <div style="margin: 5px 0" v-if="this.userinfo.otherAuth.is_save_attr == 1">
                                                                    <el-button type="text" v-if="childrendata.addisshow" @click="addPriceEvent(scope.$index, scope.row.children, 5)"
                                                                        >增加</el-button
                                                                    >
                                                                    <el-button type="text" v-if="!childrendata.addisshow" @click="delPriceEvent(scope.$index, ide)">删除</el-button>
                                                                    <!-- @click="delPriceEvent(scope.$index,scope.row.children)" -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </el-form-item>
                                    <el-form-item style="text-align: center" v-if="this.userinfo.otherAuth.is_save_attr == 1">
                                        <el-button
                                            :disabled="this.button_display == 1"
                                            type="primary"
                                            @click="priceSubmit('pricetabledata')"
                                            style="width: 150px; margin-right: 10px"
                                            >保存</el-button
                                        >
                                        <el-button @click="cancelEvent()">取消</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-tab-pane>
                        <!-- 广告设置 -->
                        <el-tab-pane
                            :disabled="disabledtabs == 1"
                            label="广告设置"
                            name="three"
                            v-if="this.userinfo.otherAuth.is_save_adv == 1 && this.mytype != 'C2' && this.myver < 3"
                        >
                            <div style="width: 100%; margin-top: 10px">
                                <el-form ref="advertabledata" :model="this.set_advert" class="demo-ruleForm">
                                    <el-form-item label="批量设备广告投放展示该设备：">
                                        <div>
                                            {{ this.set_advert.isshowdvc == 1 ? '是' : '否' }}
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="显示代理商广告：">
                                        <div class="myadvert_video">
                                            <div class="myadvert_video_button">
                                                <mainimgesup
                                                    ref="myadvertimges"
                                                    v-model="this.set_advert.agent_advert"
                                                    :myimgesup="agentimgesup"
                                                    :myimgesupisshow="agentimgesupisshow"
                                                    v-on:getuploadfile="(data) => getAdvertUploadFileEvent(data)"
                                                    v-on:deluploadfile="(data) => delAdvertuploadfileEvent(data)"
                                                    v-on:getfiletype="getfiletypeEvent"
                                                ></mainimgesup>
                                            </div>
                                            <div class="myadvert_video_loading" v-if="this.set_advert.videoloading">
                                                <span>正在飞速上传视频中，请耐心等待 <i class="el-icon-loading"></i></span>
                                            </div>
                                        </div>
                                        <!-- 代理商广告 -->
                                        <div>
                                            <el-table :data="set_advert.agent_advert" style="width: 100%">
                                                <el-table-column label="代理商广告（6条）">
                                                    <el-table-column prop="imageurl" label="文件资源" width="100px">
                                                        <template #default="scope">
                                                            <!-- 图片显示 -->
                                                            <div v-if="scope.row.files.file_type !== 'video/mp4'">
                                                                <el-image
                                                                    class="imagesshow"
                                                                    :src="scope.row.files.file_url"
                                                                    :preview-src-list="[scope.row.files.file_url]"
                                                                    fit="cover"
                                                                ></el-image>
                                                            </div>
                                                            <!-- 视频显示 -->
                                                            <div v-else>
                                                                <video
                                                                    :src="scope.row.files.file_url"
                                                                    controls
                                                                    width="80"
                                                                    poster="https://www.jingshuoprint.com/Static/ShopMange/static/h-ui.admin/images/video.png"
                                                                ></video>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="playlangtime" label="播放时长" min-width="100px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.status == '等待上传'">
                                                                {{ scope.row.playlangtime }}
                                                            </div>
                                                            <div v-else>
                                                                <div v-if="scope.row.files.file_type !== 'video/mp4'">
                                                                    <el-select v-model="scope.row.playlangtime" placeholder="">
                                                                        <el-option label="15s" :value="15"></el-option>
                                                                        <el-option label="30s" :value="30"></el-option>
                                                                    </el-select>
                                                                </div>
                                                                <div v-else>{{ scope.row.playlangtime }}s</div>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="status" label="状态">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.status == 0">
                                                                <span style="color: #f56c6c">上传失败</span>
                                                            </div>
                                                            <div v-else-if="scope.row.status == -1">
                                                                <span style="color: #465eff">等待上传</span>
                                                            </div>
                                                            <div v-else-if="scope.row.status == 1">
                                                                <span style="color: #465eff">上传成功</span>
                                                            </div>
                                                            <div v-else>
                                                                <span>已播放</span>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="findmen" label="投放人">
                                                        <template #default="scope">
                                                            {{ scope.row.findmen }}
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="findtime" label="投放时间" min-width="180px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.findtime == 0 || scope.row.findtime == ''">
                                                                <span>永久</span>
                                                            </div>
                                                            <div v-else>
                                                                {{ this.$utils.formatDate(scope.row.findtime) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="stoptime" label="到期时间" min-width="180px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.stoptime == 0 || scope.row.stoptime == ''">
                                                                <span>永久</span>
                                                            </div>
                                                            <div v-else>
                                                                {{ this.$utils.formatDate(scope.row.stoptime) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <!-- 操作 -->
                                                    <el-table-column prop="caozuo" label="操作">
                                                        <template #default="scope">
                                                            <el-button type="danger" size="small" @click="delAdvertEvent(scope.$index, this.set_advert.agent_advert)"
                                                                >删除</el-button
                                                            >
                                                        </template>
                                                    </el-table-column>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="公司及用户广告：">
                                        <!-- 公司及用户广告 -->
                                        <div style="margin-top: 10px">
                                            <el-table :data="set_advert.company_users_advert" style="width: 100%">
                                                <el-table-column label="公司及用户广告（10条）">
                                                    <el-table-column prop="imageurl" label="文件资源" width="100px">
                                                        <template #default="scope">
                                                            <!-- 图片显示 -->
                                                            <div v-if="scope.row.files.file_type !== 'video/mp4'">
                                                                <el-image
                                                                    class="imagesshow"
                                                                    :src="scope.row.files.file_url"
                                                                    :preview-src-list="[scope.row.files.file_url]"
                                                                    fit="cover"
                                                                ></el-image>
                                                            </div>
                                                            <!-- 视频显示 -->
                                                            <div v-else>
                                                                <video
                                                                    :src="scope.row.files.file_url"
                                                                    controls
                                                                    width="80"
                                                                    poster="https://www.jingshuoprint.com/Static/ShopMange/static/h-ui.admin/images/video.png"
                                                                ></video>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="playlangtime" label="播放时长" min-width="100px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.status == -1">
                                                                {{ scope.row.playlangtime }}
                                                            </div>
                                                            <div v-else>
                                                                <div v-if="scope.row.files.file_type !== 'video/mp4'">
                                                                    <el-select v-model="scope.row.playlangtime" placeholder="">
                                                                        <el-option label="15s" value="15"></el-option>
                                                                        <el-option label="30s" value="30"></el-option>
                                                                    </el-select>
                                                                </div>
                                                                <div v-else>{{ scope.row.playlangtime }}s</div>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="status" label="状态">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.status == 1">
                                                                <span>已上传</span>
                                                            </div>
                                                            <div v-else>
                                                                <span>已播放</span>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="findmen" label="投放人">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.is_default == 1">
                                                                <span>系统默认</span>
                                                            </div>
                                                            <div v-else>
                                                                {{ scope.row.findmen }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="findtime" label="投放时间" min-width="180px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.is_default == 1 || scope.row.findtime == 0 || scope.row.findtime == ''">
                                                                <span>永久</span>
                                                            </div>
                                                            <div v-else>
                                                                {{ this.$utils.formatDate(scope.row.findtime) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="stoptime" label="到期时间" min-width="180px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.is_default == 1 || scope.row.stoptime == 0 || scope.row.stoptime == ''">
                                                                <span>永久</span>
                                                            </div>
                                                            <div v-else>
                                                                {{ this.$utils.formatDate(scope.row.stoptime) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <!-- 操作 -->
                                                    <el-table-column prop="caozuo" label=" "> </el-table-column>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </el-form-item>
                                    <el-form-item style="text-align: center">
                                        <el-button
                                            :disabled="this.button_display == 1"
                                            type="primary"
                                            @click="advertSubmit('advertabledata')"
                                            style="width: 150px; margin-right: 10px"
                                            >保存</el-button
                                        >
                                        <el-button @click="cancelEvent()">取消</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-tab-pane>
                        <!-- 会员充值设置 -->
                        <el-tab-pane :disabled="disabledtabs == 1" label="会员充值设置" name="four">
                            <div style="width: 100%; margin-top: 10px">
                                <el-row>
                                    <el-col :span="24">
                                        <el-form ref="membertabledata" :model="this.set_member" class="demo-ruleForm">
                                            <el-form-item label="设备会员：">
                                                <el-radio-group v-model="this.set_member.status">
                                                    <el-radio :label="0">禁用</el-radio>
                                                    <el-radio :label="1" v-if="this.isshowstatus == 1">启用</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item v-if="this.set_member.status == 1">
                                                <el-table
                                                    :row-class-name="tableRowClassName"
                                                    :data="this.set_member.tableData"
                                                    style="width: 100%"
                                                    border
                                                    v-loading="loading"
                                                    element-loading-text="正在飞速加载中..."
                                                >
                                                    <el-table-column
                                                        v-for="(mytabletitle, i) in this.set_member.tableTitle"
                                                        :key="i"
                                                        :fixed="mytabletitle.fixedstatu"
                                                        :prop="mytabletitle.prop"
                                                        :label="mytabletitle.label"
                                                        :width="mytabletitle.width"
                                                    >
                                                        <template
                                                            #default="scope"
                                                            v-if="
                                                                mytabletitle.prop === 'unit' ||
                                                                    mytabletitle.prop === 'num' ||
                                                                    mytabletitle.prop === 'price' ||
                                                                    mytabletitle.prop == 'a4' ||
                                                                    mytabletitle.prop == 'a3' ||
                                                                    mytabletitle.prop == 'a6'
                                                            "
                                                        >
                                                            <div v-if="mytabletitle.prop === 'unit'">
                                                                <el-select v-model="scope.row.unit" placeholder="请选择" style="width: 100%">
                                                                    <el-option label="年" value="年"></el-option>
                                                                    <el-option label="月" value="月"></el-option>
                                                                </el-select>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'num'">
                                                                <el-input v-model="scope.row.num" placeholder="请输入" clearable />
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'price'">
                                                                <el-input v-model="scope.row.price" placeholder="请输入" clearable />
                                                            </div>
                                                            <div v-if="mytabletitle.prop == 'a4'">
                                                                <el-input v-model="scope.row['1']" placeholder="请输入" clearable />
                                                            </div>
                                                            <div v-if="mytabletitle.prop == 'a3'">
                                                                <el-input v-model="scope.row['2']" placeholder="请输入" clearable />
                                                            </div>
                                                            <div v-if="mytabletitle.prop == 'a6'">
                                                                <el-input v-model="scope.row['3']" placeholder="请输入" clearable />
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="caozuo" label="操作" width="80">
                                                        <template #default="scope">
                                                            <el-button type="text" v-if="scope.row.addisshow" @click="addRechargeEvent(this.set_member.tableData)">增加</el-button>
                                                            <el-button type="text" v-if="!scope.row.addisshow" @click="delRechargeEvent(scope.$index, this.set_member.tableData)"
                                                                >删除</el-button
                                                            >
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-form-item>
                                            <el-form-item style="text-align: center">
                                                <el-button
                                                    :disabled="this.button_display == 1"
                                                    type="primary"
                                                    @click="memberSubmit('membertabledata')"
                                                    style="width: 150px; margin-right: 10px"
                                                    >保存</el-button
                                                >
                                                <el-button @click="cancelEvent()">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 推送及报警配置 -->
                        <el-tab-pane :disabled="disabledtabs == 1" label="推送及报警配置" name="five">
                            <div style="width: 100%; margin-top: 10px">
                                <el-row>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="2" :xl="3">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <!-- 内容 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="15" :xl="15">
                                        <el-form ref="alramformdata" :model="this.set_pushalarm.formdata" class="demo-ruleForm" label-width="150px">
                                            <!-- 打印异常推送微信 -->
                                            <el-form-item label="打印异常推送微信：">
                                                <mainwechatmore :uniondata="this.set_pushalarm.formdata.avatar" v-on:getuniondata="getuniondataEvent"></mainwechatmore>
                                            </el-form-item>
                                            <!-- 推送间隔 -->
                                            <el-form-item label="推送间隔：">
                                                <div style="display: flex; flex-direction: column">
                                                    <el-input v-model="this.set_pushalarm.formdata.times" placeholder="" style="max-width: 300px" clearable>
                                                        <template #append>分钟</template>
                                                    </el-input>
                                                    <span style="font-size: 12px; color: #999999">( 不填则只提示一次 )</span>
                                                </div>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button
                                                    :disabled="this.button_display == 1"
                                                    type="primary"
                                                    style="width: 150px; margin-right: 10px"
                                                    @click="savealramSubmit('alramformdata')"
                                                    >保存
                                                </el-button>
                                                <el-button @click="cancelEvent()">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="4" :xl="6">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

        <!--弹出框-[设备详情]-设备地址选择-地图-->
        <el-dialog v-model="this.set_dvcinfo.addr_map.dialogVisible" title="选择设备地址" :width="this.set_dvcinfo.addr_map.searchwidth" :before-close="handleCloseSenior">
            <div class="dialog-box-flex">
                <div id="container" class="map"></div>
            </div>
        </el-dialog>

        <!--弹出框-[设备详情]-查看二维码-->
        <el-dialog v-model="this.set_dvcinfo.look_qrcode.dialogVisible" title="查看二维码" :width="this.set_dvcinfo.look_qrcode.searchwidth" :before-close="handleCloseSenior">
            <div class="dialog-box-flex">
                <div v-if="this.set_dvcinfo.look_qrcode.downcodeurl == ''" style="text-align: center">
                    <i class="el-icon-loading"></i>
                </div>
                <img :src="this.set_dvcinfo.look_qrcode.downcodeurl" alt="" style="width: 100%; margin-top: 10px; border: 1px solid #ececec" />
            </div>
            <div style="margin-top: 20px">
                <el-button type="primary" style="width: 100%" @click="addTanClick(this.set_dvcinfo, 'downcode', 'refresh')"> 刷新二维码</el-button>
            </div>
        </el-dialog>

        <!--弹出框-[设备详情]-查看宣传码-->
        <el-dialog v-model="this.set_dvcinfo.look_conduct.dialogVisible" title="查看宣传码" :width="this.set_dvcinfo.look_conduct.searchwidth" :before-close="handleCloseSenior">
            <div class="dialog-box-flex">
                <div v-if="this.set_dvcinfo.look_conduct.downcodeurl == ''" style="text-align: center">
                    <i class="el-icon-loading"></i>
                </div>
                <img :src="this.set_dvcinfo.look_conduct.downcodeurl" alt="" style="width: 100%; margin-top: 10px; border: 1px solid #ececec" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
// import mainselect from "@/components/Mainselect.vue";
import mainwechatmore from '@/components/Mainwechatmore'
import mainimgesup from '@/components/Mainimgesup.vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { defineComponent, ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'

export default defineComponent({
    name: 'vsharedvccontroledit',
    inject: ['reload'], //刷新引用
    data() {
        // 联系方式-校验
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('联系方式不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入正确的联系方式'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入正确的联系方式'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入正确的联系方式'))
                        } else {
                            if (value < 0) {
                                callback(new Error('请输入正确的联系方式'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        // 文本input框-校验（文本-值中不能有空格）【更新程序包||】
        var checkInputEventWord = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('详细地址不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('详细地址中不能有空格'))
            }
            setTimeout(() => {
                callback()
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '设备'
                },
                {
                    name: '设备列表',
                    path: '/dvclist'
                },
                {
                    name: '编辑设备'
                    // path: "/dvcedit",
                }
            ], // 当前页-【面包屑导航】
            activeName: 'one', //tabs标签-默认显示页

            disabledtabs: 1, //禁用tab标签，1禁用，2启用

            mytype: '', // 特殊判断-当type为C2时，不显示【广告设置】与【打印机配置项】
            myver: '', // 特殊判断-当ver为大于3.0.0.0时，不显示【广告设置】

            //上传-【设备详情】-imgesuploade上传图片
            myimgesupisshow: true,
            //上传-【设备详情】-店铺门头-图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authen: localStorage.getItem('token') }, //upload上传headers
                title: '上传门头图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },
            //上传-【设备详情】-设备图片-图片
            myimgesup2: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authen: localStorage.getItem('token') }, //upload上传headers
                title: '上传设备图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字 建议尺寸690px*440px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },
            // 设备地址-form表单-selectcity选框
            myselectcityisshow: true,
            myselectcity: {
                selectwidth: '100%',
                maxwidth: '100%',
                marginright: '0',
                select: {
                    ismust: true,
                    name: 'area_code',
                    area_code: '',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },
            // 设备详情
            set_dvcinfo: {
                // 【设备详情】formdata提交数据
                formdata: {
                    dvcnum: '',
                    id: '',
                    serialnumber: '',
                    teamviewer: '',
                    vernumber: '',
                    status: {
                        printstatus: 0,
                        is_online: 1
                    },
                    shopname: '',
                    shopimage: '',
                    shopimage_list: [],
                    dvcimage: '',
                    dvcimage_list: [],
                    mobile: '',
                    scene: '1',
                    // dvcaddr: "", // 选中地址
                    dvcaddrdetail: '', // 详细地址
                    lnglat: {},
                    resource: '1'
                },
                // 【设备详情】投放场景-下拉选项
                scene_option: [
                    {
                        value: '1',
                        label: '校园文具店'
                    },
                    {
                        value: '2',
                        label: '社区便利店'
                    },
                    {
                        value: '3',
                        label: '风景游览区'
                    },
                    {
                        value: '4',
                        label: '菜鸟驿站'
                    },
                    {
                        value: '5',
                        label: '照相馆'
                    },
                    {
                        value: '6',
                        label: '图文店'
                    },
                    {
                        value: '7',
                        label: '大中小校园'
                    },
                    {
                        value: '8',
                        label: '其他'
                    }
                ],
                // 【设备详情】- 设备地址-地图
                addr_map: {
                    dialogVisible: ref(false), //弹窗显示&隐藏
                    searchwidth: '1020px'
                },
                // 【设备详情】- 查看二维码-弹出框
                look_qrcode: {
                    dialogVisible: ref(false), //弹窗显示&隐藏
                    searchwidth: '380px',
                    downcodeurl: '' // 下载二维码-当前行信息
                },
                // 【设备详情】- 查看宣传码-弹出框
                look_conduct: {
                    dialogVisible: ref(false), //弹窗显示&隐藏
                    searchwidth: '380px',
                    downcodeurl: '' // 下载二维码-当前行信息
                }
            },
            // 设备详情-验证
            set_dvcinfo_rules: {
                shopname: [
                    {
                        required: true,
                        message: '店铺名称为必填项',
                        trigger: 'change'
                    }
                ],
                shopimage: [
                    {
                        required: false,
                        message: '店铺门头不能为空',
                        trigger: 'change'
                    }
                ],
                dvcimage: [
                    {
                        required: false,
                        message: '设备图片不能为空',
                        trigger: 'change'
                    }
                ],
                mobile: [
                    {
                        required: true,
                        validator: checkPhone,
                        trigger: 'change'
                    }
                ],
                scene: [
                    {
                        required: true,
                        message: '投放场景不能为空',
                        trigger: 'blur'
                    }
                ],
                dvcaddr: [
                    {
                        required: true,
                        message: '设备地址不能为空',
                        trigger: 'blur'
                    }
                ],
                dvcaddrdetail: [
                    {
                        required: true,
                        message: '详细地址不能为空',
                        trigger: 'blur'
                    },
                    {
                        validator: checkInputEventWord,
                        trigger: 'change'
                    }
                ]
            },

            // 价格设置
            set_price: {
                id: '',
                formdata: [
                    {
                        ide: '',
                        medium: '',
                        price_range: {
                            low: '',
                            height: ''
                        },
                        children: [
                            {
                                page_range: '',
                                bw_price_along: '',
                                bw_price_double: '',
                                colour_price_along: '',
                                colour_price_double: '',
                                addisshow: true
                            }
                        ]
                    }
                ],
                // 表单title
                titledata: [
                    {
                        prop: 'page_range',
                        label: '纸张范围',
                        width: '180px'
                    },
                    {
                        prop: 'bw_price_along',
                        label: '黑白价格-单面/页',
                        width: '180px'
                    },
                    {
                        prop: 'bw_price_double',
                        label: '黑白价格-双面/页',
                        width: '180px'
                    },
                    {
                        prop: 'colour_price_along',
                        label: '彩色价格-单面/页',
                        width: '180px'
                    },
                    {
                        prop: 'colour_price_double',
                        label: '彩色价格-双面/页',
                        width: '180px'
                    },
                    {
                        prop: 'caozuo',
                        label: '操作',
                        width: '80px'
                    }
                ]
            },
            // 价格设置-系统外字段-前端维护
            setsystemoutdata: {
                price: {},
                priceCate: {}
            },

            //上传-【广告设置】-imgesuploade上传图片
            agentimgesupisshow: true,
            // 上传-【广告设置】-上传代理商广告
            agentimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'text', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authen: localStorage.getItem('token') }, //upload上传headers
                multiple: true, //上传多个文件
                title: '上传代理商广告', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字
                limit: '0', //上传数量限制
                target_id: 50, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        suffix: ['jpg', 'png'], //文件后缀-用于判断
                        typechart: 'jpg/png' //文件格式提示规则
                    },
                    video: {
                        size: 20000, //文件大小（单位kb）
                        type: ['video/mp4'], //文件格式
                        typechart: 'mp4' //文件格式提示规则
                    }
                }
            },

            // 广告设置-[注意：从后端获取的广告详情具有id字段，自主最新上传的广告是无id字段，以便在提交保存时，将最新上传的做标识0]
            set_advert: {
                id: '5370',
                isshowdvc: '1',
                agent_advert: [],
                company_users_advert: [],
                ads: '', //返给后端的必传参
                videoloading: false //loading缓冲
            },

            // 会员充值设置
            set_member: {
                id: '',
                //会员充值设置-numberkey
                number_key: 0,
                //会员充值设置-设备会员：启用&禁用
                status: ref(0),
                //会员充值设置-title
                tableTitle: [
                    {
                        fixedstatu: false,
                        prop: 'num',
                        label: '数量',
                        width: ''
                    },
                    {
                        fixedstatu: false,
                        prop: 'unit',
                        label: '单位',
                        width: ''
                    },
                    {
                        fixedstatu: false,
                        prop: 'price',
                        label: '价格（元）',
                        width: ''
                    }
                ],
                // 会员充值设置-data -[1:A4,2:A3,3:6寸]
                tableData: [
                    {
                        1: 0,
                        2: 0,
                        3: 0,
                        num: 1,
                        id: 1,
                        unit: '年',
                        price: 1,
                        addisshow: true
                    }
                ]
            },
            // 会员充值设置-状态识别-前端维护
            isshowstatus: null,

            // 推送及报警配置
            set_pushalarm: {
                // 推送及报警配置-data数据
                formdata: {
                    id: '5370',
                    avatar: [
                        // {
                        //   id:1,
                        //   unionid: 1,
                        //   avatar:
                        //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                        //   nickname: "张三1092312",
                        // },
                        // {
                        //   id:2,
                        //   unionid: 2,
                        //   avatar:
                        //     "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                        //   nickname: "张三",
                        // },
                    ],
                    times: '',
                    pagealarm: {
                        // printNumber_tableData: [
                        //   {
                        //     name: "A4黑白",
                        //     allprint: "6000",
                        //     alarmnumber: "1000",
                        //     lastprint: "6000",
                        //   },
                        //   {
                        //     name: "A4彩色",
                        //     allprint: "1000",
                        //     alarmnumber: "160",
                        //     lastprint: "1000",
                        //   },
                        // ],
                        // boxsave_tableData: [
                        //   {
                        //     name: "A4",
                        //     allsave: "250",
                        //     alarmnumber: "50",
                        //     lastsave: "250",
                        //   },
                        //   {
                        //     name: "A6",
                        //     allsave: "46",
                        //     alarmnumber: "10",
                        //     lastsave: "46",
                        //   },
                        // ],
                    }
                }
            },

            loading: false, //初始化-loading加载动效，默认是false关闭
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
            isshow: true, //用于判断页面模块是否存在

            userinfo: JSON.parse(localStorage.getItem('userinfo')), //获取用户信息

            button_display: 1 // 按钮点击状态： 1禁用 2启用
        }
    },
    mounted() {
        window['closes'] = () => {
            this.closes()
        }
    },
    methods: {
        // 点击取消
        cancelEvent() {
            this.$router.go(-1) // 返回上一页
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转
                if (routeraddr === '/dvcisonline') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                ids: rowdata.id
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-远程控制页面
                if (routeraddr === '/dvcfrp') {
                    // // 跳转到-本窗口下的-远程控制页面【方式1】
                    // this.$router
                    //   .push({
                    //     path: routeraddr,
                    //     query: {
                    //       id: rowdata.id,
                    //     },
                    //   })
                    //   .catch((error) => error);

                    // 跳转到-新增窗口下的-远程控制页面【方式2】
                    const { href } = this.$router.resolve({
                        path: routeraddr,
                        query: {
                            id: rowdata.id
                        }
                    })
                    window.open(href, routeraddr)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        // 切换tabs标签页面，点击触发事件
        handleClick(tab) {
            // 设备信息
            if (tab.props.name === 'one') {
                console.log('设备信息')
            } else {
                // 判断是否可切换-未填写完整设备详情-不可切换table
                this.tableHandleClickEvent('dvcinfoformdata')
            }
            //价格设置
            if (tab.props.name === 'two') {
                console.log('价格设置')
            }
            //广告设置
            if (tab.props.name === 'three') {
                console.log('广告设置')
            }
            //
            if (tab.props.name === 'four') {
                console.log('出厂安装设置')
            }
            // 推送及报警配置
            if (tab.props.name === 'five') {
                console.log('推送及报警配置')
            }
        },
        // 切换table判断函数
        tableHandleClickEvent(formName) {
            // this.$refs[formName].validate((valid) => {
            //   if (valid) {
            //     console.log("success submit!!");
            //     this.disabledtabs = 2;
            //   } else {
            //     ElMessage({
            //       type: "warning",
            //       message: "【设备详情】未填写完整，请填写设备详情后切换tab",
            //     });
            //     this.disabledtabs = 1;
            //     return false;
            //   }
            // });

            if (this.set_dvcinfo_rules.dvcaddr[0].required == false) {
                if (formName == 'dvcinfoformdata') {
                    if (this.set_dvcinfo.formdata.shopname == '' || this.set_dvcinfo.formdata.mobile == '' || this.set_dvcinfo.formdata.scene == '') {
                        ElMessageBox.alert('【设备详情】未填写完整，请填写设备详情后切换tab', '温馨提示', {
                            confirmButtonText: '知道了'
                        })
                        this.disabledtabs = 1
                    } else {
                        this.disabledtabs = 2
                    }
                }
            } else {
                if (formName == 'dvcinfoformdata') {
                    if (
                        this.set_dvcinfo.formdata.shopname == '' ||
                        this.set_dvcinfo.formdata.mobile == '' ||
                        this.set_dvcinfo.formdata.scene == '' ||
                        this.set_dvcinfo.formdata.dvcaddr == ''
                    ) {
                        ElMessageBox.alert('【设备详情】未填写完整，请填写设备详情后切换tab', '温馨提示', {
                            confirmButtonText: '知道了'
                        })
                        this.disabledtabs = 1
                    } else {
                        this.disabledtabs = 2
                    }
                }
            }
        },

        // ------------------------------【设备信息】-------------------------
        // 【设备信息】- 获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 400) {
                this.set_dvcinfo.look_qrcode.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.set_dvcinfo.look_qrcode.searchwidth = '380px'
            }
            if (this.sreenwidth < 1040) {
                this.set_dvcinfo.addr_map.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.set_dvcinfo.addr_map.searchwidth = '1020px'
            }
        },
        // 【设备信息】-点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo, ide, action) {
            console.log(taninfo)
            if (ide == 'addrmap') {
                this.getmapEvent(taninfo.formdata.lnglat, taninfo.formdata.dvcaddr)
            }
            if (ide == 'downcode') {
                let paramsdata = {
                    client: taninfo.formdata.dvcnum,
                    id: taninfo.formdata.id
                }
                if (action != null) {
                    paramsdata['action'] = action
                }
                axios
                    .get('/shop/Equipment/clientQrcode', {
                        headers: {
                            Authen: localStorage.getItem('token')
                        },
                        params: paramsdata
                    })
                    .then((response) => {
                        if (action == 'printQrcode') {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '操作成功!'
                                })
                            }
                        } else {
                            this.set_dvcinfo.look_qrcode.downcodeurl = response.data.result.src
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            } else if (ide == 'conductcode') {
                let paramsdata = {
                    client: taninfo.formdata.dvcnum,
                    id: taninfo.formdata.id
                }
                if (action != null) {
                    paramsdata['action'] = action
                }
                axios
                    .get('/shop/Equipment/clientPub', {
                        headers: {
                            Authen: localStorage.getItem('token')
                        },
                        params: paramsdata
                    })
                    .then((response) => {
                        if (action == 'printPub') {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '操作成功!'
                                })
                            }
                        } else {
                            this.set_dvcinfo.look_conduct.downcodeurl = response.data.result.src
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
        },
        // 【设备信息】-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data, ide) {
            console.log('当前获取的回传数据：' + JSON.stringify(data))
            if (ide == 1) {
                this.set_dvcinfo.formdata.shopimage = data.url
                this.set_dvcinfo.formdata.shopimage_list = [
                    {
                        name: data.name,
                        url: data.url
                    }
                ]
            } else {
                this.set_dvcinfo.formdata.dvcimage = data.url
                this.set_dvcinfo.formdata.dvcimage_list = [
                    {
                        name: data.name,
                        url: data.url
                    }
                ]
            }
        },
        //【设备信息】-删除上传的文件-事件（upload组件）
        deluploadfileEvent(data, ide) {
            console.log('当前删除事件-返回的数据：' + JSON.stringify(data))
            if (ide == 1) {
                if (data.length > 0) {
                    this.set_dvcinfo.formdata.shopimage = data
                    this.set_dvcinfo.formdata.shopimage_list = data
                } else {
                    this.set_dvcinfo.formdata.shopimage = ''
                    this.set_dvcinfo.formdata.shopimage_list = data
                }
            } else {
                if (data.length > 0) {
                    this.set_dvcinfo.formdata.dvcimage = data
                    this.set_dvcinfo.formdata.dvcimage_list = data
                } else {
                    this.set_dvcinfo.formdata.dvcimage = ''
                    this.set_dvcinfo.formdata.dvcimage_list = data
                }
            }
        },
        // 【设备信息】-地图-closes
        closes() {
            console.log('关闭')
            document.getElementsByClassName('amap-marker-label')[0].remove()
        },
        // 【设备信息】 - 地图-获取地图函数
        getmapEvent(mylnglat, mydvcaddr) {
            var that = this
            AMapLoader.load({
                key: 'd45b94eb15c85da28f5e2e377d61546d', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.Scale', 'AMap.Geocoder'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                AMapUI: {
                    // 是否加载 AMapUI，缺省不加载
                    version: '1.1', // AMapUI 缺省 1.1
                    plugins: [] // 需要加载的 AMapUI ui插件
                },
                Loca: {
                    // 是否加载 Loca， 缺省不加载
                    version: '1.3.2' // Loca 版本，缺省 1.3.2
                }
            })
                .then((AMap) => {
                    var map = new AMap.Map('container', {
                        zoom: 4, //级别
                        resizeEnable: true
                    })
                    // ------------返显-当前数据库中设备地址-----------------------------
                    var marker = new AMap.Marker()
                    map.add(marker)
                    marker.setPosition(mylnglat)
                    new AMap.InfoWindow({
                        content: mydvcaddr
                    }).open(map, mylnglat)

                    // ----------地图-点击之后-出现新地址信息--------------------------
                    var clickHandler = function(e) {
                        regeoCode(e.lnglat)
                        console.log(e.lnglat)
                    }
                    // var marker = new AMap.Marker();
                    function regeoCode(lnglat) {
                        // 坐标点-定位
                        map.add(marker)
                        marker.setPosition(lnglat)
                        // 通过经纬度-获取中文地址
                        var geocoder = new AMap.Geocoder()
                        geocoder.getAddress(lnglat, function(status, result) {
                            if (status === 'complete' && result.regeocode) {
                                var address = result.regeocode.formattedAddress
                                // 显示-中文地址-在地图上
                                new AMap.InfoWindow({
                                    content: address
                                }).open(map, lnglat)
                                // 赋值-中文地址
                                that.set_dvcinfo.formdata.dvcaddr = address
                                // 赋值-经纬度
                                that.set_dvcinfo.formdata.lnglat = lnglat
                            } else {
                                // 赋值-中文地址
                                that.set_dvcinfo.formdata.dvcaddr = ''
                                // 赋值-经纬度
                                that.set_dvcinfo.formdata.lnglat = {}
                            }
                        })
                    }
                    map.on('click', clickHandler)
                    // ----------------------------------------------------------
                })
                .catch((e) => {
                    console.log(e)
                })
        },
        // 【设备信息】-提交表单-保存设置
        dvcinfoSubmit(formName) {
            var getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(valid)
                    axios
                        .post(
                            '/shop/Equipment/clientEditBase',
                            {
                                id: getdatas.id,
                                storename: getdatas.shopname,
                                storedoor: getdatas.shopimage,
                                clientimg: getdatas.dvcimage,
                                storemobile: getdatas.mobile,
                                push_type: getdatas.scene,
                                auto_up: getdatas.resource,
                                // area_code: getdatas.dvcaddr,
                                address: getdatas.dvcaddrdetail
                                // lng: getdatas.lnglat.lng,
                                // lat: getdatas.lnglat.lat,
                            },
                            {
                                headers: {
                                    Authen: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1); // 返回上一页
                                        let tab = { props: { name: 'one' } }
                                        this.handleClick(tab)
                                        this.reload()
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 【设备信息】-切换地址是否更新事件
        changeRadioEvent(data) {
            console.log(data)
            if (data == 1) {
                // this.set_dvcinfo_rules.dvcaddr[0].required = false;
                this.set_dvcinfo_rules.dvcaddrdetail[0].required = false
            }
            if (data == -1) {
                // this.set_dvcinfo_rules.dvcaddr[0].required = true;
                this.set_dvcinfo_rules.dvcaddrdetail[0].required = true
            }
        },
        // 【设备信息】-设备地址code
        getCityCodeEvent(data) {
            console.log('当前获取的citycode值为：' + data)
            this.set_dvcinfo.formdata.dvcaddr = data
        },
        // 【设备信息】-查询地区的函数-请求省市区接口，并返回相关值
        findAreaEvent(areacode) {
            axios
                .get('/admin/getArea', {
                    headers: {
                        Authen: localStorage.getItem('token')
                    },
                    params: {
                        code: areacode
                    }
                })
                .then((response) => {
                    var getcityname = response.data.result.list.show_name
                    var getarray = getcityname.split(',')
                    if (getarray.length === 3) {
                        this.myselectcity.select.valuesheng = getarray[0]
                        this.myselectcity.select.valueshi = getarray[1]
                        this.myselectcity.select.valuequ = getarray[2]
                    } else {
                        if (getarray.length === 2) {
                            this.myselectcity.select.valuesheng = getarray[0]
                            this.myselectcity.select.valueshi = getarray[1]
                            this.myselectcity.select.valuequ = ''
                        } else {
                            if (getarray.length === 1) {
                                this.myselectcity.select.valuesheng = getarray[0]
                                this.myselectcity.select.valueshi = ''
                                this.myselectcity.select.valuequ = ''
                            }
                        }
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        // ------------------------------【价格设置】-------------------------
        // 【价格设置】-增加行
        addPriceEvent(ide, data, times) {
            let pricerange = this.set_price.formdata[ide].price_range
            // ide: 0 A4文档；1 A3文档；2 照片；3 普通证件照；4 智能证件照；5 证件合成复印；6 扫描
            let bwpricealong = ''
            let bwpricedouble = ''
            let colourpricedouble = ''
            if (ide == 2 || ide == 3 || ide == 4) {
                console.log(pricerange)
                bwpricealong = pricerange.low
                bwpricedouble = pricerange.low
                colourpricedouble = pricerange.low
            }
            if (ide == 5 || ide == 6) {
                bwpricedouble = pricerange.low
                colourpricedouble = pricerange.low
            }

            if (times) {
                if (this.set_price.formdata[ide].children.length < times) {
                    let add = {
                        page_range: data.page_range,
                        bw_price_along: bwpricealong,
                        bw_price_double: bwpricedouble,
                        colour_price_along: '',
                        colour_price_double: colourpricedouble,
                        addisshow: false
                    }
                    // js中通过push向数组最后新增指定的元素
                    this.set_price.formdata[ide].children.push(add)
                } else {
                    ElMessage({
                        type: 'warning',
                        message: '最多增加' + (times - 1) + '组！'
                    })
                }
            } else {
                let add = {
                    page_range: data.page_range,
                    bw_price_along: bwpricealong,
                    bw_price_double: bwpricedouble,
                    colour_price_along: '',
                    colour_price_double: colourpricedouble,
                    addisshow: false
                }
                // js中通过push向数组最后新增指定的元素
                this.set_price.formdata[ide].children.push(add)
            }
        },
        // 【价格设置】-删除行
        delPriceEvent(ide, cide) {
            this.set_price.formdata[ide].children.splice(cide, 1)
        },
        // 【价格设置】-恢复出厂价格
        recoveryPriceEvent(id) {
            console.log(id)
            axios
                .post(
                    '/shop/Equipment/clientEditInitPrice',
                    {
                        id: id
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: '设置成功！',
                            duration: 1000,
                            onClose: () => {
                                this.reload() // 刷新当前页面
                            }
                        })
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 【价格设置】-提交表单-保存设置
        priceSubmit(formName) {
            var getdatas = this.$refs[formName].model
            // console.log(getdatas);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let codes = {}
                    getdatas.formdata.forEach(function(element) {
                        let codedata = []
                        element.children.forEach((cdata) => {
                            let getdata = []
                            let bw = []
                            bw[0] = cdata.bw_price_along * 100
                            bw[1] = cdata.bw_price_double * 100
                            let colour = []
                            colour[0] = cdata.colour_price_along * 100
                            colour[1] = cdata.colour_price_double * 100

                            getdata[0] = bw
                            getdata[1] = colour
                            let data = {
                                p: cdata.page_range,
                                min_price: element.price_range.low * 100,
                                max_price: element.price_range.height * 100,
                                data: getdata
                            }
                            codedata.push(data)
                            if (element.odernum == 1) {
                                codes['1'] = codedata
                            } else {
                                if (element.odernum == 2) {
                                    codes['2'] = codedata
                                } else {
                                    if (element.odernum == 3) {
                                        codes['3'] = codedata
                                    } else {
                                        if (element.odernum == 4) {
                                            codes['4'] = codedata
                                        } else {
                                            if (element.odernum == 5) {
                                                codes['5'] = codedata
                                            } else {
                                                if (element.odernum == 6) {
                                                    codes['6'] = codedata
                                                } else {
                                                    if (element.odernum == 7) {
                                                        codes['7'] = codedata
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        })
                    })
                    // console.log(codes);
                    // [系统外]-前端维护
                    if (this.setsystemoutdata.price != {}) {
                        codes['8'] = this.setsystemoutdata.price['8']
                    }
                    axios
                        .post(
                            '/shop/Equipment/clientEditPrice',
                            {
                                id: getdatas.id,
                                codes: codes
                            },
                            {
                                headers: {
                                    Authen: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1) // 返回上一页
                                        let tab = { props: { name: 'two' } }
                                        this.handleClick(tab)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // ------------------------------【广告设置】-------------------------
        // 【广告设置】-上传前-获取upload子组件回传的数据-filetype数据
        getfiletypeEvent(data) {
            console.log('当前上传前获取的文件类型数据：' + JSON.stringify(data))
            if (data == 'video/mp4') {
                this.set_advert.videoloading = true
            }
        },
        // 【广告设置】-上传成功之后-获取upload子组件回传的数据-file文件url等数据
        getAdvertUploadFileEvent(data) {
            console.log('当前获取Advert的回传数据：' + JSON.stringify(data))
            let geturl = data.url
            let filetype = ''
            let playlangtime = 15
            if (data.type == 'mp4') {
                filetype = 'video/mp4'
                this.set_advert.videoloading = false
                playlangtime = data.fileLen
                geturl = data.res.requestUrls[0]
            } else {
                if (data.type == 'png') {
                    filetype = 'image/png'
                } else {
                    filetype = 'image/jpeg'
                }
            }
            let datas = {
                files: {
                    file_type: filetype,
                    file_url: geturl
                },
                playlangtime: playlangtime,
                status: data.status,
                findmen: this.userinfo.name,
                findtime: Math.round(new Date() / 1000),
                stoptime: 0
            }
            this.set_advert.agent_advert.push(datas)
        },
        //【广告设置】-删除上传的文件-事件（upload组件）
        delAdvertuploadfileEvent(data) {
            console.log('当前删除Advert事件-返回的数据：' + JSON.stringify(data))
        },
        // 【广告设置】-删除-list列表中的行数据
        delAdvertEvent(ide, data) {
            // 由于无法控制最近上传的总文件数量，因此limit限制再删除一个之后自动+1，以保证删除时候能正常上传图片/video,此处为投机取巧，待后续优化
            this.agentimgesup.limit = (parseInt(this.agentimgesup.limit) + 1).toString()
            data.splice(ide, 1)
        },
        // 【广告设置】-提交表单-保存设置
        advertSubmit(formName) {
            var getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(valid)
                    var getimages = []
                    if (getdatas.agent_advert.length > 0) {
                        getdatas.agent_advert.forEach((element) => {
                            let data = ''
                            if (element.id) {
                                data = '1--' + element.id + '--' + element.files.file_url + '--' + element.playlangtime + '--'
                            } else {
                                data = '1--0--' + element.files.file_url + '--' + element.playlangtime + '--'
                            }
                            getimages.push(data)
                        })
                    }
                    if (getdatas.company_users_advert.length > 0) {
                        getdatas.company_users_advert.forEach((element) => {
                            var data1 = ''
                            if (element.id) {
                                data1 = '2--' + element.id + '--' + element.files.file_url + '--' + element.playlangtime + '--'
                            } else {
                                data1 = '2--0--' + element.files.file_url + '--' + element.playlangtime + '--'
                            }
                            getimages.push(data1)
                        })
                    }
                    axios
                        .post(
                            '/shop/Equipment/clientEditAdv',
                            {
                                id: getdatas.id,
                                ads: getdatas.ads,
                                is_mass_advert: getdatas.isshowdvc,
                                images: getimages
                            },
                            {
                                headers: {
                                    Authen: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1) // 返回上一页
                                        let tab = { props: { name: 'three' } }
                                        this.handleClick(tab)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // ------------------------------【会员充值设置】-------------------------
        //【会员充值设置】操作-增加事件
        addRechargeEvent(fromdata, times) {
            if (times) {
                if (fromdata.length < times) {
                    var oneAddChat = {
                        num: '',
                        unit: '',
                        price: '',
                        1: '',
                        2: '',
                        3: ''
                    }
                    // js中通过push向数组最后新增指定的元素
                    fromdata.push(oneAddChat)
                } else {
                    ElMessage({
                        type: 'warning',
                        message: '最多增加' + (times - 1) + '条！'
                    })
                }
            } else {
                var oneAddChats = {
                    num: '',
                    unit: '',
                    price: '',
                    1: '',
                    2: '',
                    3: ''
                }
                // js中通过push向数组最后新增指定的元素
                fromdata.push(oneAddChats)
            }
        },
        //【会员充值设置】操作-删除事件
        delRechargeEvent(ide, formdata) {
            console.log('获取的删除id为：' + JSON.stringify(ide))
            // js中通过splice删除指定的元素
            formdata.splice(ide, 1)
        },
        // 【会员充值设置】-提交表单-保存设置
        memberSubmit(formName) {
            var getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(valid)
                    // 由于最初是用的id，非num,因此为兼容之前的，前端将num与id值统一
                    getdatas.tableData.forEach((element, key) => {
                        // element.id = element.num;
                        // id与键关联-保证唯一
                        element.id = key + 1
                        // (元)转（分）- 价格
                        element.price = element.price * 100
                    })
                    let newdatas = getdatas
                    // 移除多余数据字段
                    delete newdatas.tableData[0].addisshow
                    let ajaxurl = {}
                    // 判断-传参
                    if (newdatas.status == 1) {
                        ajaxurl = {
                            id: getdatas.id,
                            monthly: newdatas.status,
                            monthprice: newdatas.tableData
                        }
                    } else {
                        ajaxurl = {
                            id: getdatas.id,
                            monthly: newdatas.status
                        }
                    }
                    axios
                        .post('/shop/Equipment/clientEditRecharge', ajaxurl, {
                            headers: {
                                Authen: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                // 复位-维护字段
                                getdatas.tableData.forEach((element) => {
                                    // (分)转（元）- 价格
                                    element.price = element.price / 100
                                })
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1) // 返回上一页
                                        let tab = { props: { name: 'four' } }
                                        this.handleClick(tab)
                                    }
                                })
                                getdatas.tableData[0].addisshow = true
                            } else {
                                // 复位-维护字段
                                getdatas.tableData[0].addisshow = true
                                getdatas.tableData.forEach((element) => {
                                    // (分)转（元）- 价格
                                    element.price = element.price / 100
                                })
                            }
                        })
                        .catch(function(error) {
                            getdatas.tableData[0].addisshow = true
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // ------------------------------【推送及报警配置】-------------------------
        // 【推送及报警配置】-获取（选择微信）返回的数据
        getuniondataEvent(data) {
            console.log(data)
            this.set_pushalarm.formdata.avatar = data
        },
        // 【推送及报警配置】-提交表单-保存设置
        savealramSubmit(formName) {
            var getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let uids = []
                    getdatas.avatar.forEach((element) => {
                        uids.push(element.id)
                    })
                    axios
                        .post(
                            '/shop/Equipment/clientEditShop',
                            {
                                id: getdatas.id,
                                uids: uids,
                                space: getdatas.times
                            },
                            {
                                headers: {
                                    Authen: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1) // 返回上一页
                                        let tab = { props: { name: 'five' } }
                                        this.handleClick(tab)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        }
    },
    created() {
        this.button_display = 1
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            // 获取所有的详情信息-并分类
            var p1 = axios
                .get('/shop/Equipment/clientEditInfo', {
                    headers: {
                        Authen: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        // 存储所有信息到alldvcinfo
                        this.alldvcinfo = response.data.result.client
                        var getdata = response.data.result.client

                        // 特殊判断-当type为C2时，不显示【广告设置】
                        this.mytype = getdata.type
                        // 特殊判断-当ver为大于3.0.0.0时，不显示【广告设置】
                        if (getdata.ver && getdata.ver != '' && getdata.ver != null) {
                            this.myver = parseInt(getdata.ver.substr(0, 1))
                        }

                        // 前端自组分类form表单
                        // 设备详情
                        this.set_dvcinfo.formdata = {
                            dvcnum: getdata.client,
                            id: getdata.id,
                            serialnumber: getdata.serial_number,
                            teamviewer: getdata.timeview,
                            vernumber: getdata.ver,
                            status: {
                                printstatus: getdata.printstatus,
                                is_online: getdata.is_online
                            },
                            shopname: getdata.storeinfo.storename,
                            shopimage: getdata.storeinfo.storedoor,
                            shopimage_list:
                                getdata.storeinfo.storedoor.length > 0
                                    ? [
                                          {
                                              name: '',
                                              url: getdata.storeinfo.storedoor
                                          }
                                      ]
                                    : [],
                            dvcimage: getdata.storeinfo.clientimg,
                            dvcimage_list:
                                getdata.storeinfo.clientimg.length > 0
                                    ? [
                                          {
                                              name: '',
                                              url: getdata.storeinfo.clientimg
                                          }
                                      ]
                                    : [],
                            mobile: getdata.storeinfo.storemobile,
                            scene: typeof getdata.push_type == 'number' ? (getdata.push_type != 0 ? getdata.push_type.toString() : '') : getdata.push_type,
                            // dvcaddr: getdata.area_code,
                            dvcaddrdetail: getdata.address,
                            resource: getdata.auto_up
                            // lnglat: {
                            //   Q: getdata.lat,
                            //   R: getdata.lng,
                            //   lng: getdata.lng, //经度
                            //   lat: getdata.lat, //纬度
                            // },
                        }
                        this.changeRadioEvent(getdata.auto_up)
                        // 设备详情-省市区的返显
                        this.findAreaEvent(getdata.area_code)
                        // 【设备详情】tabs判断-判断其是否填写完整设备详情，若填写完整-disabledtabs=2;若未填写完整-disabledtabs=1且切换到设备详情页面
                        this.tableHandleClickEvent('dvcinfoformdata')
                        if (this.disabledtabs == 1) {
                            this.activeName = 'one'
                        }

                        // 价格设置项
                        let price_set_info = []
                        if (getdata.price) {
                            let getkeys = Object.keys(getdata.price)
                            getkeys.forEach((element) => {
                                if (element != '8') {
                                    let nowdata = getdata.price[element]
                                    let getmedium = ''
                                    let getide = ''
                                    if (element == '1') {
                                        getmedium = 'A4文档'
                                        getide = '0'
                                    } else {
                                        if (element == '2') {
                                            getmedium = 'A3文档'
                                            getide = '1'
                                        } else {
                                            if (element == '3') {
                                                getmedium = '照片'
                                                getide = '2'
                                            } else {
                                                if (element == '4') {
                                                    getmedium = '普通证件照'
                                                    getide = '3'
                                                } else {
                                                    if (element == '5') {
                                                        getmedium = '智能证件照'
                                                        getide = '4'
                                                    } else {
                                                        if (element == '6') {
                                                            getmedium = '证件合成复印'
                                                            getide = '5'
                                                        } else {
                                                            if (element == '7') {
                                                                getmedium = '扫描'
                                                                getide = '6'
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    // 定义data
                                    let data = {}
                                    // 标识
                                    data.odernum = element
                                    // 打印介质名&打印介质标识
                                    data.medium = getmedium
                                    data.ide = getide
                                    // 区间价格
                                    data.price_range = {
                                        low: nowdata[0].min_price / 100,
                                        height: nowdata[0].max_price / 100
                                    }
                                    // 纸张范围&&黑白&&彩色价格等children
                                    let children = []
                                    nowdata.forEach((priceinfo, keys) => {
                                        let newchildren = {
                                            page_range: priceinfo.p,
                                            bw_price_along: priceinfo.data[0][0] / 100,
                                            bw_price_double: priceinfo.data[0][1] / 100,
                                            colour_price_along: priceinfo.data[1][0] / 100,
                                            colour_price_double: priceinfo.data[1][1] / 100,
                                            addisshow: keys == 0 ? true : false
                                        }
                                        children.push(newchildren)
                                    })
                                    data.children = children
                                    // 将数据push入数组
                                    price_set_info.push(data)
                                } else {
                                    // 【系统外】-前端维护
                                    this.setsystemoutdata.price['8'] = getdata.price['8']
                                    this.setsystemoutdata.priceCate['8'] = getdata.priceCate['8']
                                    console.log(this.setsystemoutdata)
                                }
                            })
                        }
                        this.set_price.id = getdata.id
                        this.set_price.formdata = price_set_info

                        // 广告设置
                        var agent_advert_images = []
                        var company_users_advert_images = []
                        var agentimgeslimit = 0
                        getdata.image.forEach((element) => {
                            let cutout = element.file.split('.')
                            let suffix = cutout[cutout.length - 1]
                            var filetype = ''
                            if (suffix == 'mp4') {
                                filetype = 'video/mp4'
                            } else {
                                filetype = 'image/jpeg || image/png'
                            }
                            if (element.intype == 1) {
                                // 已有代理商广告条数
                                agentimgeslimit += 1

                                let datas = {
                                    id: element.id,
                                    files: {
                                        file_type: filetype,
                                        file_url: element.file
                                    },
                                    playlangtime: element.play_unit_times,
                                    status: element.statusname,
                                    findmen: element.user,
                                    findtime: element.begin_time,
                                    stoptime: element.end_time
                                }
                                agent_advert_images.push(datas)
                            } else {
                                let mydatas = {
                                    id: element.id,
                                    files: {
                                        file_type: filetype,
                                        file_url: element.file
                                    },
                                    playlangtime: element.play_unit_times,
                                    status: element.statusname,
                                    findmen: element.user,
                                    findtime: element.begin_time,
                                    stoptime: element.end_time,
                                    is_default: element.is_default //is_default判断投放人是否为系统默认
                                }
                                company_users_advert_images.push(mydatas)
                            }
                        })
                        this.agentimgesup.limit = (6 - agentimgeslimit).toString() //代理商广告剩余上传数量【注：upload中的limit只有是string类型才不会出现bug】
                        this.set_advert = {
                            id: getdata.id,
                            isshowdvc: getdata.is_mass_advert,
                            agent_advert: agent_advert_images,
                            company_users_advert: company_users_advert_images,
                            ads: getdata.ads //存储ads值
                        }

                        // 会员充值设置
                        if (getdata.monthlyConfig == [] || getdata.monthlyConfig == null) {
                            console.log(getdata.monthlyConfig)
                            this.set_member.status = getdata.monthly
                            this.set_member.id = getdata.id
                            this.set_member.tableTitle = [
                                {
                                    fixedstatu: false,
                                    prop: 'num',
                                    label: '数量',
                                    width: ''
                                },
                                {
                                    fixedstatu: false,
                                    prop: 'unit',
                                    label: '单位',
                                    width: ''
                                },
                                {
                                    fixedstatu: false,
                                    prop: 'price',
                                    label: '价格（元）',
                                    width: ''
                                },
                                {
                                    fixedstatu: false,
                                    prop: 'a4',
                                    label: 'a4（页数）',
                                    width: ''
                                },
                                {
                                    fixedstatu: false,
                                    prop: 'a3',
                                    label: 'a3（页数）',
                                    width: ''
                                },
                                {
                                    fixedstatu: false,
                                    prop: 'a6',
                                    label: 'a6照片（页数）',
                                    width: ''
                                }
                            ]
                            this.set_member.tableData = [
                                {
                                    1: '',
                                    2: '',
                                    3: '',
                                    num: '',
                                    unit: '',
                                    price: '',
                                    addisshow: true
                                }
                            ]
                        } else {
                            if (getdata.monthlyConfig.length == 0) {
                                this.set_member.status = getdata.monthly
                                this.set_member.id = getdata.id
                                this.set_member.tableTitle = [
                                    {
                                        fixedstatu: false,
                                        prop: 'num',
                                        label: '数量',
                                        width: ''
                                    },
                                    {
                                        fixedstatu: false,
                                        prop: 'unit',
                                        label: '单位',
                                        width: ''
                                    },
                                    {
                                        fixedstatu: false,
                                        prop: 'price',
                                        label: '价格（元）',
                                        width: ''
                                    },
                                    {
                                        fixedstatu: false,
                                        prop: 'a4',
                                        label: 'a4（页数）',
                                        width: ''
                                    },
                                    {
                                        fixedstatu: false,
                                        prop: 'a3',
                                        label: 'a3（页数）',
                                        width: ''
                                    },
                                    {
                                        fixedstatu: false,
                                        prop: 'a6',
                                        label: 'a6照片（页数）',
                                        width: ''
                                    }
                                ]
                                this.set_member.tableData = [
                                    {
                                        1: '',
                                        2: '',
                                        3: '',
                                        num: '',
                                        unit: '',
                                        price: '',
                                        addisshow: true
                                    }
                                ]
                            } else {
                                // (分)转（元）- 价格
                                getdata.monthlyConfig.forEach((element) => {
                                    element.price = element.price / 100
                                })
                                let arrary = []
                                Object.keys(getdata.monthlyConfig[0]).forEach(function(value) {
                                    if (value != 'addisshow') {
                                        if (value != 'num' && value != 'unit' && value != 'price' && value != 'id') {
                                            if (value == '3') {
                                                let addtabledatas = {
                                                    fixedstatu: false,
                                                    prop: 'a6',
                                                    label: '6寸照片（页数）',
                                                    width: ''
                                                }
                                                arrary.push(addtabledatas)
                                            } else {
                                                if (value == '2') {
                                                    let addtabledatas = {
                                                        fixedstatu: false,
                                                        prop: 'a3',
                                                        label: 'A3（页数）',
                                                        width: ''
                                                    }
                                                    arrary.push(addtabledatas)
                                                } else {
                                                    if (value == '1') {
                                                        let addtabledatas = {
                                                            fixedstatu: false,
                                                            prop: 'a4',
                                                            label: 'A4（页数）',
                                                            width: ''
                                                        }
                                                        arrary.push(addtabledatas)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })
                                this.set_member.status = getdata.monthly
                                this.set_member.id = getdata.id
                                this.set_member.tableTitle = this.set_member.tableTitle.concat(arrary)
                                getdata.monthlyConfig[0].addisshow = true
                                this.set_member.tableData = getdata.monthlyConfig
                            }
                        }
                        // 会员充值设置-状态识别-前端维护
                        this.isshowstatus = this.set_member.status

                        // 推送及报警配置
                        this.set_pushalarm.formdata = {
                            id: getdata.id,
                            avatar: getdata.avatar,
                            times: getdata.space
                        }

                        console.log(response.data.result.client)
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })

            Promise.all([p1])
                .then(() => {
                    setTimeout(() => {
                        this.button_display = 2
                    }, 500)
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                })
        }
    },
    components: {
        mainone,
        mainwechatmore,
        mainimgesup
        // mainselect
    }
})
</script>
<style scoped>
.dialog-box-flex {
    display: flex;
    flex-direction: column;
    justify-self: center;
}

#price_css :deep(.el-table .cell) {
    line-height: 50px;
}

.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

.box_content :deep(.el-form-item__error) {
    position: static !important;
}

.tan-table-status {
    display: flex;
    flex-direction: row;
}

.print_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.print_box_info {
    max-width: 140px;
    margin: 0 20px 5px 0;
}

/* 广告设置-上传video视频-缓冲提示 */
.myadvert_video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.myadvert_video_button {
    margin-right: 20px;
    margin-bottom: 10px;
}

.myadvert_video_loading {
    margin-right: 20px;
    margin-bottom: 10px;
    color: red;
}

/* 地图 */
.map {
    width: 100%;
    height: 500px;
    margin-top: 10px;
    border-radius: 4px;
}
</style>
