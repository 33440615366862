<template>
    <div>
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font" style="color: #f56c6c">当前订单状态 : {{ mainpagedata.datas.pay_status_name }}</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px"></maindetails>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details1" style="margin-top: 20px">
                        <template #contentJumpbt="{ detailsdatainfo }">
                            <div v-if="this.printstatus == 1" style="color: #465eff; cursor: pointer" @click="addTanClick(getrowdata, (dialogVisible = true))">
                                {{ detailsdatainfo.jumpbt }}
                            </div>
                            <div v-if="this.printstatus == 2">
                                {{ detailsdatainfo.jumpbt }}
                            </div>
                        </template>
                    </maindetails>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details2"></maindetails>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details3"></maindetails>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>

        <!--弹出框-打印记录-->
        <el-dialog v-model="dialogVisible" title="打印记录" :width="this.searchwidth" :before-close="handleCloseSenior">
            <div>
                <el-table :data="formdata" style="width: 100%" border>
                    <el-table-column label="文档信息" prop="file">
                        <template #default="scope">
                            <div>
                                <div style="font-size: 16px; color: #333333; margin: 10px 0">
                                    {{ scope.row.file.filename }}
                                </div>
                                <div style="font-size: 13px; color: #999999">打印范围 {{ scope.row.file.start }}~{{ scope.row.file.end }} 页 {{ scope.row.file.number }} 份</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="打印状态" prop="status" width="150">
                        <template #default="scope">
                            <div>
                                <div
                                    :style="{
                                        color: scope.row.status == '待打印' ? '#f56c6c' : '#67c23a'
                                    }"
                                >
                                    {{ scope.row.status }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import maindetails from '@/components/Maindetails'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'orderinfo',
    data() {
        return {
            mainpagedata: {
                mainonebread: [
                    {
                        name: '订单'
                    },
                    {
                        name: '订单列表',
                        path: '/orderlist'
                    },
                    {
                        name: '订单详情',
                        path: '/orderlistinfo'
                    }
                ], // 当前页-【面包屑导航】
                datas: {
                    pay_status_name: ''
                },
                details: {
                    showstatus: 1, //确定样式模块
                    title: '订单详情',
                    button: {
                        isshow: false,
                        name: '查看订单详情'
                    },
                    content: [
                        {
                            name: '订单编号:',
                            value: ''
                        },
                        {
                            name: '订单状态:',
                            value: ''
                        },
                        {
                            name: '设备编号:',
                            value: ''
                        },
                        {
                            name: '代理商:',
                            value: ''
                        },
                        {
                            name: '平台来源:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                details1: {
                    showstatus: 1, //确定样式模块
                    title: '打印详情',
                    button: {
                        isshow: false,
                        name: '查看打印详情'
                    },
                    content: [
                        {
                            name: '打印状态:',
                            value: ''
                        },
                        {
                            name: '打印分类:',
                            value: ''
                        },
                        {
                            name: '打印类型:',
                            value: ''
                        },
                        {
                            name: '打印参数:',
                            value: ''
                        },
                        {
                            name: '打印费:',
                            value: ''
                        },
                        {
                            name: '文档使用费:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                details2: {
                    showstatus: 1, //确定样式模块
                    title: '支付详情',
                    button: {
                        isshow: false,
                        name: '查看支付详情'
                    },
                    content: [
                        {
                            name: '优惠方式:',
                            value: ''
                        },
                        {
                            name: '优惠金额:',
                            value: ''
                        },
                        {
                            name: '实付金额:',
                            value: ''
                        },
                        {
                            name: '分佣金额:',
                            value: ''
                        },
                        {
                            name: '支付方式:',
                            value: ''
                        },
                        {
                            name: '支付时间:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                details3: {
                    showstatus: 1, //确定样式模块
                    title: '用户详情',
                    button: {
                        isshow: false,
                        name: '查看用户详情'
                    },
                    content: [
                        {
                            name: '用户手机号:',
                            value: ''
                        },
                        {
                            name: '用户ID:',
                            value: ''
                        },
                        {
                            name: '创建时间:',
                            value: ''
                        }
                    ]
                } //关于详情信息的组件
            }, // 当前Pagedata所需的data数据与控制

            // 打印中-弹窗
            dialogVisible: false,
            searchwidth: '580px',
            formdata: [
                {
                    file: {
                        filename: 'filename123',
                        start: '1',
                        end: '3',
                        number: 1
                    },
                    status: '待打印'
                },
                {
                    file: {
                        filename: 'filename12',
                        start: '1',
                        end: '20',
                        number: 3
                    },
                    status: '已打印'
                }
            ],
            // 打印中-状态-维护字段-【当数据为空时，打印状态不可点击,1可以点击,2不可点击】
            printstatus: 1,

            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: true
        }
    },
    methods: {
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/shop/shareorder/info',
                    {
                        trade_no: this.$route.query.orderno,
                        platform: this.$route.query.platform
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.mainpagedata.datas = response.data.result
                    var content1 = [] //订单详情
                    content1 = [
                        {
                            name: '订单编号:',
                            value: response.data.result.trade_no
                        },
                        {
                            name: '订单状态:',
                            value: response.data.result.pay_status_name
                        },
                        {
                            name: '设备编号:',
                            value: response.data.result.ShareClient.client
                        },
                        {
                            name: '代理商:',
                            value: response.data.result.shop_user_name
                        },
                        {
                            name: '平台来源:',
                            value: response.data.result.thirdparty_plateform
                        }
                    ]
                    this.mainpagedata.details.content = content1

                    var content2 = [] //打印详情
                    content2 = [
                        {
                            name: '打印状态:',
                            value: '',
                            jumpbt: response.data.result.print_status_name + ' >>'
                        },
                        {
                            name: '打印分类:',
                            value: response.data.result.SharePrintCate.printtype_name
                        },
                        {
                            name: '打印类型:',
                            value: response.data.result.SharePrintCate.aliasname
                        },
                        {
                            name: '总份数:',
                            value: response.data.result.countnumber + '份'
                        },
                        {
                            name: '总页数:',
                            value: response.data.result.countpages + '页'
                        },
                        {
                            name: '打印费(含服务费):',
                            value: response.data.result.printtotal
                        },
                        {
                            name: '文档使用费:',
                            value: response.data.result.mobetotal
                        },
                        {
                            name: '三方服务费:',
                            value: response.data.result.mattingprice
                        },
                        {
                            name: '设备会员:',
                            value: response.data.result.monthlytotal != null ? response.data.result.monthlytotal / 100 : ''
                        }
                    ]
                    // 当总份数为0时，不显示
                    if (response.data.result.countnumber == 0) {
                        content2 = content2.filter((n) => n.name != '总份数:')
                    }
                    this.mainpagedata.details1.content = content2

                    var content3 = [] //支付详情
                    content3 = [
                        {
                            name: '优惠方式:',
                            value: response.data.result.discount_type_name
                        },
                        {
                            name: '优惠金额:',
                            value: response.data.result.discount_price.length > 0 ? response.data.result.discount_price.join('\n') : ''
                        },
                        {
                            name: '实付金额:',
                            value: response.data.result.sftotal
                        },
                        {
                            name: '实付打印费(含设备会员和服务费):',
                            value: response.data.result.totalFenyong
                        },
                        {
                            name: '分佣金额:',
                            value: response.data.result.fenyong
                        },
                        {
                            name: '支付方式:',
                            value: response.data.result.paytype_name
                        },
                        {
                            name: '支付时间:',
                            value: response.data.result.pay_time != null ? this.$utils.formatDate(response.data.result.pay_time) : ''
                        }
                    ]
                    this.mainpagedata.details2.content = content3

                    var content4 = [] //用户详情
                    content4 = [
                        {
                            name: '用户手机号:',
                            value: response.data.result.mobile
                        },
                        {
                            name: '用户ID:',
                            value: response.data.result.uid
                        },
                        {
                            name: '创建时间:',
                            value: response.data.result.addtime != null ? this.$utils.formatDate(response.data.result.addtime) : ''
                        }
                    ]
                    this.mainpagedata.details3.content = content4

                    // 显示-弹窗-打印记录信息
                    let printinfo = response.data.result.ShareOrderFile
                    let nowform = []
                    if (printinfo.length > 0) {
                        printinfo.forEach((element) => {
                            let datas = {
                                file: {
                                    filename: element.file_name,
                                    start: element.start,
                                    end: element.end,
                                    number: element.number
                                },
                                status: element.status_name
                            }
                            nowform.push(datas)
                        })
                    }
                    this.formdata = nowform
                    // 打印记录显示与否判断-维护点击状态字段
                    if (nowform.length > 0) {
                        this.printstatus = 1
                    } else {
                        this.printstatus = 2
                    }

                    this.loading = false
                })
                .catch(function (error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails
    }
})
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    /*line-height: 60px;*/
    padding: 10px 30px;
    margin-top: 20px;
    margin-bottom: 80px;
}

.box_title {
    line-height: 40px;
    height: 40px;
    margin-top: 10px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #616e9d;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
