<template>
    <div class="qiwei-qrcode-container">
        <div class="qrcode-container">
            <img class="qrcode" :src="qrcodeUrl" />
            <div class="refresh" @click="refresh"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        qrcodeUrl: String,
        refresh:Function,
    }
}
</script>
<style scoped>
.qiwei-qrcode-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
}

.qrcode-container {
    width: 840px;
    height: 512px;
    border-radius: 8px;
    background: url('https://dev-test-yinboshi.oss-cn-beijing.aliyuncs.com/JSMERCHANT/qiwei-bg.jpg') no-repeat;
    background-size: 100% 100%;
    position: relative;
}
.qrcode-container .qrcode {
    position: absolute;
    top: 160px;
    left: 75px;
    width: 200px;
    height: 200px;
}
.qrcode-container .refresh {
    position: absolute;
    width: 250px;
    height: 50px;
    top: 430px;
    left: 40px;
    cursor: pointer;
}
</style>
