<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>

    <el-row :gutter="16">
      <!-- 账户概览 -->
      <el-col :span="16" :xs="24" :sm="24" :md="16" :lg="17" :xl="18">
        <!--标题-->
        <div class="box">
          <div class="box_title" style="">
            <div class="box_title_font">账户概览</div>
            <div class="box_title_button"></div>
          </div>
          <!--内容-->
          <div class="box_content">
            <div class="box_content_flex">
              <div class="flex_box">
                <div style="width: 320px">
                  <mainecharts
                    ref="mypanel"
                    :mypanelisshow="mypanelisshow"
                    :mypaneldata="mypanel"
                  ></mainecharts>
                </div>
                <div class="money_box">
                  <span class="money_box_title">未提现金额(元)</span>
                  <div
                    class="hoverhand money_box_content"
                    @click="routeraddrClick('/statementdetails')"
                  >
                    <div class="money_box_content_div">
                      <span class="money_box_content_div_span">{{
                        this.adminoverview.apply_money
                      }}</span>
                    </div>
                    <div style="margin-top: 5px">
                      <img
                        src="@/assets/imgs/account/jiantou_black.png"
                        alt=""
                        style="width: 18px; height: 18px"
                      />
                    </div>
                  </div>
                  <div class="money_box_button">
                    <!-- 暂时关闭 -->
                    <el-button
                      type="primary"
                      plain
                      size="small"
                      @click="routeraddrClick('/handwithdrawal')"
                      >提现</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="flex_box">
                <div style="width: 320px">
                  <mainecharts
                    ref="mypanel1"
                    :mypanelisshow="mypanelisshow"
                    :mypaneldata="mypanel1"
                  ></mainecharts>
                </div>
                <div class="money_box">
                  <span class="money_box_title">已提现金额(元)</span>
                  <div
                    class="hoverhand money_box_content"
                    @click="routeraddrClick('/withdrawalrecord')"
                  >
                    <div class="money_box_content_div">
                      <span class="money_box_content_div_span">{{
                        this.adminoverview.withdraw_money
                      }}</span>
                    </div>
                    <div style="margin-top: 5px">
                      <img
                        src="@/assets/imgs/account/jiantou_black.png"
                        alt=""
                        style="width: 18px; height: 18px"
                      />
                    </div>
                  </div>
                  <div class="money_box_payinfo">
                    <div class="hoverhand">
                      <span>微信：</span
                      ><span
                        >&nbsp; &nbsp;¥
                        {{ this.adminoverview.withdraw_wechat }}</span
                      >
                    </div>
                    <div class="hoverhand">
                      <span>支付宝：</span
                      ><span>¥ {{ this.adminoverview.withdraw_alipay }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <!--账户信息-->
      <el-col :span="8" :xs="24" :sm="24" :md="8" :lg="7" :xl="6">
        <div class="box" style="">
          <div class="box_title" style="">
            <div class="box_title_font">账户信息</div>
            <div class="box_title_button"></div>
          </div>
          <!--内容-->
          <div class="box_content">
            <el-form label-width="80px">
              <el-form-item label="姓名:">
                {{ admininfo.name }}
              </el-form-item>
              <el-form-item label="账户:">
                {{ admininfo.adminnum }}
              </el-form-item>
              <el-form-item label="提现账户:">
                <el-button type="text" @click="routeraddrClick('/chfn')"
                  >去配置</el-button
                >
              </el-form-item>
              <el-form-item label="提取费率:">
                {{ admininfo.rate }}
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div
        style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px 20px 20px;
        "
      >
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">本月收支概况</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <el-button
              type="primary"
              plain
              @click="
                routeraddrClick('/statementdetails', this.userinfo, 'more')
              "
              >查看更多</el-button
            >
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table
            :header-cell-style="{ background: '#F5F7FA' }"
            ref="multipleTable"
            :row-class-name="tableRowClassName"
            :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            "
            style="width: 100%"
            min-height="900"
            border
            v-loading="loading"
            element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange"
            @cell-click="handleOneData"
          >
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column
              type="selection"
              width="55"
              v-if="mainthreeisshowset"
            />
            <el-table-column
              v-for="(mytabletitle, i) in mainthreetabletitle"
              :key="i"
              :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop"
              :label="mytabletitle.label"
              :width="mytabletitle.width"
            >
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template
                #default="scope"
                v-if="
                  mytabletitle.prop === 'is_buy' ||
                  mytabletitle.prop === 'is_online'
                "
              >
                <!--收入-样式等渲染-->
                <div v-if="mytabletitle.prop === 'is_buy'">
                  <div style="color: #66c139; font-size: 15px">
                    +{{ scope.row.income_total }}
                  </div>
                  <div style="color: #999999; font-size: 15px">
                    ({{ scope.row.income_num }}笔)
                  </div>
                </div>
                <!--支出-样式等渲染-->
                <div v-if="mytabletitle.prop === 'is_online'">
                  <div style="color: #f56c6c; font-size: 15px">
                    -{{ scope.row.pay_total }}
                  </div>
                  <div style="color: #999999; font-size: 15px">
                    ({{ scope.row.pay_num }}笔)
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column
              :fixed="false"
              label="操作"
              :width="mainthreehandle"
              v-if="mainthreeisshowope"
              prop="myoperation"
              id="operation"
            >
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-代理商列表-->
                <div v-if="mainthreetableope == 2">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="
                      routeraddrClick(
                        '/statementdetails',
                        scope.row,
                        'day',
                        this.userinfo
                      )
                    "
                    >查看详情</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainecharts from "@/components/Mainecharts.vue";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vaccount",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "账户总览",
          path: "/account",
        },
      ], // 当前页-【面包屑导航】

      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 2, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: "200",
      mainthreetabletitle: [
        {
          fixedstatu: false,
          prop: "data",
          label: "日期",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "is_buy",
          label: "收入",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "is_online",
          label: "支出",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      // 【账户信息】
      admininfo: {
        id: 0,
        name: "",
        adminnum: "",
        rate: "",
      },

      // 【账户概览】-概览信息
      adminoverview: {
        apply_money: 0,
        withdraw_money: 0,
        withdraw_wechat: 0,
        withdraw_alipay: 0,
      },

      // 【账户概览】-仪表盘
      mypanelisshow: true,
      mypanel: [
        {
          idname: "mypanel",
          width: "300px",
          height: "250px",
          subcolor: "#f34c4c",
          data: "60",
        },
      ],
      mypanel1: [
        {
          idname: "mypanel1",
          width: "300px",
          height: "250px",
          subcolor: "#465EFF",
          data: "40",
        },
      ],

      userinfo: JSON.parse(localStorage.getItem("userinfo")), //获取存储的用户信息
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata, ide, userinfo) {
      if (rowdata) {
        // 携带参数跳转
        if (routeraddr == "/statementdetails" && ide == "more") {
          // 获取当月时间-10位时间戳
          // 本月开始时间戳 XX年XX月1日 00:00:00
          let fristDay =
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ).getTime() / 1000;
          // 本月结束时间戳 XX年XX月XX日 23:59:59
          let lastDay =
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              1
            ).getTime() /
              1000 -
            1;

          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id, month: fristDay + " ~ " + lastDay },
            })
            .catch((error) => error);
        } else {
          if (routeraddr == "/statementdetails" && ide == "day") {
            // 获取当月时间-10位时间戳-new Date(rowdata.data)获取的时间为某天的8点[东八区时间]
            // 开始时间戳 XX年XX月XX日 00:00:00
            let fristDay =
              (new Date(rowdata.data).valueOf() - 8 * 60 * 60 * 1000) / 1000;
            // 结束时间戳 XX年XX月XX日 23:59:59
            let lastDay =
              (new Date(rowdata.data).valueOf() + 16 * 60 * 60 * 1000) / 1000 -
              1;

            this.$router
              .push({
                path: routeraddr,
                query: { id: userinfo.id, day: fristDay + " ~ " + lastDay },
              })
              .catch((error) => error);
          } else {
            this.$router
              .push({
                path: routeraddr,
                query: { id: rowdata.id },
              })
              .catch((error) => error);
          }
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 400) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "380px";
      }
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      // 通过Ajax向后台获取数据-账户概览&账户信息
      axios
        .post(
          "/shop/Withdraw/accountInfo",
          {},
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          let userinfo = response.data.result;
          // 【账户概览】-概览信息
          this.adminoverview.apply_money = userinfo.apply_money;
          this.adminoverview.withdraw_money = userinfo.withdraw_money;
          this.adminoverview.withdraw_wechat = userinfo.withdraw_wechat;
          this.adminoverview.withdraw_alipay = userinfo.withdraw_alipay;

          // console.log(typeof userinfo.apply_money);
          // console.log(typeof userinfo.withdraw_money);

          // 【账户总览-金额string转float】
          if(typeof userinfo.apply_money == 'string'){
            userinfo.apply_money = parseFloat(userinfo.apply_money)
          }
          if(typeof userinfo.withdraw_money == 'string'){
            userinfo.withdraw_money = parseFloat(userinfo.withdraw_money)
          }

          // 计算仪表盘数据-未提款金额
          if (userinfo.apply_money != 0 || userinfo.withdraw_money != 0) {
            this.mypanel[0].data =
              (userinfo.apply_money / (userinfo.apply_money + userinfo.withdraw_money)) * 100;
          } else {
            this.mypanel[0].data = 0;
          }
          this.$refs.mypanel.echartPanel(this.mypanel, 0);
          // 计算仪表盘数据-已提款金额
          if (userinfo.withdraw_money != 0 || userinfo.apply_money != 0) {
            this.mypanel1[0].data =
              (userinfo.withdraw_money /
                (userinfo.apply_money + userinfo.withdraw_money)) *
              100;
          } else {
            this.mypanel1[0].data = 0;
          }
          this.$refs.mypanel1.echartPanel(this.mypanel1, 0);

          // 【账户信息】
          this.admininfo.id = userinfo.id;
          this.admininfo.name = userinfo.name;
          this.admininfo.adminnum = userinfo.username;
          this.admininfo.rate = userinfo.ratio;
        })
        .catch(function (error) {
          console.log(error);
        });

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      // 通过Ajax向后台获取数据-list列表数据
      axios
        .post(
          "/shop/fund/bill",
          {},
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    mainecharts,
  },
});
</script>
<style scoped>
.dialog-box-flex {
  display: flex;
  flex-direction: column;
  justify-self: center;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

/*table表中-带状态*/
.el-table :deep(.warning-row) {
  background-color: #f4faff !important;
}

.box {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 5px 20px;
  margin: 5px 0;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  margin-top: 10px;
  width: 100%;
}
.box_content_flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 200px;
}

.hoverhand {
  cursor: pointer;
}

/* 账户概览-金额信息等 */
.money_box {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: 40px;
}
.money_box_title {
  color: #999999;
  font-size: 14px;
}
.money_box_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}
.money_box_content_div {
  margin-right: 10px;
}
.money_box_content_div_span {
  color: #333333;
  font-size: 26px;
  font-weight: bold;
}
.money_box_button {
  display: line-flex;
  flex-direction: column;
  margin-top: 10px;
}
.money_box_payinfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  line-height: 30px;
  color: #999999;
  font-size: 14px;
}
</style>
