<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div
                style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        "
            >
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--添加XX按钮-点击跳转添加类页面-->
                        <el-button type="primary" plain class="max_search" v-show="mainthreeisshowbut" @click="routeraddrClick('/agentlistadd')">添加</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                        row-key="id"
                        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                        default-expand-all
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'status' || mytabletitle.prop === 'bottom_num' || mytabletitle.prop === 'client_num'">
                                <!--下级代理-样式等渲染-->
                                <div class="mytabletext" v-if="mytabletitle.prop === 'bottom_num'" @click="routeraddrClick('/agentlist', scope.row, this.downlevel)">
                                    <span>{{ scope.row.bottom_num }}</span>
                                </div>
                                <!--设备数量-样式等渲染-->
                                <div class="mytabletext" v-if="mytabletitle.prop === 'client_num'" @click="routeraddrClick('/dvclist', scope.row)">
                                    <span>{{ scope.row.client_num }}</span>
                                </div>
                                <!--状态-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div v-if="scope.row.switchstatus === 4" id="switch">
                                        <div>
                                            <span v-if="scope.row.status == 1">启用</span>
                                            <span v-if="scope.row.status == -1">禁用</span>
                                            <span v-if="scope.row.status == -2">已删除</span>
                                        </div>
                                        <!-- <el-switch
                                                v-model="scope.row.status"
                                                active-text="开启"
                                                inactive-text="关闭"
                                                :active-value="1"
                                                :inactive-value="-1"
                                                active-color="#13ce66"
                                                @click="changeStatus(scope.row)"
                                                inactive-color="#cbcbcb"
                                                >
                                                </el-switch> -->
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-代理商列表-->
                                <div v-if="mainthreetableope === 1">
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/agentlistinfo', scope.row)">查看 </el-button>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/agentlistedit', scope.row)">编辑 </el-button>
                                    <el-button type="primary" size="mini" v-if="scope.row.status != '-2'" @click="addTanClick(scope.row, 1, (dialogVisible1 = true))">退出平台 </el-button>
                                    <el-button type="danger" size="mini" @click="delGuanxiEvent(scope.row)" v-if="scope.row.status != '-2'">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 弹出框-退出平台 -->
        <el-dialog v-model="dialogVisible1" title="退出平台" :width="this.searchwidth" :before-close="handleCloseSenior">
            <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="150px" class="demo-ruleForm">
                <div
                    style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              margin: 0 1% 0 0;
            "
                >
                    <div style="margin-bottom: 20px;">
                        <el-alert title="注意：操作不可逆，请看清楚再操作！！！" type="error" effect="dark" :closable="false" />
                    </div>
                    <el-form-item label="未提现余额：">
                        <div>￥{{ ruleForm1.apply_money }}</div>
                    </el-form-item>
                    <el-form-item label="设备数：">
                        <div>直属{{ ruleForm1.use_client_num }}台，下级{{ ruleForm1.bottom_client_num }}台</div>
                    </el-form-item>
                    <el-form-item label="选择处理方式：" prop="type">
                        <div>
                            <el-radio-group v-model="ruleForm1.type">
                                <el-radio :label="1">仅清空提现账户</el-radio>
                                <el-radio :label="2">清空提现账户，解绑设备，设备返回上级</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="状态：" prop="status">
                        <div>
                            <el-radio-group v-model="ruleForm1.status">
                                <el-radio :label="1">启用</el-radio>
                                <el-radio :label="-1">禁用</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <div style="text-align: right">
                        <el-button @click="resetFormGoout('ruleForm1')">取消</el-button>
                        <el-button type="primary" @click="submitFormGoout('ruleForm1')" :disabled="mydisabled">确认</el-button>
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vagentlist',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '代理商'
                },
                {
                    name: '代理商列表',
                    path: '/agentlist'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '代理商名称/手机号',
                            name: 'keywords',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    value: '',
                                    label: '全部'
                                },
                                {
                                    value: '-1',
                                    label: '禁用'
                                },
                                {
                                    value: '1',
                                    label: '启用'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 260, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'name',
                    label: '代理商名称',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号/账号',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'ratio',
                    label: '打印佣金比例',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'bottom_num',
                    label: '下级代理',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client_num',
                    label: '设备数量',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '添加时间',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',

            //弹窗相关数据
            dialogVisible: ref(false), //弹窗显示&隐藏
            ruleForm: {
                id: '', //当前行代理商id
                ratio: '' //当前行佣金比例
            }, //弹窗中-form表单
            rules: {}, //弹窗中-form表单-必填规则

            // 下级代理level-维护字段
            downlevel: '',

            //【退出平台】弹窗相关数据
            dialogVisible1: ref(false), //弹窗显示&隐藏
            ruleForm1: {
                id: '',
                apply_money: '',
                level: '',
                grandpa_id: '',
                parent_id: '',
                use_client_num: '',
                bottom_client_num: '',
                type: 1,
                status: 1
            },
            //【退出平台】弹窗中-form表单-必填规则
            rules1: {
                type: [
                    {
                        required: true,
                        message: '处理方式为必选项',
                        trigger: 'change'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态为必选项',
                        trigger: 'change'
                    }
                ]
            },

            mytoken: localStorage.getItem('token'), //获取存储的token
            userinfo: JSON.parse(localStorage.getItem('userinfo')), //获取用户信息
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            mydisabled: false // 防抖-前端维护
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 删除
        delGuanxiEvent(data) {
            ElMessageBox.confirm('确定删除 ' + data.name + ' 么？', '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/shop/shopUser/delete',
                            {
                                id: data.id
                            },
                            {
                                headers: {
                                    Authen: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.pagesdatas.page) // 保留当前筛选条件与页数-重新查询数据
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除'
                    })
                    this.mydisabled = false
                })
        },
        // table表单-状态改变-status值通过后台返回
        changeStatus(data) {
            console.log('切换状态的data值：' + data.status)
            axios
                .post(
                    '/shop/shopUser/disable',
                    {
                        id: data.id,
                        status: data.status
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //table表单-点击单位格-带参跳转等
        handleOneData(row, column, cell, event) {
            // console.log(row); //获取当前行数据
            // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
            // console.log(event); //获取当前指针事件
            console.log(event.path[1].id) //获取当前指针所指向的单元格id值，配合标签的id使用
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/shop/shopUser/index',
                    {
                        keywords: this.pagesdatas.keywords,
                        status: this.pagesdatas.status,
                        parent_id: this.pagesdatas.parent_id,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/shop/shopUser/index',
                    {
                        keywords: this.pagesdatas.keywords,
                        status: this.pagesdatas.status,
                        parent_id: this.pagesdatas.parent_id,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo, flag) {
            console.log(taninfo)
            // flag标识- 1退出平台
            if (flag == 1) {
                axios
                    .post(
                        'shop/shopUser/exitPlatformInfo',
                        {
                            shop_id: taninfo.id
                        },
                        {
                            headers: {
                                Authen: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        console.log(response.data.result)
                        let getdatas = response.data.result
                        this.ruleForm1 = {
                            id: taninfo.id,
                            apply_money: getdatas.apply_money,
                            level: getdatas.level,
                            grandpa_id: getdatas.grandpa_id,
                            parent_id: getdatas.parent_id,
                            use_client_num: getdatas.use_client_num,
                            bottom_client_num: getdatas.bottom_client_num,
                            type: 1,
                            status: getdatas.status
                        }
                    })
                    .catch(function(error) {
                        // 请求失败处理
                        console.log(error)
                    })
            }
        },
        // 【退出平台】弹出框-提交表单
        submitFormGoout(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 前端防抖
                    this.mydisabled = true

                    ElMessageBox.confirm('是否退出平台，退出将不可逆转！！！', '提示：', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            var getdatas = this.$refs[formName].model
                            console.log(getdatas)
                            axios
                                .post(
                                    'shop/shopUser/exitPlatform',
                                    {
                                        shop_id: getdatas.id,
                                        type: getdatas.type,
                                        status: getdatas.status
                                    },
                                    {
                                        headers: {
                                            Authen: localStorage.getItem('token')
                                        }
                                    }
                                )
                                .then((response) => {
                                    if (response.data.code === 0) {
                                        this.dialogVisible1 = ref(false) //关闭弹窗
                                        ElMessage({
                                            type: 'success',
                                            message: response.data.message,
                                            duration: 1000,
                                            onClose: () => {
                                                this.reload() //刷新
                                                this.mydisabled = false
                                            }
                                        })
                                    } else {
                                        this.mydisabled = false
                                    }
                                })
                                .catch(function(error) {
                                    console.log(error)
                                    this.mydisabled = false
                                })
                        })
                        .catch(() => {
                            ElMessage({
                                type: 'info',
                                message: '取消删除'
                            })
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 【退出平台】弹出框-重置表单-取消关闭弹窗
        resetFormGoout(formName) {
            this.$refs[formName].resetFields()
            this.dialogVisible1 = ref(false) //关闭弹窗
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata, downlevel) {
            console.log(rowdata)
            if (rowdata) {
                // 携带参数跳转
                if (routeraddr == '/agentlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                parent_id: rowdata.id,
                                downlevel: downlevel
                            }
                        })
                        .catch((error) => error)
                } else {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id, name: rowdata.name }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            let postdata = {}
            if (this.pagesdatas.parent_id) {
                this.pagesdatas.keywords = data.keywords //将筛选条件赋值给本页的pagesdatas存储，供分页使用
                this.pagesdatas.status = data.status //将筛选条件赋值给本页的pagesdatas存储，供分页使用
                postdata = {
                    keywords: data.keywords,
                    status: data.status,
                    parent_id: this.pagesdatas.parent_id,
                    page: 1,
                    page_size: this.PageSize
                }
            } else {
                this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
                postdata = {
                    keywords: data.keywords,
                    status: data.status,
                    page: 1,
                    page_size: this.PageSize
                }
            }

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post('/shop/shopUser/index', postdata, {
                    headers: {
                        Authen: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //该字段表示search按钮，且status为1表示启用，2表示禁用
                newdata[i].switchstatus = 4
                //转换添加时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = '无添加时间'
                    } else {
                        // console.log('添加时间：' + newdata[i].addtime)
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                    }
                } else {
                    newdata[i].addtime = '无添加时间'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            if (this.$route.query.downlevel) {
                // 通过level层级显示数据列表
                if (this.$route.query.downlevel == 2) {
                    this.mainthreetabletitle = [
                        {
                            fixedstatu: 'left',
                            prop: 'name',
                            label: '代理商名称',
                            width: '200'
                        },
                        {
                            fixedstatu: false,
                            prop: 'username',
                            label: '手机号/账号',
                            width: '150'
                        },
                        {
                            fixedstatu: false,
                            prop: 'ratio',
                            label: '打印佣金比例',
                            width: ''
                        },
                        {
                            fixedstatu: false,
                            prop: 'client_num',
                            label: '设备数量',
                            width: ''
                        },
                        {
                            fixedstatu: false,
                            prop: 'addtime',
                            label: '添加时间',
                            width: '200'
                        },
                        {
                            fixedstatu: false,
                            prop: 'status',
                            label: '状态',
                            width: ''
                        }
                    ]
                }
                // 下级level-维护字段
                this.downlevel = this.$route.query.downlevel + 1
            } else {
                // 通过level层级显示数据列表
                if (this.userinfo.level != 1) {
                    this.mainthreetabletitle = [
                        {
                            fixedstatu: 'left',
                            prop: 'name',
                            label: '代理商名称',
                            width: '200'
                        },
                        {
                            fixedstatu: false,
                            prop: 'username',
                            label: '手机号/账号',
                            width: '150'
                        },
                        {
                            fixedstatu: false,
                            prop: 'ratio',
                            label: '打印佣金比例',
                            width: ''
                        },
                        {
                            fixedstatu: false,
                            prop: 'client_num',
                            label: '设备数量',
                            width: ''
                        },
                        {
                            fixedstatu: false,
                            prop: 'addtime',
                            label: '添加时间',
                            width: '200'
                        },
                        {
                            fixedstatu: false,
                            prop: 'status',
                            label: '状态',
                            width: ''
                        }
                    ]
                }
                // 下级level-维护字段
                this.downlevel = parseInt(this.userinfo.level) + 1
            }

            // 判断是否具有routeinfo
            let routeinfo = this.$route.query
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            // 路由-代理商id
            if (routeinfo.parent_id) {
                this.pagesdatas.parent_id = routeinfo.parent_id
            }
            // 路由-代理商名称
            if (routeinfo.name) {
                this.pagesdatas.keywords = routeinfo.name
                this.maintwoforminfo.myinputone.input[0].content = routeinfo.name
            }
            // 路由-状态
            if (routeinfo.status) {
                this.pagesdatas.status = routeinfo.status
                this.maintwoforminfo.selectleft.select[0].value = routeinfo.status.toString()
            } else {
                this.pagesdatas.status = 1 // 需求-默认显示状态为启用
                this.maintwoforminfo.selectleft.select[0].value = '1'
            }

            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .post('/shop/shopUser/index', this.pagesdatas, {
                    headers: {
                        Authen: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}
</style>
