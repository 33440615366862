<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        >
            <template v-slot:searchModel>
                <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; gap: 20px">
                    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; color: #000; font-size: 14px; margin-right: 10px">
                        <div><span>开锁途径：</span></div>
                        <div>
                            <el-select v-model="model_form.type" placeholder="请选择">
                                <el-option v-for="(item, index) in select_type_options" :key="index" :label="item" :value="index" />
                            </el-select>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; color: #000; font-size: 14px; margin-right: 10px">
                        <div><span>开锁人：</span></div>
                        <div><el-input v-model="model_form.uid" :placeholder="placeholder_option[model_form.type]" /></div>
                    </div>
                </div>
            </template>
        </maintwo>
        <!--table表单-->
        <div style="border-top: 1px solid #eeeeee; margin-top: 10px">
            <!--主list列表-->
            <div style="background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 10px 20px">
                <div class="box-list-box" style="font-size: 14px; line-height: 60px; margin-bottom: 10px">
                    <!--左侧名称-->
                    <div><span>数据列表</span></div>
                    <!--右侧按钮-->
                    <div class="button-box">
                        <el-button type="warning" size="small" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <dpwTable
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="myTableDataRef"
                        :tabletitle="tabletitle"
                        :tabledata="tableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        :Operation="{ isshow: false }"
                    >
                        <template #content="{ scope, prop }">
                            <div v-if="prop == 'onLockUser'">
                                <div v-if="scope.row.type == 1">
                                    <div>
                                        <span>用户ID:</span><span>{{ scope.row.uid }}</span>
                                    </div>
                                    <div>
                                        <span>用户昵称:</span><span>{{ scope.row.nickname }}</span>
                                    </div>
                                    <div>
                                        <span>手机号:</span><span>{{ scope.row.mobile }}</span>
                                    </div>
                                </div>
                                <div v-else-if="scope.row.type == 2">
                                    <div>
                                        <span>代理商名称:</span><span>{{ scope.row.nickname }}</span>
                                    </div>
                                    <div>
                                        <span>代理商手机号:</span><span>{{ scope.row.mobile }}</span>
                                    </div>
                                </div>
                                <div v-else-if="scope.row.type == 4">
                                    <div>
                                        <span>姓名:</span><span>{{ scope.row.nickname }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                    </dpwTable>

                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import dpwTable from './acommon/dpw-table.vue'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vdeviceOpenLockLog',
    data() {
        return {
            mainonebread: [
                {
                    name: '购物'
                },
                {
                    name: '开锁管理'
                },
                {
                    name: '设备开锁记录'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '110px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '打印设备编号:',
                            placeholder: '请输入打印设备编号',
                            name: 'client',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '购物设备编号:',
                            placeholder: '请输入购物设备编号',
                            name: 'relate_id',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '原始设备编号:',
                            placeholder: '请输入原始设备编号',
                            name: 'meizhi_id',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '门锁状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: '0'
                                },
                                {
                                    label: '开启',
                                    value: '1'
                                },
                                {
                                    label: '关闭',
                                    value: '-1'
                                }
                            ]
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '开门时间：',
                            placeholder: {
                                placeholderstart: '请选择开始时间',
                                placeholderend: '请选择结束时间'
                            },
                            name: 'addtime',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            divinterval: '3%', //上方字中-框右侧距离
                            selectwidth: '100%', //上方字中-总框长度
                            selectmaxwidth: '97%', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '关门时间：',
                            placeholder: {
                                placeholderstart: '请选择开始时间',
                                placeholderend: '请选择结束时间'
                            },
                            name: 'closetime',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            divinterval: '3%', //上方字中-框右侧距离
                            selectwidth: '100%', //上方字中-总框长度
                            selectmaxwidth: '97%', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        }
                    ]
                }
                // myinputonefullisshow: true, //筛选查询-自动建议下拉框
                // myinputonefull: {
                //     divinterval: '0', //框右侧距离
                //     titlewidth: '110px', //标题宽度
                //     inputwidth: '220px', //input宽度
                //     inputinterval: '20px', //input右侧距离
                //     input: [
                //         {
                //             ismust: false, //是否必填，true表示必填
                //             title: '打印设备编号：',
                //             placeholder: '请输入打印设备编号', //此name为键值
                //             name: 'clientid',
                //             content: '',
                //             id: '', //此id为给后台的id值
                //             url: '/admin/Equipment/mzClientListSelect',
                //             posttype: 'get',
                //             inputval: { client: '' } //这里记录需要传的参数
                //         }
                //     ]
                // }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 240, // 数据列表中-操作-宽度
            tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'onLockUser',
                    label: '开锁人',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'type_name',
                    label: '开锁途径',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '打印设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'relate_id',
                    label: '购物设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'meizhi_id',
                    label: '原始设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addtimeStr',
                    label: '开门时间',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'closetimeStr',
                    label: '关门时间',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'time',
                    label: '开门时长',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'isopen_name',
                    label: '门锁状态',
                    width: ''
                }
            ], // 数据列表中-数据表头
            tableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,

            select_type_options: { 1: '小程序', 2: '商家公众号', 4: '租户后台' },
            model_form: {
                type: '1',
                uid: ''
            },
            placeholder_option: { 1: '用户ID/用户昵称/手机号', 2: '代理商名称/手机号', 4: '姓名' },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')), //获取存储的用户信息
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            loading: false
        }
    },
    methods: {
        // 导出
        exportDatas() {
            this.pagesdatas = {
                type: this.pagesdatas.type,
                uid: this.pagesdatas.uid,
                client: this.pagesdatas.client,
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                status: this.pagesdatas.status,
                addtime: this.pagesdatas.addtime,
                closetime: this.pagesdatas.closetime,
                export: '1'
            }
            this.postAxiosEvent(this.pagesdatas, 1)
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                type: this.pagesdatas.type,
                uid: this.pagesdatas.uid,
                client: this.pagesdatas.client,
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                status: this.pagesdatas.status,
                addtime: this.pagesdatas.addtime,
                closetime: this.pagesdatas.closetime,
                page: 1,
                page_size: val
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                type: this.pagesdatas.type,
                uid: this.pagesdatas.uid,
                client: this.pagesdatas.client,
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                status: this.pagesdatas.status,
                addtime: this.pagesdatas.addtime,
                closetime: this.pagesdatas.closetime,
                page: val,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },

        //提交等相关event事件函数
        // 获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // 判断-开锁时间
            var getaddtime = null
            if (data.addtime.start == '') {
                getaddtime = ''
            } else {
                if (data.addtime.end == '') {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.start + 86400 - 1)
                } else {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.end + 86400 - 1)
                }
            }
            //判断-关门时间
            var getclosetime = null
            if (data.closetime.start == '') {
                getclosetime = ''
            } else {
                if (data.closetime.end == '') {
                    getclosetime = data.closetime.start + ' ~ ' + (data.closetime.start + 86400 - 1)
                } else {
                    getclosetime = data.closetime.start + ' ~ ' + (data.closetime.end + 86400 - 1)
                }
            }

            this.pagesdatas = {
                type: this.model_form.type,
                uid: this.model_form.uid,
                client: data.client,
                relate_id: data.relate_id,
                meizhi_id: data.meizhi_id,
                status: data.status,
                addtime: getaddtime,
                closetime: getclosetime,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            this.tableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        postAxiosEvent(data, flag) {
            if (!flag) this.loading = true
            axios
                .post(
                    '/shop/meizhi/openlocklist',
                    { ...data, ...{ shop_id: this.myuserinfo.id } },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (!flag) this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                type: this.model_form.type,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo,
        dpwTable
    }
})
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}

.box-list-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.button-box {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
</style>
