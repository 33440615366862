<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <el-descriptions title="" direction="vertical" :column="6" border style="margin-bottom: 20px">
            <el-descriptions-item label="名称">{{ topdata.title }}</el-descriptions-item>
            <el-descriptions-item label="适用渠道">{{ topdata.type_name }}</el-descriptions-item>
            <el-descriptions-item label="仅限新用户">{{ topdata.is_new_name }}</el-descriptions-item>
            <el-descriptions-item label="使用门槛">{{ topdata.full_name }}</el-descriptions-item>
            <el-descriptions-item label="面值">{{ topdata.money_name }}</el-descriptions-item>
            <el-descriptions-item label="状态">{{ topdata.status_name }}</el-descriptions-item>
            <el-descriptions-item label="有效期">{{ topdata.use_time }}</el-descriptions-item>
            <el-descriptions-item label="总发行量">{{ topdata.number }}</el-descriptions-item>
            <el-descriptions-item label="已领取">{{ topdata.received_num }}</el-descriptions-item>
            <el-descriptions-item label="待领取">{{ topdata.receivedno_num }}</el-descriptions-item>
            <el-descriptions-item label="已使用">{{ topdata.used_num }}</el-descriptions-item>
            <el-descriptions-item label="未使用">{{ topdata.usedno_num }}</el-descriptions-item>
        </el-descriptions>
        <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
            :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div
                style="margin-top: 10px;background-color: #ffffff;border-radius: 4px;line-height: 40px;padding: 0 20px;">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left;font-size: 14px;"> 数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <!--<el-button-->
                        <!--type="primary"-->
                        <!--plain-->
                        <!--@click="routeraddrClick('/couponadd')"-->
                        <!--&gt;添加-->
                        <!--</el-button-->
                        <!--&gt;-->
                        <!--<el-button type="warning" plain @click="exportDatas(mainthreetableData)">导出</el-button>-->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%" min-height="900" border v-loading="loading"
                        element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange"
                        @cell-click="handleOneData">
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                            :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label"
                            :width="mytabletitle.width">
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope"
                                v-if="mytabletitle.prop === 'status' || mytabletitle.prop === 'images'">
                                <div v-if="mytabletitle.prop === 'images'">
                                    <div v-if="scope.row.images">
                                        <el-image class="imagesshow" :src="scope.row.images"
                                            :preview-src-list="[scope.row.images]" fit="cover"></el-image>
                                    </div>
                                </div>
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div v-if="scope.row.switchstatus === 4" id="switch">
                                        <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭"
                                            :active-value='1' :inactive-value='-1' active-color="#13ce66"
                                            inactive-color="#cbcbcb">
                                        </el-switch>
                                    </div>
                                    <div v-if="scope.row.textstatus">
                                        <span>{{ scope.row.textstatus }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center;margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-->
            <el-dialog v-model="dialogVisible" :title="tancontent.title" :width="this.searchwidth"
                :before-close="handleCloseSenior">
                <el-form ref="seniorform" :model="seniorform">
                    <div style="max-height: 450px;overflow: hidden;overflow-y:visible;padding-left:5%;margin: 20px 0;">
                        <el-form-item>
                            <!--以下是左侧字-->
                            <maininput :myinputoneisshow="tancontent.content.myinputoneisshow"
                                :myinputone="tancontent.content.myinputone"></maininput>
                            <!--以下是上方字-->
                            <maininput :myinputishow="tancontent.content.myinputishow"
                                :myinput="tancontent.content.myinput"></maininput>
                            <mainselect :myselectisshow="tancontent.content.myselectisshow"
                                :myselect="tancontent.content.myselect"></mainselect>
                            <mainselect :myselectcityisshow="tancontent.content.myselectcityisshow"
                                :myselectcity="tancontent.content.myselectcity"></mainselect>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <div style="text-align: center" class="main_one_button">
                            <el-button
                                @click="resetFormSenior(tancontent.content.myinputone, tancontent.content.myinput, tancontent.content.myselect, tancontent.content.myselectcity)">
                                <i class="el-icon-refresh-left"></i> 重置
                            </el-button>
                            <el-button type="primary"
                                @click="onSubmitSenior(tancontent.content.myinputone, tancontent.content.myinput, tancontent.content.myselect, tancontent.content.myselectcity)"
                                class="main_two_search">确定
                            </el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import maininput from '@/components/Maininput'
import mainselect from '@/components/Mainselect'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
// import {ElMessage} from "element-plus";
// import {ElMessageBox, ElMessage} from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: 'vcoupondata',
    inject: ["reload"], //刷新引用
    data() {
        return {
            topdata: "",
            mainonebread: [
                {
                    name: '首页',

                },
                {
                    name: '优惠券',
                    path: '/coupon'
                },
                {
                    name: '优惠券明细',
                    // path: '/luckydraw'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
                selecttop: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '平台来源：',
                            placeholder: '微信',
                            name: 'thirdParty',
                            value: '1',
                            options: componentProperties.get('thirdpartyoption').options,
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '使用状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: ''
                                },
                                {
                                    label: '未使用',
                                    value: '-1'
                                },
                                {
                                    label: '已使用',
                                    value: '1'
                                },
                                {
                                    label: '已过期',
                                    value: '2'
                                }
                            ],
                        }
                    ]
                },
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px',  //标题宽度
                    inputwidth: '200px',  //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '订单编号：',
                            placeholder: '订单编号',
                            name: 'trade_no',
                            content: '',
                        }
                    ],
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 26, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 0, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'code',
                    label: '优惠券编码',
                    width: '250'
                },
                {
                    fixedstatu: false,
                    prop: 'uid',
                    label: '领取用户ID',
                    width: '210'
                },
                {
                    fixedstatu: false,
                    prop: 'receive_time',
                    label: '领取时间',
                    width: '300'
                },
                {
                    fixedstatu: false,
                    prop: 'status_name',
                    label: '当前状态',
                    width: '250'
                },
                {
                    fixedstatu: false,
                    prop: 'pay_time',
                    label: '使用时间',
                    width: '300'
                },
                {
                    fixedstatu: false,
                    prop: 'number',
                    label: '订单编号',
                    width: ''
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [],// 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {},
            },//弹框

            mytoken: localStorage.getItem('token'),  //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
        }
    },
    setup() {
        const dialogVisible = ref(false);
        const handleCloseSenior = (done) => {
            done()
        }

        return {
            dialogVisible,
            handleCloseSenior,
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path;
    },
    methods: {
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 2
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios.post('/admin/coupon/usedList',
                {
                    id: this.$route.query.id,
                    page: val,
                    page_size: this.PageSize,
                    thirdparty: this.pagesdatas.thirdParty,
                    status: this.pagesdatas.status,
                    trade_no: this.pagesdatas.trade_no,
                },
                {
                    headers: {
                        'Authentication': this.mytoken
                    }
                })
                .then(response => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) { // 请求失败处理
                    console.log(error)
                })
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo)
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, data) {
            // this.$router.push(routeraddr).catch(error => error)
            if (data) {
                this.$router
                    .push({ path: routeraddr, query: { id: data.id } })
                    .catch((error) => error);
            } else {
                this.$router.push(routeraddr).catch((error) => error);
            }
        },
        //导出数据
        exportDatas(tableData) {
            console.log(tableData);
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight;
            this.sreenwidth = document.documentElement.clientWidth;
            if (this.sreenwidth < 600) {
                this.searchwidth = (this.sreenwidth * 0.8) + 'px';
            } else {
                this.searchwidth = '580px'
            }
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data;//将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios.post('/shop/Coupon/usedList',
                {
                    id: this.$route.query.id,
                    thirdparty: data.thirdParty,
                    status: data.status,
                    trade_no: data.trade_no,
                },
                {
                    headers: {
                        'Authen': this.mytoken
                    }
                })
                .then(response => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) { // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {


                newdata[i].trade_no = newdata[i].trade_no == 0 || newdata[i].trade_no == "" ? '' : newdata[i].trade_no
                newdata[i].receive_time = newdata[i].receive_time == 0 || newdata[i].receive_time == "" ? '' : this.$utils.formatDate(newdata[i].receive_time)
                newdata[i].pay_time = newdata[i].pay_time == 0 || newdata[i].pay_time == "" ? '' : this.$utils.formatDate(newdata[i].pay_time)

                // //转换-活动时间
                // if (newdata[i].receive_time) {
                //     if (newdata[i].receive_time === 0 || newdata[i].receive_time === null) {
                //         newdata[i].receive_time = ''
                //     } else {
                //         newdata[i].receive_time = this.$utils.formatDate(newdata[i].receive_time)
                //     }
                // } else {
                //     newdata[i].receive_time = ''
                // }

            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        }
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false
            this.$router.push('/').catch(error => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            //加载loading
            this.loading = true      //通过Ajax向后台获取数据

            //通过Ajax向后台获取数据
            axios.post('/shop/coupon/info',
                {
                    id: this.$route.query.id,
                },
                {
                    headers: {
                        'Authen': this.mytoken
                    }
                })
                .then(response => {
                    console.log(response.data.result)
                    this.topdata = response.data.result
                })
                .catch(function (error) {
                    console.log(error)
                })
            //通过Ajax向后台获取数据
            axios.post('/shop/coupon/usedList',
                {
                    page: 1,
                    page_size: 20,
                    id: this.$route.query.id,
                    thirdparty: 1
                },
                {
                    headers: {
                        'Authen': this.mytoken
                    }
                })
                .then(response => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo,
        maininput,
        mainselect,
    }
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>