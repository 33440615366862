<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
    </div>
    <div class="center">
        <div class="center-button">
            <div class="button-group">
                <el-button type="primary" @click="changeClientNumEvent(1)">{{ on }}</el-button>
                <el-button type="danger" @click="changeClientNumEvent(-1)">{{ off }}</el-button>
            </div>
        </div>
        <div id="container" class="map"></div>
    </div>
</template>
<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import AMapLoader from '@amap/amap-jsapi-loader'
import { defineComponent } from 'vue'
import mapss from '@/assets/imgs/map/map.png'
import mapss2 from '@/assets/imgs/map/tdb.png'
import axios from 'axios'
import utils from '@/assets/js/utils' //自定义公共js文件

export default defineComponent({
    name: 'vsharedvcmap',
    data() {
        return {
            maparr: [],
            on: '',
            off: '',
            mainonebread: [
                {
                    name: '设备'
                },
                {
                    name: '设备地图',
                    path: '/sharedvcmap'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                // myinputoneisshow: true, //筛选查询-左侧字+input
                // myinputone: {
                //   divinterval: "0", //框右侧距离
                //   titlewidth: "74px", //标题宽度
                //   inputwidth: "200px", //input宽度
                //   inputinterval: "20px", //input右侧距离
                //   input: [
                //     {
                //       ismust: false, //是否必填，true表示必填
                //       title: "设备号：",
                //       placeholder: "请输入设备号",
                //       name: "client",
                //       content: "",
                //     },
                //   ],
                // },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号：',
                            placeholder: '请输入设备编号', //此name为键值
                            name: 'client',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/shop/Equipment/searchClient',
                            posttype: 'get',
                            inputval: { client: '' } //这里记录需要传的参数
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '20px', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '', //select右侧距离
                    select: [
                        // {
                        //     selectisshow: true,
                        //     ismust: false,
                        //     title: '投放方式：',
                        //     placeholder: '全部',
                        //     name: 'sign_type',
                        //     value: '',
                        //     options: [
                        //         {
                        //             label: '全部',
                        //             value: ''
                        //         },
                        //         {
                        //             label: '公司自投',
                        //             value: '1'
                        //         },
                        //         {
                        //             label: '购买',
                        //             value: '2'
                        //         },
                        //         {
                        //             label: '租赁',
                        //             value: '3'
                        //         }
                        //     ]
                        // }
                    ]
                }
            }, // 筛选-控件信息

            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
        window['closes'] = () => {
            this.closes()
        }
    },
    methods: {
        // 在线离线-查询设备
        changeClientNumEvent(flag) {
            axios
                .get('/shop/Equipment/map', {
                    headers: {
                        Authen: localStorage.getItem('token')
                    },
                    params: {
                        client: this.pagesdatas.client,
                        is_buy: this.pagesdatas.sign_type,
                        is_online: flag
                    }
                })
                .then((response) => {
                    this.getmapEvent(response.data.result.data, this.mytoken)
                    this.on = response.data.result.activity.on
                    this.off = response.data.result.activity.off
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 关闭-信息详情事件
        closes() {
            document.getElementsByClassName('amap-marker-label')[0].remove()
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            axios
                .get('/shop/Equipment/map', {
                    headers: {
                        Authen: localStorage.getItem('token')
                    },
                    params: {
                        client: data.client,
                        is_buy: data.sign_type
                    }
                })
                .then((response) => {
                    this.getmapEvent(response.data.result.data, this.mytoken)
                    this.on = response.data.result.activity.on
                    this.off = response.data.result.activity.off
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 获取地图函数
        getmapEvent(maparr, getmytoken) {
            AMapLoader.load({
                key: 'd45b94eb15c85da28f5e2e377d61546d', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.Scale'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                AMapUI: {
                    // 是否加载 AMapUI，缺省不加载
                    version: '1.1', // AMapUI 缺省 1.1
                    plugins: [] // 需要加载的 AMapUI ui插件
                },
                Loca: {
                    // 是否加载 Loca， 缺省不加载
                    version: '1.3.2' // Loca 版本，缺省 1.3.2
                }
            })
                .then((AMap) => {
                    // 中心坐标点定位&放大级别
                    let centerdian = []
                    let zoomdian = 11
                    if (maparr.length > 0) {
                        maparr.forEach((element) => {
                            if (element.lng != null && element.lat != null && element.lng != '' && element.lat != '') {
                                centerdian = [element.lng, element.lat] //中心点坐标-第一个设备
                                return
                            }
                        })
                        if (centerdian.length == 0) {
                            zoomdian = 4 //级别
                            centerdian = [113.64964385, 34.7566100641] //中心点坐标-郑州
                        }
                    } else {
                        zoomdian = 4 //级别
                        centerdian = [113.64964385, 34.7566100641] //中心点坐标-郑州
                    }
                    var map = new AMap.Map('container', {
                        zoom: zoomdian, //级别
                        center: centerdian
                    })

                    var style = [
                        {
                            url: mapss,
                            anchor: new AMap.Pixel(15, 35),
                            size: new AMap.Size(30, 40)
                        },
                        {
                            url: mapss2,
                            anchor: new AMap.Pixel(15, 35),
                            size: new AMap.Size(30, 40)
                        }
                    ]
                    let arrayss = [] // 在线
                    let arrayssoff = [] // 离线
                    for (let i = 0; i < maparr.length; i++) {
                        if (maparr[i].lng && maparr[i].lat) {
                            let datas = []
                            datas[0] = maparr[i].lng
                            datas[1] = maparr[i].lat
                            let lnglatdata = {
                                id: maparr[i].id,
                                is_online: maparr[i].is_online,
                                lng: maparr[i].lng,
                                lat: maparr[i].lat,
                                lnglat: datas
                            }
                            if (maparr[i].is_online == 1) {
                                arrayss.push(lnglatdata)
                            } else {
                                arrayssoff.push(lnglatdata)
                            }
                        }
                    }

                    // Marker标记点
                    var marker = new AMap.Marker({ content: '', topWhenClick: true, bubble: true })
                    // 在线
                    var mass = new AMap.MassMarks(arrayss, {
                        zIndex: 111,
                        cursor: 'pointer',
                        style: style[0]
                    })
                    mass.on('click', function(e) {
                        // Marker标记点
                        marker.setMap(map)
                        marker.setPosition(e.data.lnglat)
                        clickEvent(e.data.id, marker)
                    })
                    mass.setMap(map)

                    // 离线
                    var mass1 = new AMap.MassMarks(arrayssoff, {
                        zIndex: 110,
                        cursor: 'pointer',
                        style: style[1]
                    })
                    mass1.on('click', function(e) {
                        // Marker标记点
                        marker.setMap(map)
                        marker.setPosition(e.data.lnglat)
                        clickEvent(e.data.id, marker)
                    })
                    mass1.setMap(map)

                    // 内置函数-点击设备时-显示相关数据
                    function clickEvent(ide, marker) {
                        //通过Ajax向后台获取数据
                        axios
                            .get('/shop/Equipment/clientInfo', {
                                headers: {
                                    Authen: getmytoken
                                },
                                params: {
                                    id: ide
                                }
                            })
                            .then((response) => {
                                if (response.data.result.userType == 1) {
                                    marker.setLabel({
                                        content:
                                            '<div style="padding: 5px; display: flex;flex-direction: row;">' +
                                            '<div style="display: flex;flex-direction: column;">' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">设备编号:' +
                                            '<text style="color:#666;margin-left: 10px;">' +
                                            response.data.result.client +
                                            '</text></div>' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">商家名称:' +
                                            '<text style="color:#666;margin-left: 10px;">' +
                                            response.data.result.share_name +
                                            '</text></div>' +
                                            // '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">投放方式:' +
                                            // '<text style="color:#666;margin-left: 10px;">' +
                                            // response.data.result.is_buy +
                                            // '</text></div>' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">设备地址:' +
                                            '<text style="color:#666;margin-left: 10px;">' +
                                            response.data.result.address +
                                            '</text></div>' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">更新时间:' +
                                            '<text style="color:#666;margin-left: 10px;">' +
                                            utils.formatDate(response.data.result.addtime) +
                                            '</text></div>' +
                                            '</div>' +
                                            '</div>'
                                    })
                                } else {
                                    marker.setLabel({
                                        content:
                                            '<div style="padding: 5px; display: flex;flex-direction: row;">' +
                                            '<div style="display: flex;flex-direction: column;">' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">设备编号:' +
                                            '<text style="color:#666;margin-left: 10px;">' +
                                            response.data.result.client +
                                            '</text></div>' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">商家名称:' +
                                            '<text style="color:#666;margin-left: 10px;">' +
                                            response.data.result.share_name +
                                            '</text></div>' +
                                            // '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">投放方式:' +
                                            // '<text style="color:#666;margin-left: 10px;">' +
                                            // response.data.result.is_buy +
                                            // '</text></div>' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">设备地址:' +
                                            '<text style="color:#666;margin-left: 10px;">' +
                                            response.data.result.address +
                                            '</text></div>' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">更新时间:' +
                                            '<text style="color:#666;margin-left: 10px;">' +
                                            utils.formatDate(response.data.result.addtime) +
                                            '</text></div>' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">订单金额:' +
                                            '<text style="color:#666;margin-left: 10px;">¥ ' +
                                            response.data.result.order_total +
                                            '</text></div>' +
                                            '<div style=" height: 25px;line-height: 25px;color: #333333;font-size:15px;">订单数量:' +
                                            '<text style="color:#666;margin-left: 10px;">' +
                                            response.data.result.order_num +
                                            '</text></div>' +
                                            '</div>' +
                                            '</div>'
                                    })
                                }
                            })
                            .catch(function(error) {
                                console.log(error)
                            })
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 定义
            this.pagesdatas = {}
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query
            // 来源于路由的支付订单编号-设备编号
            //   if (routerinfo.client) {
            //     this.maintwoforminfo.myinputonefull.input[0].content = routerinfo.client;
            //     axios
            //       .get("/shop/Equipment/searchClient", {
            //         headers: {
            //           Authen: localStorage.getItem("token"),
            //         },
            //         params: { client: routerinfo.client },
            //       })
            //       .then((response) => {
            //         if (response.data.code == 0) {
            //           let getdata = response.data.result.data;
            //           this.pagesdatas["client"] = getdata[0].id;
            //           this.maintwoforminfo.myinputonefull.input[0].id = getdata[0].id;
            //         }
            //       })
            //       .catch(function (error) {
            //         console.log(error);
            //       });
            //   }

            // 优化-上方请求接口的程序不适于低速网请求！【整个项目就-设备订单排行-页面跳转设备地图页面-且能正常取到设备编号和设备id值】
            if (routerinfo.client && routerinfo.client_id) {
                this.maintwoforminfo.myinputonefull.input[0].content = routerinfo.client
                this.maintwoforminfo.myinputonefull.input[0].id = routerinfo.client_id
                this.pagesdatas['client'] = routerinfo.client_id
            }

            setTimeout(() => {
                var getmytoken = this.mytoken
                axios
                    .get('/shop/Equipment/map', {
                        headers: {
                            Authen: getmytoken
                        },
                        params: this.pagesdatas
                    })
                    .then((response) => {
                        this.getmapEvent(response.data.result.data, getmytoken)
                        this.on = response.data.result.activity.on
                        this.off = response.data.result.activity.off
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }, 500)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>
<style scoped>
#container :deep(.amap-marker-label) {
    position: absolute;
    z-index: 2;
    border: 1px solid #465fff;
    border-radius: 4px;
    background-color: white;
    white-space: nowrap;
    cursor: default;
    padding: 3px;
    font-size: 12px;
    line-height: 14px;
}

#container :deep(.amap-marker-label) #closebutton {
    color: #465fff;
}

#container :deep(.amap-marker-label) #closebutton:hover {
    color: #f56c6c;
}

.center {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
}

.center-button {
    position: absolute;
    right: 20px;
    bottom: 710px;
    z-index: 1000;
}

.button-group {
    display: flex;
    justify-content: flex-end;
}

.map {
    width: 100%;
    height: 740px;
    margin-top: 10px;
    border-radius: 4px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>
