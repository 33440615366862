<template>
    <div id="mycontainer">
        <!--左侧导航栏&logo-->
        <el-container>
            <el-aside :width="this.mymainwidth">
                <!--头部logo-->
                <div class="logoimges" @click="clickheadlogo(this.headlogoclicknum)" :style="{ width: this.mymainwidth + ' !important' }">
                    <div class="logotitle-box" v-if="show01">
                        <span>{{ this.userinfo.brand_name == '' ? '商户平台' :  this.userinfo.brand_name }}</span>
                    </div>
                    <div class="logotitle-bag" v-if="show02">
                        <div class="logotitle-bag-box logotitle-box-one">
                            <span>{{
                                this.userinfo.brand_name == ''
                                    ? '京'
                                    : this.userinfo.brand_name.length > 0
                                    ? this.userinfo.brand_name.substr(0, 1)
                                    : this.userinfo.brand_name.substr(0, 1)
                            }}</span>
                        </div>
                    </div>
                </div>
                <!--左侧导航栏  -->
                <div style="">
                    <el-menu
                        :default-active="myDefault.myasideactive"
                        background-color="#e9eef3"
                        text-color="#ffffff"
                        active-text-color="#ffffff"
                        class="el-menu-vertical-demo"
                        popper-class="mytestclass"
                        :collapse="isCollapse"
                        :unique-opened="true"
                        style="text-align: left; border-right: none"
                    >
                        <el-sub-menu v-for="(stepone, j) in this.models" :key="j" :index="stepone.id.toString()" :id="stepone.id">
                            <template #title>
                                <i :class="stepone.icon" v-if="isCollapse"></i>
                                <span style="font-size: 12px">{{ stepone.name }}</span>
                            </template>
                            <el-menu-item
                                v-for="(step, k) in stepone.child"
                                @click="puShstep(step.id), routerleftgo(step.id)"
                                :key="k"
                                :index="step.id.toString()"
                                :class="{ active: step.id === getData.num }"
                            >
                                <i
                                    v-show="getData.num !== step.id"
                                    :class="step.icon"
                                    style="
                    font-size: 14px;
                    vertical-align: middle;
                    margin-right: 10px;
                  "
                                ></i>
                                <i
                                    v-show="getData.num === step.id"
                                    :class="step.icon"
                                    style="
                    font-size: 14px;
                    vertical-align: middle;
                    margin-right: 10px;
                  "
                                ></i>
                                <span style="font-size: 14px; line-height: 50px">{{ step.name }}</span>
                            </el-menu-item>
                        </el-sub-menu>
                    </el-menu>
                    <!--左侧导航栏-下方界面颜色-->
                    <div
                        style="
              background-color: #333333;
              height: 900px;
              width: 230px;
              float: left;
            "
                    ></div>
                    <div
                        style="
              background-color: #e9eef3;
              height: 900px;
              width: 10px;
              float: left;
            "
                    ></div>
                </div>
                <!--底部网页信息栏-->
                <!-- <el-footer style="color: #bfc7e0; font-size: 12px" v-if="footerisshow"
          >©2017-2022 京硕信息版权所有 <br />
          ICP证: 豫ICP备17032092号-1
        </el-footer> -->
            </el-aside>
            <!--顶部导航栏&主页面-->
            <el-container>
                <!--顶部导航栏-->
                <el-header :style="{ left: this.mymainwidth + ' !important' }" style="background-color: #4a4a4a; height: 70px">
                    <!--右侧-用户信息等导航-->
                    <div
                        style="
              float: left;
              position: fixed;
              top: 0;
              right: 0;
              margin-top: 10px;
            "
                    >
                        <div
                            style="
                width: 69px;
                height: 60px;
                text-align: center;
                margin-left: 40px;
              "
                            class="mymenuicon"
                        >
                            <el-popover :placement="myset" :width="300" trigger="hover" :offset="24">
                                <template #reference>
                                    <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" style="margin-top: 8px"></el-avatar>
                                </template>
                                <div class="header-right-admin">
                                    <span class="header-right-admin-info">账户信息</span>
                                    <!-- <el-button type="text" class="header-right-admin-set"
                    >账户设置
                  </el-button> -->
                                    <!--分割线-->
                                    <div class="myline"></div>
                                    <div class="header-right-admin-color">
                                        <div>
                                            账号名称：<span>{{ this.userinfo.username }}</span>
                                        </div>
                                        <div v-if="this.userinfo.last_login_ip">
                                            登录地址：<span>河南省郑州市 (IP:{{ this.userinfo.last_login_ip }})</span>
                                        </div>
                                        <div v-if="this.userinfo.last_login_time">
                                            上次登录：<span>{{ this.$utils.formatDate(this.userinfo.last_login_time) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-popover>
                        </div>
                        <!-- <div class="mymenuicon">
              <el-popover placement="bottom" :width="250" trigger="click">
                <template #reference>
                  <div>
                    <i class="el-icon-bell"></i>
                  </div>
                </template>
                <div style="line-height: 30px">
                  <div>
                    <span
                      style="
                        line-height: 40px;
                        padding: 5px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #94a3d2;
                      "
                      >订单提示</span
                    >
                    <div style="padding: 5px">
                      <div
                        style="
                          background-color: #eaedf7;
                          height: 1px;
                          width: 100%;
                        "
                      ></div>
                    </div>
                    <div
                      v-for="(dingdate, i) in dingdanDate"
                      :key="i"
                      class="myhover"
                      style="padding: 0 5px; color: #616e9d"
                    >
                      <el-row
                        style="
                          border-bottom: 1px solid #f1f1f1;
                          line-height: 40px;
                          margin-bottom: 0 !important;
                        "
                      >
                        <el-col :span="18">
                          {{ dingdate.name }}
                        </el-col>
                        <el-col :span="6" style="text-align: center">
                          (
                          <span style="color: red">{{ dingdate.number }}</span>
                          )
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                  <div>
                    <span
                      style="
                        line-height: 40px;
                        padding: 5px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #94a3d2;
                      "
                      >广告位提示</span
                    >
                    <div style="padding: 5px">
                      <div
                        style="
                          background-color: #eaedf7;
                          height: 1px;
                          width: 100%;
                        "
                      ></div>
                    </div>
                    <div
                      v-for="(guanggao, i) in guanggaoDate"
                      :key="i"
                      class="myhover"
                      style="padding: 0 5px; color: #616e9d"
                    >
                      <el-row
                        style="
                          border-bottom: 1px solid #f1f1f1;
                          line-height: 40px;
                          margin-bottom: 0 !important;
                        "
                      >
                        <el-col :span="18">
                          {{ guanggao.name }}
                        </el-col>
                        <el-col :span="6" style="text-align: center">
                          (
                          <span style="color: red">{{ guanggao.number }}</span>
                          )
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>
              </el-popover>
            </div> -->
                        <div class="mymenuicon" @click="loginout">
                            <i class="el-icon-switch-button"></i>
                        </div>
                    </div>
                </el-header>
                <!--主页面-->
                <el-main :router="true" :style="{ left: this.mymainwidth + ' !important' }">
                    <!--特殊页面的page页-->
                    <special
                        v-if="getData.isshowspecial"
                        :mainpagedata="mainpagedata"
                        v-on:getspecialinfo="getSpecialinfoEvent"
                        v-on:getsearchinfo="getSeachinfoEvent"
                        v-on:searchForm="searchFormEvent"
                        v-on:getpageinfo="getPageinfoEvent"
                        v-on:searchFormtan="searchFormtanEvent"
                    ></special>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import qiweiQrcode from './qiweiQrcode/index'
import special from '@/vspecial/special'

export default defineComponent({
    data() {
        return {
            models: JSON.parse(localStorage.getItem('mymodels')), //获取存储的mymodels(顶部&左侧导航)
            mytoken: localStorage.getItem('token'), //获取存储的token
            userinfo: JSON.parse(localStorage.getItem('userinfo')), //获取用户信息

            mymainwidth: '200px',
            myset: 'bottom',
            footerisshow: true,
            headlogoclicknum: 0,
            isCollapse: false,
            show01: true,
            show02: false,
            sreenwidth: '',
            sreenheight: '',
            isActive: false,
            routerid: null,
            myasidepid: null,

            dingdanDate: [
                {
                    name: '待付款订单',
                    number: '0'
                },
                {
                    name: '待发货订单',
                    number: '123'
                },
                {
                    name: '已发货订单',
                    number: '2'
                },
                {
                    name: '已完成订单',
                    number: '58'
                }
            ], //头部图标导航-消息-订单模块数据
            guanggaoDate: [
                {
                    name: '小程序广告位',
                    number: '10'
                },
                {
                    name: '主机广告位',
                    number: '13'
                }
            ], //头部图标导航-消息-广告位模块数据
            mytagDate: [
                {
                    name: 'Plus会员返现记录',
                    active: false
                },
                {
                    name: '添加商品',
                    active: true
                },
                {
                    name: '专题列表',
                    active: false
                },
                {
                    name: '订单列表',
                    active: false
                },
                {
                    name: '广告',
                    active: false
                },
                {
                    name: '交易统计',
                    active: false
                },
                {
                    name: '用户管理',
                    active: false
                },
                {
                    name: '成员管理',
                    active: false
                }
            ], //头部图标导航-常用菜单-模块数据
            mymodeDate: [
                {
                    name: '日间模式',
                    active: true,
                    icon: 'iconfont icon-baozhengjin'
                },
                {
                    name: '夜间模式',
                    active: false,
                    icon: 'iconfont icon-baozhengjin'
                }
            ], //头部图标导航-切换主题-模块数据

            menutitle: [
                {
                    icon: ''
                }
            ]
        }
    },
    props: ['mainpagedata', 'mydefault'],
    setup(props) {
        const mainPagedata = reactive(props['mainpagedata'])
        const myDefault = reactive(props['mydefault'])

        //定义数据数组等
        let getData = reactive({
            num: null,

            isshowpages: true, //显示pages的内容
            isshowspecial: false //显示special的内容
        })

        if (mainPagedata.showpages) {
            //判断pages页面，常规通用组件页面显示pages，特殊页面显示special
            getData.isshowpages = mainPagedata.showpages.isshowpages
            getData.isshowspecial = mainPagedata.showpages.isshowspecial
        } else {
            getData.isshowpages = true //显示pages的内容
            getData.isshowspecial = false //显示special的内容
        }

        const puShaside = (asideid) => {
            //点击顶部导航
            myDefault.asideid = asideid
            myDefault.myheadactive = asideid.toString()
            // console.log('点击顶部导航：' + asideid)
            // console.log('获取顶部导航信息model的name：' + JSON.stringify(model[asideid].name))
            // console.log('获取顶部导航信息model的子数据：' + JSON.stringify(model),asideid)
        }
        const puShstep = (stepid) => {
            //点击左侧导航
            getData.num = stepid
            myDefault.myasideactive = stepid.toString()
        }

        // 登陆成功之后，判断是否显示加企业微信二维码
        if (localStorage.getItem('is_model_login') == 0) qiweiQrcode.load()

        return {
            mainPagedata,
            myDefault,
            getData,
            puShaside,
            puShstep
        }
    },
    methods: {
        searchFormEvent(data) {
            //获取Pagedata-Maintwo-高级筛选-开始检索返回的数据
            console.log('高级筛选-开始检索返回数据(container):' + JSON.stringify(data))
            //将数据传向父组件
            this.$emit('searchForm', data)
        },
        getSeachinfoEvent(data) {
            //获取Pagedata-Maintwo-搜索-返回的数据
            console.log('搜索-返回的数据:' + JSON.stringify(data))
            //将数据传向父组件
            this.$emit('getsearchinfo', data)
        },
        getPageinfoEvent(data) {
            //获取Pagedata-Mainthree-数据列表-分页选择的数据
            console.log('页面-分页选择的数据:' + JSON.stringify(data))
            //将页数传向父组件
            this.$emit('getpageinfo', data)
        },
        getSpecialinfoEvent(data) {
            //获取XX特殊页面返回的数据
            console.log('获取XX特殊页面返回的数据:' + JSON.stringify(data))
            //将页数传向父组件
            this.$emit('getspecialinfo', data)
        },
        searchFormtanEvent(data) {
            //获取弹框-返回的数据
            console.log('container层-获取单框提交的参数与数据等：' + JSON.stringify(data))
            //将数据传向父组件
            this.$emit('searchFormtan', data)
        },
        routerleftgo(stepid) {
            //点击左侧导航跳转
            /*
             * 前后端分离开发-路由数据获取与绑定
             * 1、获取所有的路由数据
             * 2、通过for循环遍历数据，并及时用return返回
             * 3、通过对比后台返回值stepid与本地路由id进行比较并绑定路由数据
             * 4、最后通过页面路由地址进行跳转！
             * 5、并通过push将参数写入路由，通过路由传入页面中
             * */
            var arr = this.$router.options.routes
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id == stepid) {
                    // 通过路由跳转-且通过params传数据
                    this.$router.push({ path: arr[i].path }).catch((error) => error)
                    return
                }
            }
        },

        routeraddrClick(routeraddr) {
            //点击通过路由跳转
            this.$router.push(routeraddr).catch((error) => error)
        },
        loginout() {
            //点击退出登录-并清除Item的缓存
            // localStorage.removeItem("token");
            // localStorage.removeItem("mymodels");
            localStorage.clear()
            this.$router.push('/').catch((error) => error)

            console.log(localStorage.getItem('token'), localStorage.getItem('mymodels'))
        },
        clickheadlogo(num) {
            this.headlogoclicknum = num + 1
            if (this.headlogoclicknum % 2 > 0) {
                this.mymainwidth = '64px'
                this.isCollapse = true
                this.show01 = false
                this.show02 = true
                this.footerisshow = false
            } else {
                this.mymainwidth = '240px'
                this.isCollapse = false
                this.show01 = true
                this.show02 = false
                this.footerisshow = true
            }
        },
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            // console.log(this.sreenwidth, this.sreenheight)
            if (this.sreenwidth < 1400) {
                this.mymainwidth = '64px'
                this.isCollapse = true
                this.show01 = false
                this.show02 = true
                this.myset = 'bottom-start'
                this.footerisshow = false
            } else {
                this.mymainwidth = '240px'
                this.isCollapse = false
                this.show01 = true
                this.show02 = false
                this.myset = 'bottom'
                this.footerisshow = true
            }
        }
    },
    created() {
        window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
        this.getwindowinfo()
    },
    components: {
        special
    }
})
</script>

<style>
body {
    padding: 0;
    margin: 0;
    background-color: #e9eef3;
}

.el-header {
    --el-header-padding: 0 20px 0 0;
    background-color: #4a4a4a;
    color: var(--el-text-color-primary);
    /*text-align: center;*/
    line-height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 240px;
    z-index: 999;
}

.el-footer {
    position: fixed;
    bottom: 0;
    height: 50px;
    line-height: 20px;
}

.el-aside {
    background-color: #333333;
    color: var(--el-text-color-primary);
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: left 0.5s linear;
    -moz-transition: left 0.5s linear;
    -webkit-transition: left 0.5s linear;
    -o-transition: left 0.5s linear;
    overflow-y: visible;
    overflow-x: hidden;
}

.el-main {
    background-color: #e9eef3;
    color: var(--el-text-color-primary);
    position: absolute;
    top: 60px;
    left: 240px;
    right: 0;
    padding: 20px 10px 20px 10px;
}

body > .el-container {
    margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
    line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
    line-height: 320px;
}

/*头部导航点击之后的底色*/
.el-menu-demo .el-menu-item.is-active {
    background: var(--el-color-primary) !important;
}

/*头部导航点击之后的背景样式*/
.el-menu--horizontal > .el-menu-item.is-active {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 0 solid var(--el-menu-active-color);
    color: var(--el-menu-active-color) !important;
}

.el-menu--horizontal > .el-menu-item:hover {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/*左侧侧边导航栏背景样式*/
.el-sub-menu__title {
    max-width: 230px;
    background-color: #333333 !important;
}

.el-sub-menu .el-menu-item {
    height: 50px;
    line-height: 50px;
    padding: 0 45px;
    width: 230px;
    background-color: rgba(51, 51, 51, 0.97);
}

.el-sub-menu .el-menu-item:hover {
    background-color: rgb(51, 51, 51);
    /*background-color: #1B50BE;*/
    /*width: 240px;*/
    /*border-top-right-radius: 10px;*/
    /*border-bottom-right-radius: 10px;*/
    /*transition: background-color 0s linear 0s;*/
    /*-moz-transition: background-color 0s linear 0s; !* Firefox 4 *!*/
    /*-webkit-transition: background-color 0s linear 0s; !* Safari 和 Chrome *!*/
    /*-o-transition: background-color 0s linear 0s; !* Opera *!*/
}

/*左侧导航栏点击之后的底色&样式*/
.el-menu-vertical-demo .el-menu-item.is-active {
    background-color: var(--el-color-primary) !important;
    width: 240px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /*transition: width 0s linear 0s;*/
    /*-moz-transition: width 0s linear 0s; !* Firefox 4 *!*/
    /*-webkit-transition: width 0s linear 0s; !* Safari 和 Chrome *!*/
    /*-o-transition: width 0s linear 0s; !* Opera *!*/
}

.el-menu-item {
    transition: none;
}

.mymenuicon {
    width: 69px;
    text-align: center;
    float: left;
    color: white;
    font-size: 20px;
}

.mymenuicon:hover {
    cursor: pointer;
    background-color: rgb(57, 93, 170, 0.39);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.mymenuicon:active {
    background-color: rgba(57, 93, 170, 0.39);
}

/**********以下是菜单类的button样式*****/

.mycursor:hover {
    cursor: pointer;
}

.mytagclass {
    border: 1px solid var(--el-color-primary);
    margin: 0 10px 10px 0;
}

.mytagcolor {
    color: var(--el-color-primary);
    background-color: white;
}

.mytagcoloractive {
    color: white !important;
    background-color: var(--el-color-primary) !important;
}

.header-right-admin {
    padding: 5px;
    line-height: 30px;
}
.header-right-admin-info {
    line-height: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #94a3d2;
}
.header-right-admin-set {
    float: right;
    font-size: 12px;
    font-weight: 400;
    color: #002eb7;
}
.myline {
    background-color: #eaedf7;
    height: 1px;
    width: 100%;
    margin-bottom: 10px;
}
.header-right-admin-color {
    color: #616e9d;
}

/* .logoimges {
  height: 70px;
  line-height: 70px;
  text-align: center;
  background-color: #4a4a4a;
  cursor: pointer;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -o-transition: width 0.5s;
} */

/* 头部logo */
.logoimges {
    background-color: #4a4a4a;
    cursor: pointer;
}

.logotitle-box {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 4px;
    line-height: 70px;
    text-align: center;
}

.logotitle-bag {
    width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 70px;
}
.logotitle-bag-box {
    width: 50px;
    height: 50px;
    background-color: var(--el-color-primary) !important;
    border-radius: 50px;
    text-align: center;
}
.logotitle-box-one {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
}
</style>
