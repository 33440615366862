<template>
  <!--柱状图-->
  <div v-if="getData.columnisshow">
    <div v-for="(mycolumdatainfo,i) in Columndata" :key="i">
      <div :id="mycolumdatainfo.idname"
           :style="{'width':mycolumdatainfo.width, 'height': mycolumdatainfo.height}"></div>
    </div>
  </div>
  <!--仪表盘-半圆-->
  <div v-if="getData.mypanelisshow">
    <div v-for="(mypaneldatainfo,j) in myPaneldata" :key="j">
      <div :id="mypaneldatainfo.idname"
           :style="{'width':mypaneldatainfo.width, 'height': mypaneldatainfo.height}"></div>
    </div>
  </div>
  <!--仪表盘-圆盘-->
  <div v-if="getData.mycustompanelisshow">
    <div v-for="(mycustompaneldatainfo,n) in myCustomPaneldata" :key="n">
      <div :id="mycustompaneldatainfo.idname"
           :style="{'width':mycustompaneldatainfo.width, 'height': mycustompaneldatainfo.height}"></div>
    </div>
  </div>
  <!--饼状图-圆角-->
  <div v-if="getData.filletcakeisshow">
    <div v-for="(filletcakedatainfo,k) in filletCakedata" :key="k">
      <div :id="filletcakedatainfo.idname"
           :style="{'width':filletcakedatainfo.width, 'height': filletcakedatainfo.height}"></div>
    </div>
  </div>
  <!--饼状图-自定义样式-->
  <div v-if="getData.customcakeisshow">
    <div v-for="(customcakedatainfo,l) in Customcakedata" :key="l">
      <div :id="customcakedatainfo.idname"
           :style="{'width':customcakedatainfo.width, 'height': customcakedatainfo.height}"></div>
    </div>
  </div>
  <!--折现图-渐变堆叠-->
  <div v-if="getData.linegradualisshow">
    <div v-for="(linegradualdatainfo,m) in lineGradualdata" :key="m">
      <div :id="linegradualdatainfo.idname"
           :style="{'width':linegradualdatainfo.width, 'height': linegradualdatainfo.height}"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import {onMounted, reactive} from 'vue';

export default {
  name: 'Mainecharts',
  props: [
    'columnisshow',
    'columndata',
    'mypanelisshow',
    'mypaneldata',
    'mycustompanelisshow',
    'mycustompaneldata',
    'filletcakeisshow',
    'filletcakedata',
    'customcakeisshow',
    'customcakedata',
    'linegradualisshow',
    'linegradualdata'

  ],
  setup(props) {
    const Columnisshow = reactive(props['columnisshow']);
    const Columndata = reactive(props['columndata']);
    const myPanelisshow = reactive(props['mypanelisshow']);
    const myPaneldata = reactive(props['mypaneldata']);
    const myCustompanelisshow = reactive(props['mycustompanelisshow']);
    const myCustomPaneldata = reactive(props['mycustompaneldata']);  
    const filletCakeisshow = reactive(props['filletcakeisshow']);
    const filletCakedata = reactive(props['filletcakedata']);
    const Customcakeisshow = reactive(props['customcakeisshow']);
    const Customcakedata = reactive(props['customcakedata']);
    const lineGradualisshow = reactive(props['linegradualisshow']);
    const lineGradualdata = reactive(props['linegradualdata']);
    //定义数据数组等
    let getData = reactive({
      columnisshow: false,          //柱状图
      mypanelisshow: false,         //仪表盘-半圆
      mycustompanelisshow:false,    //仪表盘-圆盘
      filletcakeisshow: false,      //饼状图-圆角
      customcakeisshow: false,      //饼状图-自定义样式
      linegradualisshow: false,     //折现图-渐变堆叠
    })

    //柱状图
    if (Columnisshow) {
      getData.columnisshow = Columnisshow;
      if (Columndata) {
        onMounted(() => {
          if (Columndata.length) {
            for (var i = 0; i < Columndata.length; i++) {
              echartInit(Columndata, i)
            }
          }
        })
      }
    } else {
      getData.columnisshow = false;
    }
    //仪表盘-半圆
    if (myPanelisshow) {
      getData.mypanelisshow = myPanelisshow;
      if (myPaneldata) {
        onMounted(() => {
          if (myPaneldata.length) {
            for (var i = 0; i < myPaneldata.length; i++) {
              echartPanel(myPaneldata, i)
            }
          }
        })
      }
    } else {
      getData.mypanelisshow = false
    }
    //仪表盘-圆盘
    if (myCustompanelisshow) {
      getData.mycustompanelisshow = myCustompanelisshow;
      if (myCustomPaneldata) {
        onMounted(() => {
          if (myCustomPaneldata.length) {
            for (var i = 0; i < myCustomPaneldata.length; i++) {
              echartCustomPanel(myCustomPaneldata, i)
            }
          }
        })
      }
    } else {
      getData.mycustompanelisshow = false
    }
    //饼状图-圆角
    if (filletCakeisshow) {
      getData.filletcakeisshow = filletCakeisshow;
      if (filletCakedata) {
        onMounted(() => {
          if (filletCakedata.length) {
            for (var i = 0; i < filletCakedata.length; i++) {
              echartFillet(filletCakedata, i)
            }
          }
        })
      }
    } else {
      getData.filletcakeisshow = false
    }
    //饼状图-自定义样式
    if (Customcakeisshow) {
      getData.customcakeisshow = Customcakeisshow;
      if (Customcakedata) {
        onMounted(() => {
          if (Customcakedata.length) {
            for (var i = 0; i < Customcakedata.length; i++) {
              echartCustom(Customcakedata, i)
            }
          }
        })
      }
    } else {
      getData.customcakeisshow = false
    }
    //折现图-渐变堆叠
    if (lineGradualisshow) {
      getData.linegradualisshow = lineGradualisshow;
      if (lineGradualdata) {
        onMounted(() => {
          if (lineGradualdata.length) {
            for (var i = 0; i < lineGradualdata.length; i++) {
              echartGradual(lineGradualdata, i)
            }
          }
        })
      }
    } else {
      getData.linegradualisshow = false
    }

    //柱状图
    const echartInit = (datas, ide) => {   
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: datas[ide].text,
          subtext: datas[ide].subtext
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Rainfall']
        },
        toolbox: {
          show: true,
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: {show: true, type: ['line', 'bar']},
            restore: {show: true},
            saveAsImage: {show: true}
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            // data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            data: datas[ide].subtextx
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '在线时长',
            type: 'bar',
            // data: [9.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
            data: datas[ide].subdata,
            markPoint: {
              data: [
                {type: 'max', name: 'Max'},
                {type: 'min', name: 'Min'}
              ]
            },
            markLine: {
              data: [{type: 'average', name: 'Avg'}]
            },
            itemStyle: {
              color: datas[ide].subcolor
            },
          },

        ]
      };
      myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
      window.onresize = myChart.resize
    }
    // 仪表盘-半圆
    const echartPanel = (datas, ide) => {
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 10
            },
            axisLine: {
              lineStyle: {
                width: 10,
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 4,
              lineStyle: {
                width: 1,
                color: '#999'
              },
            },
            axisLabel: {
              distance: -35,
              color: '#999',
              fontSize: 12,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 15,
              itemStyle: {
                borderWidth: 4,
                borderColor: datas[ide].subcolor,
              }
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              color: datas[ide].subcolor,
              fontSize: 30,
              offsetCenter: [0, '40%'],
              formatter: function (value) {
                return Math.round(value) + '%';
              },
            },
            itemStyle: {
              color: datas[ide].subcolor
            },
            data: [
              {
                value: datas[ide].data,
              }
            ]
          }
        ]
      };

      myChart.setOption(option)
      window.onresize = myChart.resize
    }
    // 仪表盘-圆盘-（待更新）
    const echartCustomPanel = (datas, ide) => {
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 18
            },
            axisLine: {
              lineStyle: {
                width: 18,
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 10,
              lineStyle: {
                width: 1,
                color: '#999'
              },
            },
            axisLabel: {
              distance: -45,
              color: '#999',
              fontSize: 14,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 20,
              itemStyle: {
                borderWidth: 6,
                borderColor: datas[ide].subcolor,
              }
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              color: datas[ide].subcolor,
              fontSize: 40,
              offsetCenter: [0, '40%'],
              formatter: function (value) {
                return Math.round(value) + '%';
              },
            },
            itemStyle: {
              color: datas[ide].subcolor
            },
            data: [
              {
                value: datas[ide].data,
              }
            ]
          }
        ]
      };

      myChart.setOption(option)
      window.onresize = myChart.resize
    }
    //饼状图-圆角
    const echartFillet = (datas, ide) => {
      console.log('获取的饼状图数据：' + datas);
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '1%',
          left: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: datas[ide].data
          }
        ]
      };
      myChart.setOption(option)
      window.onresize = myChart.resize
    }
    //饼状图-自定义
    const echartCustom = (datas, ide) => {
      console.log('获取的饼状图自定义' + datas)
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        // backgroundColor: '#2c343c',
        title: {
          text: datas[ide].text,  //标题
          left: 'center',
          top: 20,
          textStyle: {
            color: datas[ide].textcolor //字体颜色
          }
        },
        tooltip: {
          trigger: 'item'
        },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1]
          }
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            data: datas[ide].data.sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: 'radius',
            label: {
              color: datas[ide].subcolor //解释字颜色
            },
            labelLine: {
              smooth: 0.2,
              length: 10,
              length2: 20
            },
            itemStyle: {
              color: datas[ide].subcolor, //板块颜色
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
          }
        ]
      };

      myChart.setOption(option)
      window.onresize = myChart.resize
    }
    //折线-渐变堆叠
    const echartGradual = (datas, ide) => {
      console.log('获取的折线-渐变堆叠:' + datas)
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        color: datas[ide].maincolor,
        title: {
          text: datas[ide].text,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'  //选中-下标模块颜色
            }
          }
        },
        legend: {
          data: datas[ide].legenddata
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: datas[ide].gridleft,
          right: datas[ide].gridright,
          bottom: datas[ide].gridbottom,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: datas[ide].subtextx
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: datas[ide].data
      };

      myChart.setOption(option)
      window.onresize = myChart.resize
    }

    return {
      getData,
      Columnisshow,
      Columndata,
      myPanelisshow,
      myPaneldata,
      myCustompanelisshow,
      myCustomPaneldata,
      filletCakeisshow,
      filletCakedata,
      Customcakeisshow,
      Customcakedata,
      lineGradualisshow,
      lineGradualdata,

      echartInit,
      echartPanel,
      echartCustomPanel,
      echartFillet,
      echartCustom,
      echartGradual,

    };
  }
};
</script>
<style scoped>

</style>