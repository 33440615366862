<template>
  <div style="margin-bottom: 10px">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(bread, i) in mainoneBread" :key="i">
        <span
          v-if="bread.path"
          @click="routeraddrClick(bread.path, bread.parameter)"
          class="mytabletext"
          >{{ bread.name }}</span
        >
        <span v-else>{{ bread.name }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "Mainone",
  props: ["mainonebread"],
  setup(props) {
    const mainoneBread = reactive(props["mainonebread"]);

    return {
      mainoneBread,
    };
  },
  methods: {
    routeraddrClick(routeraddr, rowdata) {
      //点击进入路由页面
      if (rowdata) {
        this.$router
          .push({
            path: routeraddr,
            query: rowdata,
          })
          .catch((error) => error);
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
  },
};
</script>

<style scoped>
.mytabletext {
  color: #333333;
  cursor: pointer;
}

.mytabletext:hover{
  color: var(--el-color-primary);
}
</style>
