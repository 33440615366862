<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">添加代理商</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :md="2" :lg="3" :xl="6">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :md="20" :lg="18" :xl="12">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
                                <!--姓名-->
                                <el-form-item label="姓名：" prop="name">
                                    <el-input v-model="ruleForm.name" clearable></el-input>
                                </el-form-item>
                                <!--手机号-->
                                <el-form-item label="手机号：" prop="username">
                                    <el-input v-model="ruleForm.username" placeholder="用于登录系统用户名" clearable></el-input>
                                </el-form-item>
                                <!-- 身份证号码 -->
                                <el-form-item label="身份证号码：" prop="idcard_no">
                                    <el-input v-model="ruleForm.idcard_no" placeholder="请输入身份证号" maxlength="18" clearable></el-input>
                                </el-form-item>
                                <!-- 身份证正面照 -->
                                <el-form-item label="身份证正面照：" prop="idcard_pic_front">
                                    <mainimgesup
                                        ref="Refmyimges_on"
                                        v-model="ruleForm.idcard_pic_front"
                                        :myimgesup="myimgesup_on"
                                        :myimgesupisshow="myimgesupisshow_on"
                                        :filelist="ruleForm.idcard_pic_front_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                                        v-on:deluploadfile="(data) => deluploadfileEvent(data, 1)"
                                        v-on:getfiletype="getfiletypeEvent"
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <!-- 身份证反面照 -->
                                <el-form-item label="身份证反面照：" prop="idcard_pic_back">
                                    <mainimgesup
                                        ref="Refmyimges_off"
                                        v-model="ruleForm.idcard_pic_back"
                                        :myimgesup="myimgesup_off"
                                        :myimgesupisshow="myimgesupisshow_off"
                                        :filelist="ruleForm.idcard_pic_back_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data, 2)"
                                        v-on:deluploadfile="(data) => deluploadfileEvent(data, 2)"
                                        v-on:getfiletype="getfiletypeEvent"
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <!--负责设备-->
                                <el-form-item label="负责设备：" prop="client_ids">
                                    <el-select v-model="ruleForm.client_ids" multiple filterable placeholder="请选择设备" style="width: 100%">
                                        <el-option :label="getsales.client" :value="getsales.id" v-for="(getsales, i) in client_ids_datas" :key="i"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!--打印佣金比例-->
                                <el-form-item label="打印佣金比例：" required="true">
                                    <el-form-item prop="ratio">
                                        <el-input v-model="ruleForm.ratio" clearable step="0.1" min="0" max="1" maxlength="5" :disabled="this.topisratio == -1 ? true : false">
                                        </el-input>
                                    </el-form-item>
                                </el-form-item>
                                <!-- 临时需求：20230320-隐藏流量变现佣金比例字段 -->
                                <!-- 流量变现佣金比例 required="true"-->
                                <!-- <el-form-item label="流量变现佣金比例：">
                                    <el-form-item prop="flow_ratio">
                                        <el-input v-model="ruleForm.flow_ratio" clearable step="0.1" min="0" max="1" maxlength="5"> </el-input>
                                    </el-form-item>
                                </el-form-item> -->
                                <!--选择地区-->
                                <el-form-item label="选择地区：" prop="area_code">
                                    <mainselect
                                        ref="citycode"
                                        v-model="ruleForm.area_code"
                                        :myselectcityleftisshow="myselectcityisshow"
                                        :myselectcity="myselectcity"
                                        v-on:getcitycode="getCityCodeEvent"
                                    ></mainselect>
                                </el-form-item>
                                <!--价格参数修改权限-->
                                <el-form-item label="价格参数修改权限：" prop="is_save_attr">
                                    <el-radio-group v-model="ruleForm.is_save_attr">
                                        <el-radio :label="-1">无</el-radio>
                                        <el-radio :label="1" v-if="this.radioinfo.is_save_attr == 1">有</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--是否自动提现-->
                                <el-form-item label="是否自动提现：" required="true">
                                    <div class="draw-box">
                                        <div class="draw-box-radio">
                                            <el-form-item prop="is_forthwith">
                                                <el-radio-group v-model="ruleForm.is_forthwith">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="-1">否</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </div>
                                        <div class="draw-box-input" v-if="ruleForm.is_forthwith == -1">
                                            <el-form-item prop="month_draw_times">
                                                <div style="margin: 0 10px 10px 0">
                                                    <el-input v-model="ruleForm.month_draw_times" clearable placeholder="每月手动提现次数"></el-input>
                                                </div>
                                            </el-form-item>
                                            <el-form-item prop="month_draw_minmoney">
                                                <div>
                                                    <el-input v-model="ruleForm.month_draw_minmoney" clearable placeholder="每次最少提现金额限制"> </el-input>
                                                </div>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </el-form-item>
                                <!--机器广告图修改权限-->
                                <el-form-item label="机器广告图修改权限：" prop="is_save_adv">
                                    <el-radio-group v-model="ruleForm.is_save_adv">
                                        <el-radio :label="-1">无</el-radio>
                                        <el-radio :label="1" v-if="this.radioinfo.is_save_adv == 1">有</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--订单退款权限-->
                                <el-form-item label="订单退款权限：" prop="refund_audit">
                                    <el-radio-group v-model="ruleForm.refund_audit">
                                        <el-radio :label="-1">无</el-radio>
                                        <el-radio :label="1" v-if="this.radioinfo.refund_audit == 1">有</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--提现微信-->
                                <el-form-item label="提现微信：" prop="wechat_user_id">
                                    <mainwechat
                                        ref="wechat"
                                        v-model="ruleForm.wechat_user_id"
                                        :mycashoutisshow="mycashoutisshow"
                                        :mycashout="mycashout"
                                        v-on:getpayaccount="(data) => getzhanghuCodeEvent(data, 'wechat')"
                                        v-on:getchangepaytype="getChangePaytypeEvent"
                                        v-on:getisshow="changeGetisshowEvent"
                                    ></mainwechat>
                                </el-form-item>
                                <!--提现支付宝-->
                                <el-form-item label="提现支付宝：" prop="alipay_user_id" style="display: none;">
                                    <mainwechat
                                        ref="paychat"
                                        v-model="ruleForm.alipay_user_id"
                                        :mycashoutisshow="mycashoutisshow"
                                        :mycashout="mycashout1"
                                        v-on:getpayaccount="(data) => getzhanghuCodeEvent(data, 'paychat')"
                                        v-on:getchangepaytype="getChangePaytypeEvent"
                                        v-on:getisshow="changeGetisshowEvent"
                                    ></mainwechat>
                                </el-form-item>
                                <!-- 有无押金权限 -->
                                <el-form-item label="有无押金权限：" prop="is_hock" v-if="myuserinfo.otherAuth.is_hock == 1">
                                    <el-radio-group v-model="ruleForm.is_hock">
                                        <el-radio :label="1">有</el-radio>
                                        <el-radio :label="-1">无</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!-- 押金金额 -->
                                <el-form-item label="押金金额：" prop="hock_money" v-if="myuserinfo.otherAuth.is_hock == 1">
                                    <el-radio-group v-model="ruleForm.hock_money">
                                        <el-radio :label="item.label" v-for="(item, index) in hock_options" :key="index">{{ item.value }}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--状态-->
                                <el-form-item label="状态：" prop="status">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio label="1">启用</el-radio>
                                        <el-radio label="-1">禁用</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--button按钮-->
                                <el-form-item style="margin-top: 50px">
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%" :disabled="mydisabled">提交 </el-button>
                                    <el-button @click="resetForm()">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :md="2" :lg="3" :xl="6">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import mainselect from '@/components/Mainselect.vue'
import mainwechat from '@/components/Mainwechat.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElMessageBox } from 'element-plus'
import { ref } from 'vue'
import axios from 'axios'

export default {
    name: 'vagentlistadd',
    data() {
        // 打印佣金比例-校验
        var checkRatio = (rule, value, callback) => {
            // console.log(rule, value);
            if (!value) {
                return callback(new Error('打印佣金比例不能为空'))
            }
            setTimeout(() => {
                // 当输入为浮点型的判断
                if (!Number.parseFloat(value)) {
                    callback(new Error('请输入小数'))
                } else {
                    if (value > 1) {
                        callback(new Error('打印佣金比例值小于等于1'))
                    } else {
                        callback()
                    }
                }
            }, 500)
        }
        // // 流量变现佣金比例-校验
        // var checkFlowratio = (rule, value, callback) => {
        //   // console.log(rule, value);
        //   if (!value) {
        //     return callback(new Error("流量变现佣金比例不能为空"));
        //   }
        //   setTimeout(() => {
        //     // 当输入为浮点型的判断
        //     if (!Number.parseFloat(value)) {
        //       callback(new Error("请输入小数"));
        //     } else {
        //       if (value > 1) {
        //         callback(new Error("流量变现佣金比例值小于等于1"));
        //       } else {
        //         callback();
        //       }
        //     }
        //   }, 500);
        // };
        return {
            mainonebread: [
                {
                    name: '代理商'
                },
                {
                    name: '代理商列表',
                    path: '/agentlist'
                },
                {
                    name: '添加代理商',
                    path: '/agentlistadd'
                }
            ], // 当前页-【面包屑导航】
            myselectcityisshow: true, //form表单-selectcity选框
            myselectcity: {
                selectwidth: '100%',
                maxwidth: '100%',
                marginright: '0',
                select: {
                    ismust: true,
                    name: 'area_code',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },
            mycashoutisshow: true, //form表单-提现微信/支付宝（单个组件）
            mycashout: {
                ide: 'wechat', // 必填标识：微信（'wechat'）,支付宝（'alipay'）
                titlewidth: '180px',
                cashout: [
                    {
                        title: '提现微信：',
                        ismust: true, //是否显示必填红标
                        isshowedit: false, //控制-首次进入页面时-编辑用户信息模块
                        isshowadd: true, //控制-首次进入页面时-添加提现信息的模块
                        avatarinfo: {
                            id: 1,
                            avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
                            nickname: '用户李四',
                            mobile: '18738573256'
                        }, //显示-首次进入页面时-提现用户信息
                        tantitle: '请选择提现微信', //弹窗-title名称
                        tantable: {
                            mainnick: '微信昵称',
                            searchpder: '请输入 微信昵称 / 手机号 查询'
                        }, //弹窗-table中的需改变的中文字段
                        addtitle: '添加提现微信', //添加-按钮的中文
                        addcolor: '#04BE02', //添加-按钮的颜色
                        paytype: 0, //默认提现方式 - 0表示无，1微信，2支付宝
                        paytypeisshow: true //默认提现方式显示 -默认显示false，不显示true
                    }
                ]
            }, //提现微信
            mycashout1: {
                ide: 'alipay',
                titlewidth: '180px',
                cashout: [
                    {
                        title: '提现支付宝：',
                        ismust: true, //是否显示必填红标
                        isshowedit: false, //控制-首次进入页面时-编辑用户信息模块
                        isshowadd: true, //控制-首次进入页面时-添加提现信息的模块
                        avatarinfo: {
                            id: 1,
                            avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
                            nickname: '用户李四',
                            mobile: '18738573256'
                        }, //显示-首次进入页面时-提现用户信息
                        tantitle: '请选择提现支付宝', //弹窗-title名称
                        tantable: {
                            mainnick: '支付宝昵称',
                            searchpder: '请输入 支付宝昵称 / 手机号 查询'
                        }, //弹窗-table中的需改变的中文字段
                        addtitle: '添加提现支付宝', //添加-按钮的中文
                        addcolor: '#0571dc', //添加-按钮的颜色
                        paytype: 0, //默认提现方式 - 0表示无，1微信，2支付宝
                        paytypeisshow: true //默认提现方式显示 -默认显示false，不显示true
                    }
                ]
            }, //提现支付宝

            //上传身份证-正面-imgesuploade上传图片
            myimgesupisshow_on: true,
            // 上传身份证-正面-上传图片
            myimgesup_on: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/shop/Withdraw/upload', //upload上传API地址
                headers: { Authen: localStorage.getItem('token') }, //upload上传headers
                title: '上传身份证（正面）', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },

            //上传身份证-正面-imgesuploade上传图片
            myimgesupisshow_off: true,
            // 上传身份证-正面-上传图片
            myimgesup_off: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/shop/Withdraw/upload', //upload上传API地址
                headers: { Authen: localStorage.getItem('token') }, //upload上传headers
                title: '上传身份证（反面）', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },

            // 负责设备-下拉框数据
            client_ids_datas: [],

            //form表单数据
            ruleForm: {
                name: '',
                username: '',
                client_ids: '',
                ratio: '',
                ratio_remarks: '',
                // flow_ratio: '',
                area_code: '',
                wechat_user_id: '',
                alipay_user_id: '',
                paytype: 0, //默认提现方式 1微信，2支付宝
                is_forthwith: ref(1),
                month_draw_times: '',
                month_draw_minmoney: '',
                is_save_attr: ref(-1),
                is_save_adv: ref(-1),
                refund_audit: ref(-1),
                status: ref('1'),
                is_ratio: '',
                is_hock: ref(-1), //是否押金权限 -1否 1是
                hock_money: 0, // 押金金额（分）
                idcard_no: '',
                idcard_pic_front: '',
                idcard_pic_front_list: [],
                idcard_pic_back: '',
                idcard_pic_back_list: []
            },
            //form表单填写规则-前端判断是否必填
            rules: {
                name: [
                    {
                        required: true,
                        message: '代理商姓名为必填项',
                        trigger: 'blur'
                    }
                ],
                username: [
                    {
                        required: true,
                        message: '手机号/账号为必填项',
                        trigger: 'blur'
                    }
                ],
                client_ids: [
                    {
                        required: false,
                        message: '负责设备为必选项',
                        trigger: 'blur'
                    }
                ],
                ratio: [
                    {
                        validator: checkRatio,
                        trigger: 'blur'
                    }
                ],
                // flow_ratio: [
                //   {
                //     validator: checkFlowratio,
                //     trigger: "blur",
                //   },
                // ],
                area_code: [
                    {
                        required: true,
                        message: '地区为必填项',
                        trigger: 'blur'
                    }
                ],
                wechat_user_id: [
                    {
                        required: false,
                        message: '提现微信必填项',
                        trigger: 'blur'
                    }
                ],
                alipay_user_id: [
                    {
                        required: false,
                        message: '提现支付宝必填项',
                        trigger: 'blur'
                    }
                ],
                is_forthwith: [
                    {
                        required: true,
                        message: '是否自动提现为必填',
                        trigger: 'change'
                    }
                ],
                month_draw_times: [
                    {
                        required: true,
                        message: '每月手动提现次数为必填',
                        trigger: 'change'
                    }
                ],
                month_draw_minmoney: [
                    {
                        required: true,
                        message: '每次最少提现金额限制为必填',
                        trigger: 'change'
                    }
                ],
                is_save_attr: [
                    {
                        required: true,
                        message: '价格参数修改权限为必填',
                        trigger: 'change'
                    }
                ],
                is_save_adv: [
                    {
                        required: true,
                        message: '机器广告图修改权限为必填',
                        trigger: 'change'
                    }
                ],
                refund_audit: [
                    {
                        required: true,
                        message: '订单退款权限为必填',
                        trigger: 'change'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态为必填',
                        trigger: 'change'
                    }
                ],
                is_hock: [
                    {
                        required: true,
                        message: '有无押金权限为必选',
                        trigger: 'change'
                    }
                ],
                hock_money: [
                    {
                        required: false,
                        message: '押金金额为必选',
                        trigger: 'change'
                    }
                ],
                idcard_no: [
                    {
                        required: false,
                        message: '身份证为必填',
                        trigger: 'change'
                    }
                ],
                idcard_pic_front: [
                    {
                        required: false,
                        message: '身份证正面照为必填',
                        trigger: 'change'
                    }
                ],
                idcard_pic_back: [
                    {
                        required: false,
                        message: '身份证反面照为必填',
                        trigger: 'change'
                    }
                ]
            },
            // 价格参数修改权限&机器广告图修改权限&订单退款权限-控制
            radioinfo: {
                is_save_attr: '', //价格参数修改权限
                is_save_adv: '', //机器广告图修改权限
                refund_audit: '' //订单退款权限
            },

            // top_is_ratio判断默认佣金比例是否禁用- 1否，-1是
            topisratio: '',

            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')), //获取存储的用户信息

            // 押金相关字段添加
            hock_options: {}, // 押金金额options

            mydisabled: false
        }
    },
    mounted() {},
    methods: {
        // 【身份证】-获取upload子组件回传的数据
        getUploadFileEvent(data, flag) {
            if (flag == 1) {
                this.ruleForm.idcard_pic_front = data.url
                this.ruleForm.idcard_pic_front_list = [
                    {
                        name: data.name,
                        url: data.url
                    }
                ]
            } else {
                this.ruleForm.idcard_pic_back = data.url
                this.ruleForm.idcard_pic_back_list = [
                    {
                        name: data.name,
                        url: data.url
                    }
                ]
            }
        },
        //【身份证】-删除上传的文件-事件
        deluploadfileEvent(data, flag) {
            if (flag == 1) {
                this.ruleForm.idcard_pic_front = ''
                this.ruleForm.idcard_pic_front_list = data
            } else {
                this.ruleForm.idcard_pic_back = ''
                this.ruleForm.idcard_pic_back_list = data
            }
        },
        //添加/删除后切换默认提现方式
        getChangePaytypeEvent(val) {
            // console.log("回传的值：" + val);
            if (this.mycashout.cashout[0].isshowadd == true && this.mycashout1.cashout[0].isshowadd == true) {
                this.ruleForm.paytype = 0
                this.mycashout.cashout[0].paytype = 0
                this.mycashout1.cashout[0].paytype = 0
            } else {
                this.ruleForm.paytype = val
                this.mycashout.cashout[0].paytype = val
                this.mycashout1.cashout[0].paytype = val
            }
            // console.log("之后：" + this.ruleForm.paytype);
        },
        //判断默认提现方式是否都为添加状态
        changeGetisshowEvent(val) {
            console.log('changeGetisshowEvent:' + val)
            if (val.ide == 'wechat') {
                this.mycashout.cashout[0].isshowedit = false
                this.mycashout.cashout[0].isshowadd = true
            } else {
                this.mycashout1.cashout[0].isshowedit = false
                this.mycashout1.cashout[0].isshowadd = true
            }
        },
        //获取公共组件回传的值-citycode
        getCityCodeEvent(data) {
            console.log('当前获取的citycode值为：' + data)
            this.ruleForm.area_code = data
        },
        //获取支付账号公共组件
        getzhanghuCodeEvent(data, payaccount) {
            if (payaccount == 'wechat') {
                console.log('获取返回的（微信）数据：' + JSON.stringify(data))
                if (data) {
                    this.ruleForm.wechat_user_id = data.id
                } else {
                    this.ruleForm.wechat_user_id = 0
                }
            } else {
                console.log('获取返回的（支付宝）数据：' + JSON.stringify(data))
                if (data) {
                    this.ruleForm.alipay_user_id = data.id
                } else {
                    this.ruleForm.alipay_user_id = 0
                }
            }
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            // 前端防抖
            this.mydisabled = true

            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // console.log(
                    //   "当前获取的form表单值：" +
                    //     JSON.stringify(this.$refs[formName].model)
                    // );
                    let clientids = ''
                    if (getdatas.client_ids.length > 0) {
                        clientids = getdatas.client_ids.join(',')
                    }
                    console.log(clientids)
                    axios
                        .post(
                            '/shop/shopUser/create',
                            {
                                name: getdatas.name,
                                username: getdatas.username,
                                client_ids: clientids,
                                ratio: getdatas.ratio,
                                // flow_ratio: getdatas.flow_ratio,
                                area_code: getdatas.area_code,
                                is_save_attr: getdatas.is_save_attr,
                                is_forthwith: getdatas.is_forthwith,
                                month_draw_times: getdatas.month_draw_times,
                                month_draw_minmoney: getdatas.month_draw_minmoney,
                                is_save_adv: getdatas.is_save_adv,
                                refund_audit: getdatas.refund_audit,
                                wechat_user_id: getdatas.wechat_user_id,
                                alipay_user_id: getdatas.alipay_user_id,
                                status: getdatas.status,
                                is_ratio: getdatas.is_ratio,
                                is_hock: getdatas.is_hock,
                                hock_money: getdatas.hock_money,
                                idcard_no: getdatas.idcard_no,
                                idcard_pic_front: getdatas.idcard_pic_front,
                                idcard_pic_back: getdatas.idcard_pic_back
                            },
                            {
                                headers: {
                                    Authen: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            console.log(response)
                            if (response.data.code === 0) {
                                ElMessageBox.alert(response.data.result, response.data.message, {
                                    confirmButtonText: '知道了',
                                    callback: () => {
                                        // 成功之后跳转到代理商列表页面
                                        this.$router
                                            .push({
                                                path: '/agentlist',
                                                query: { name: getdatas.username, status: getdatas.status }
                                            })
                                            .catch((error) => error)
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm() {
            this.$router.go(-1) // 返回上一页
            // this.$refs[formName].resetFields();
            // // 清空citycode
            // this.$refs.citycode.resetCitycode();
            // // 清空提现微信/支付宝[仅限于一个微信，一个支付宝（待优化）]
            // this.$refs.wechat.delWechatClient(0);
            // this.$refs.paychat.delWechatClient(0);
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 加载当前代理商相关信息-判断-价格参数&机器广告图&订单退款
            axios
                .post(
                    '/shop/shopUser/view',
                    {},
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.radioinfo.is_save_attr = response.data.result.is_save_attr
                    this.radioinfo.is_save_adv = response.data.result.is_save_adv
                    this.radioinfo.refund_audit = response.data.result.refund_audit

                    // 判断is_ratio【代理商是否分成】 1-分成 -1-仅商户，代理商不分成  2-禁用(该等级分成归公司所有) 3-禁用(所有等级分成都归公司所有)
                    this.ruleForm.is_ratio = response.data.result.is_ratio
                    // top_is_ratio判断默认佣金比例是否禁用- 1否，-1是
                    this.topisratio = response.data.result.top_is_ratio
                    if (response.data.result.top_is_ratio == -1) {
                        this.ruleForm.ratio = 1
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })

            // 加载代理商可分配设备
            axios
                .post(
                    '/shop/client/clientAllot',
                    {},
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.client_ids_datas = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })

            //获取-配置(押金金额)
            axios
                .post(
                    '/shop/user/configInfo',
                    {
                        key_name: 'agent_deposit'
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    // console.log(response.data.result)
                    let options = response.data.result
                    let keys = Object.keys(options)
                    this.hock_options = keys.map((n) => {
                        let label = n
                        let value = options[n]
                        return { label: parseInt(label), value: value }
                    })
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        mainselect,
        mainwechat,
        mainimgesup
    }
}
</script>

<style scoped>
/* 以下是：是否自动提现的css */
.draw-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.draw-box-radio {
    margin-right: 20px;
}

.draw-box-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* 以下是:当前页面整个box */
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}
</style>
