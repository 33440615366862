<template>
  <div>
    <!-- 面包屑导航栏 -->
    <mainone :mainonebread="mainonebread"></mainone>
    <!-- 搜索栏 -->
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent">
    </maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table ref="multipleTable" :row-class-name="tableRowClassName" :data="
            mainthreetableData.slice(
              (currentPage - lastpage) * PageSize,
              currentPage * PageSize
            )
          " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'uid' ||
                mytabletitle.prop === 'print_num' ||
                mytabletitle.prop === 'print_num_used'
              ">
                <!--用户ID-样式等渲染-->
                <div v-if="mytabletitle.prop === 'uid'">
                  <div v-if="scope.row.uid" style="cursor: pointer" @click="routeraddrClick('/userlist')">
                    <span style="color: #465eff">{{ scope.row.uid }}</span>
                  </div>
                </div>
                <!--会员使用数量-样式等渲染-->
                <div v-if="mytabletitle.prop === 'print_num'">
                  <div style="cursor: pointer">
                    <el-popover placement="bottom-start" title="会员使用量:" :width="200" trigger="click"
                      :content="scope.row.getdatas">
                      <template #reference>
                        <span style="color: #465eff" :ref="'btnShowNum' + scope.$index"
                          @click="showunm(scope.$index, scope.row)">查看</span>
                      </template>
                    </el-popover>
                  </div>
                </div>
                <!--用户ID-样式等渲染-->
                <div v-if="mytabletitle.prop === 'print_num_used'">
                  <div style="cursor: pointer">
                    <el-popover placement="bottom-start" title="已打印数量:" :width="200" trigger="click"
                      :content="scope.row.getused">
                      <template #reference>
                        <span style="color: #465eff" :ref="'btnShowNum' + scope.$index"
                          @click="showused(scope.$index, scope.row)">查看</span>
                      </template>
                    </el-popover>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vmemberdvcbuy",
  data() {
    return {
      mainonebread: [
        {
          name: "首页",
        },
        {
          name: "设备会员",
          path: "/memberdvcbuy",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号",
              name: "client",
              content: "",
            },
            {
              ismust: false, //是否必填，true表示必填
              title: "手机号：",
              placeholder: "请输入手机号",
              name: "keywords",
              content: "",
            },
          ],
        },
        // myinputonefullisshow: true, //筛选查询-自动建议下拉框
        // myinputonefull: {
        //   divinterval: "0", //框右侧距离
        //   titlewidth: "74px", //标题宽度
        //   inputwidth: "200px", //input宽度
        //   inputinterval: "20px", //input右侧距离
        //   input: [
        //     {
        //       ismust: false, //是否必填，true表示必填
        //       title: "设备编号：",
        //       placeholder: "请输入设备编号", //此name为键值
        //       name: "client",
        //       content: "",
        //       id: "", //此id为给后台的id值
        //       url: "/shop/Equipment/searchClient",
        //       posttype:'get', 
        //       inputval: { client: "" }, //这里记录需要传的参数
        //     },
        //   ],
        // },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间（放input框之前）
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-控件信息

      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: false,
          prop: "thirdparty",
          label: "平台来源",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "mobile",
          label: "手机号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "client",
          label: "设备编号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "day",
          label: "时间",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "price",
          label: "实付金额",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "endtime",
          label: "VIP到期时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "print_num",
          label: "会员使用数量",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "print_num_used",
          label: "已打印数量",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "购买时间",
          width: "200",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始 化-loading加载动效，默认是false关闭

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "设备编号：",
            placeholder: "请输入设备编号",
            name: "client",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "手机号：",
            placeholder: "请输入手机号",
            name: "keywords",
            content: "",
          },
        ],
      },
      // myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
      // myinputonefullgao: {
      //   divinterval: "3%", //框右侧距离
      //   inputwidth: "47%", //input宽度
      //   inputmaxwidth: "100%",
      //   input: [
      //     {
      //       ismust: false, //是否必填，true表示必填
      //       title: "设备编号：",
      //       placeholder: "请输入设备编号", //此name为键值
      //       name: "client",
      //       content: "",
      //       id: "", //此id为给后台的id值
      //       url: "/shop/Equipment/searchClient",
      //       posttype: 'get',
      //       inputval: { client: "" }, //这里记录需要传的参数
      //     },
      //   ],
      // },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "thirdparty",
            value: "1",
            valueisshow: true,
            valuedefault: "1", //设置默认值
            options: componentProperties.get('thirdpartyoption').options,
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "到期时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "endtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    showunm(index, row) {
      row.getdatas =
        "A4:" + row.num1 + "     A3:" + row.num2 + "     A6:" + row.num3;
    },
    showused(index, row) {
      row.getused =
        "A4:" +
        row.print_num1 +
        "     A3:" +
        row.print_num2 +
        "     A6:" +
        row.print_num3;
    },

    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/shop/Equipment/equipment", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {
            thirdparty: this.pagesdatas.thirdparty,
            client: this.pagesdatas.client,
            keywords: this.pagesdatas.keywords,
            endtime: this.pagesdatas.endtime,
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/shop/Equipment/equipment", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {
            thirdparty: this.pagesdatas.thirdparty,
            client: this.pagesdatas.client,
            keywords: this.pagesdatas.keywords,
            endtime: this.pagesdatas.endtime,
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch((error) => error);
    },
    //导出数据
    exportDatas() {
      //通过Ajax向后台获取数据
      axios
        .get("/shop/Equipment/equipment", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {
            thirdparty: this.pagesdatas.thirdparty,
            client: this.pagesdatas.client,
            keywords: this.pagesdatas.keywords,
            endtime: this.pagesdatas.endtime,
            export: 1,
          },
        })
        .then((response) => {
            console.log(response.data.message)
        //   ElMessage({ type: "success", message: response.data.message });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-申请时间段状态
      var getendtime = null;
      if (data.endtime.start == "") {
        getendtime = "";
      } else {
        if (data.endtime.end == "") {
          getendtime =
            data.endtime.start + " ~ " + (data.endtime.start + 86400 - 1);
        } else {
          getendtime =
            data.endtime.start + " ~ " + (data.endtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.endtime = getendtime;
      //通过Ajax向后台获取数据
      axios
        .get("/shop/Equipment/equipment", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {
            thirdparty: data.thirdparty,
            client: data.client,
            keywords: data.keywords,
            endtime: data.endtime,
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/shop/Equipment/equipment", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {
            thirdparty: data.thirdparty,
            client: data.client,
            keywords: data.keywords,
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          console.log(response);
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata ? successdata.data : "";
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
        // 平台来源
        if (newdata[i].thirdparty) {
          if (newdata[i].thirdparty == 1) {
            newdata[i].thirdparty = "微信自助版";
          } else {
            newdata[i].thirdparty = "支付宝自助版";
          }
        }
        if (newdata[i].addtime) {
          newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
        } else {
          newdata[i].addtime = "无购买时间";
        }
        if (newdata[i].endtime) {
          newdata[i].endtime = this.$utils.formatDate(newdata[i].endtime);
        } else {
          newdata[i].endtime = "VIP到期时间";
        }
        if (newdata[i].day) {
          newdata[i].day = newdata[i].day + "天";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      if (successdata) {
        this.totalCount = successdata.total;
        this.currentpage = successdata.current_page; //当前页面页数
        this.lastpage = successdata.last_page;
      }
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 定义
      this.pagesdatas = {
        thirdparty: 1,
        page: 1,
        page_size: this.PageSize,
      }

      //通过Ajax向后台获取数据
      axios
        .get("/shop/Equipment/equipment", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: this.pagesdatas,
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>