<template>
    <div id="mymain">
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font"><i class="el-icon-info"></i> 退款详情</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <div class="main_one_button">
                            <el-button
                                type="primary"
                                plain
                                @click="addTanClick(this.mainpagedata.datas, (dialogVisible = true))"
                                v-if="this.mainpagedata.datas.status == -1 && this.userinfo.otherAuth.refund_audit == 1"
                                >处理</el-button
                            >
                        </div>
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <!-- <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px" ></maindetails> -->
                    <!--[由于牵扯特殊弹框组件-且只用一次，因此未使用公用组件]-->
                    <div style="margin-top: 20px">
                        <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px">
                            <template #contentJumpbt="{ detailsdatainfo }">
                                <div v-if="this.printstatus == 1" style="color: #465eff; cursor: pointer" @click="addTanClick(getrowdata, (dialogVisible1 = true))">
                                    {{ detailsdatainfo.jumpbt }}
                                </div>
                                <div v-if="this.printstatus == 2">
                                    {{ detailsdatainfo.jumpbt }}
                                </div>
                            </template>
                        </maindetails>
                    </div>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details1"></maindetails>
                    <div class="text_img_box">
                        <div class="text_img_box_title">
                            <span style="font-size: 14px">反馈图片:</span>
                        </div>
                        <div class="text_img_box_photos">
                            <mainimgesshow :imgesdata="mainpagedata.imgesdata"></mainimgesshow>
                        </div>
                    </div>
                    <el-divider></el-divider>

                    <div>
                        <span style="font-size: 14px; font-weight: 600; margin-bottom: 20px">处理信息</span>
                        <div class="details-box" v-for="(item, index) in mainpagedata.details2" :key="index">
                            <maindetails :detailsdata="item"></maindetails>
                            <div class="text_img_box">
                                <div class="text_img_box_title">
                                    <span style="font-size: 14px">回复图片:</span>
                                </div>
                                <div>
                                    <mainimgesshow :imgesdata="item.imgesdata1"></mainimgesshow>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--弹出框-处理-->
        <el-dialog v-model="dialogVisible" title="处理" :width="this.searchwidth" :before-close="handleCloseSenior">
            <div>
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
                    <div style="min-height: 400px; overflow: hidden; overflow-y: visible; margin: 20px 5% 0 0">
                        <el-form-item label="退款张数：" prop="number">
                            <el-input v-model.number="ruleForm.number" :max="ruleForm.refund_pages" step="1" clearable>
                                <template #append>张</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="申请退款金额：">
                            <div class="money_box">
                                <div class="money_box_content" v-if="ruleForm.number == ruleForm.refund_pages">
                                    ¥
                                    {{ ruleForm.lastmoney }}
                                </div>
                                <div class="money_box_content" v-else>
                                    ¥
                                    {{ ruleForm.number * ruleForm.price > ruleForm.lastmoney ? ruleForm.lastmoney : (ruleForm.number * ruleForm.price).toFixed(2) }}
                                </div>
                                <div class="money_box_content">(剩余最多退款金额 ¥ {{ ruleForm.lastmoney }})</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="选择处理方式：" prop="type">
                            <el-radio-group v-model="ruleForm.type">
                                <el-radio :label="2">驳回</el-radio>
                                <el-radio :label="1">同意退款</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="回复内容：" prop="remark">
                            <el-input v-model="ruleForm.remark" type="textarea" minlength="10" maxlength="50" show-word-limit :autosize="{ minRows: 2 }" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="留言图片(选填)：">
                            <mainimgesup
                                ref="myimges"
                                v-model="ruleForm.reply_img"
                                :myimgesup="myimgesup"
                                :myimgesupisshow="myimgesupisshow"
                                :filelist="ruleForm.reply_img_list"
                                v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                v-on:deluploadfile="(data) => deluploadfileEvent(data)"
                                v-on:getfiletype="getfiletypeEvent"
                                @click="changeMainimgesupEvent(this.changeMainimgesup)"
                            >
                            </mainimgesup>
                        </el-form-item>
                        <el-form-item style="text-align: right">
                            <el-button @click="resetForm('ruleForm')">重置</el-button>
                            <el-button id="btnok" type="primary" @click="submitForm('ruleForm', ruleForm.type)" :disabled="mydisabled">确认</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </el-dialog>

        <!--弹出框-打印记录-->
        <el-dialog v-model="dialogVisible1" title="打印记录" :width="this.searchwidth1" :before-close="handleCloseSenior">
            <div>
                <el-table :data="formdata" style="width: 100%" border>
                    <el-table-column label="文档信息" prop="file">
                        <template #default="scope">
                            <div>
                                <div style="font-size: 16px; color: #333333; margin: 10px 0">
                                    {{ scope.row.file.filename }}
                                </div>
                                <div style="font-size: 13px; color: #999999">打印范围 {{ scope.row.file.start }}~{{ scope.row.file.end }} 页 {{ scope.row.file.number }} 份</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="打印状态" prop="status" width="150">
                        <template #default="scope">
                            <div>
                                <div
                                    :style="{
                                        color: scope.row.status == '待打印' ? '#f56c6c' : '#67c23a'
                                    }"
                                >
                                    {{ scope.row.status }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
import mainimgesup from '@/components/Mainimgesup.vue'
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default defineComponent({
    name: 'refundinfo',
    inject: ['reload'], //刷新引用
    data() {
        // 处理-退款张数-张数校验
        var checkBig = (rule, value, callback) => {
            //获取key值为ide下的相关数据
            var getdatas = this.ruleForm
            setTimeout(() => {
                // 当输入为整数的判断
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'))
                } else {
                    if (getdatas.number > getdatas.refund_pages) {
                        callback(new Error('当前订单最多可退张数为：' + getdatas.refund_pages + ' 张'))
                    } else {
                        if (getdatas.number < 0) {
                            callback(new Error('张数最少为：0张'))
                        } else {
                            callback()
                        }
                    }
                }
            }, 500)
        }
        return {
            mainpagedata: {
                mainonebread: [
                    {
                        name: '订单'
                    },
                    {
                        name: '退款申请',
                        path: '/refundapplication'
                    },
                    {
                        name: '退款详情'
                        // path: "/refundapplicationinfo",
                    }
                ], // 当前页-【面包屑导航】

                datas: {}, //接收接口中所有的数据

                details: {
                    showstatus: 1, //确定样式模块
                    title: '订单信息',
                    button: {
                        isshow: false,
                        name: '查看微信投诉详情',
                        jump: '/refundwechatinfo', //点击跳转-微信投诉详情
                        jumpcontent: {
                            id: '',
                            thirdparty: ''
                        }
                    },
                    content: [
                        {
                            name: '订单编号:',
                            value: ''
                        },
                        {
                            name: '平台来源:',
                            value: ''
                        },
                        {
                            name: '订单状态:',
                            value: ''
                        },
                        {
                            name: '打印状态:',
                            value: '',
                            jumpbt: '打印中>>'
                        },
                        {
                            name: '打印张数:',
                            value: ''
                        },
                        {
                            name: '打印费:',
                            value: ''
                        },
                        {
                            name: '文档使用费:',
                            value: ''
                        },
                        {
                            name: '设备会员:',
                            value: ''
                        },
                        {
                            name: '优惠方式:',
                            value: ''
                        },
                        {
                            name: '优惠金额:',
                            value: ''
                        },
                        {
                            name: '实付金额:',
                            value: ''
                        },
                        {
                            name: '实付打印费(含设备会员):',
                            value: ''
                        },
                        {
                            name: '支付方式:',
                            value: ''
                        },
                        {
                            name: '用户手机号:',
                            value: ''
                        },
                        {
                            name: '用户ID:',
                            value: ''
                        },
                        {
                            name: '支付时间:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                details1: {
                    showstatus: 1, //确定样式模块
                    title: '反馈信息',
                    button: {
                        isshow: false,
                        name: ''
                    },
                    content: [
                        {
                            name: '设备编号:',
                            value: ''
                        },
                        {
                            name: '申请退款张数:',
                            value: ''
                        },
                        {
                            name: '申请退款金额:',
                            value: ''
                        },
                        {
                            name: '退款类型:',
                            value: ''
                        },
                        {
                            name: '反馈详情:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                details2: [
                    // {
                    //   showstatus: 1, //确定样式模块
                    //   title: "",
                    //   button: {
                    //     isshow: false,
                    //     name: "",
                    //   },
                    //   content: [
                    //     {
                    //       name: "处理状态:",
                    //       value: "",
                    //     },
                    //     {
                    //       name: "处理平台:",
                    //       value: "",
                    //     },
                    //     {
                    //       name: "处理人:",
                    //       value: "",
                    //     },
                    //     {
                    //       name: "处理时间:",
                    //       value: "",
                    //     },
                    //     {
                    //       name: "实际退款金额:",
                    //       value: "",
                    //     },
                    //     {
                    //       name: "处理回复:",
                    //       value: "",
                    //     },
                    //   ],
                    //   //关于图片展示的组件-回复图片
                    //   imgesdata1: {
                    //     showstatus: 2,
                    //     content: [
                    //       // {
                    //       //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                    //       //   preview: [
                    //       //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                    //       //   ],
                    //       // },
                    //     ],
                    //   },
                    // }
                ], //关于详情信息的组件

                //关于图片展示的组件-反馈图片
                imgesdata: {
                    showstatus: 2,
                    content: [
                        // {
                        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                        //   preview: [
                        //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                        //   ],
                        // },
                    ]
                }
            }, // 当前Pagedata所需的data数据与控制

            // 弹窗-默认宽度
            searchwidth: '580px',
            // 弹窗-处理
            dialogVisible: false,
            // 弹窗-处理-form表单
            ruleForm: {
                number: 0,
                type: 1,
                remark: '',
                reply_img: '',
                reply_img_list: [], //当前订单-展示image
                refund_pages: 0, //当前订单-最多可退张数
                price: 0, //当前订单-单价
                thirdparty: '', //当前订单-所属第三方平台
                trade_no: '', //当前订单-订单编号
                lastmoney: '', // 剩余最大可退金额
                admin_remark: '' //处理备注-前端占位
            },
            rules: {
                number: [
                    {
                        required: true,
                        validator: checkBig,
                        trigger: 'change'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '处理方式为必选项',
                        trigger: 'change'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '回复内容不能为空',
                        trigger: 'change'
                    },
                    {
                        required: false,
                        min: 10,
                        max: 50,
                        message: '回复内容在10-50字',
                        trigger: 'change'
                    }
                ]
            },
            //上传-【处理】-imgesuploade上传图片
            myimgesupisshow: true,
            // 上传-【处理】-上传留言图片
            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: true, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                data: { id: '', thirdparty: '' }, // upload上传时的携带参数
                action: '/shop/ShareQuestion/wechatImg', //upload上传API地址
                headers: { Authen: localStorage.getItem('token') }, //upload上传headers
                multiple: true, //上传多个文件
                title: '上传图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '支持扩展名：*.jpg、*.jpeg、*.png ; 最多上传4张图片，每张图片大小不超过5M', //提示&建议文字
                limit: '4', //上传数量限制,0表示不限
                target_id: 10, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；10-退款审核
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 5000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/jpeg/png' //文件格式提示规则
                    }
                }
            },
            chuliinfo: '', //加载退款信息

            // 弹窗-打印中-打印记录信息
            dialogVisible1: false,
            searchwidth1: '580px',
            formdata: [
                {
                    file: {
                        filename: 'filename123',
                        start: '1',
                        end: '3',
                        number: 1
                    },
                    status: '待打印'
                }
            ],
            // 打印中-状态-维护字段-【当数据为空时，打印状态不可点击,1可以点击,2不可点击】
            printstatus: 1,

            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
            userinfo: JSON.parse(localStorage.getItem('userinfo')), //获取用户信息

            //存储当前页传参信息
            pagesdatas: {
                id: '',
                thirdparty: ''
            },

            imges: [], //维护imges字段
            wechatimg: [], //维护wechatimg字段

            mydisabled: false,

            loading: false
        }
    },
    methods: {
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log('当前获取的taninfo is :' + JSON.stringify(taninfo))
            let dataarray = []
            if (taninfo.reply_img != '0') {
                let imgearray = taninfo.reply_img.split(',')
                if (imgearray.length > 0) {
                    imgearray.forEach((element) => {
                        let onedata = {
                            name: '',
                            url: element
                        }
                        dataarray.push(onedata)
                    })
                }
            }

            // this.ruleForm.number = taninfo.pages - taninfo.cumu_pages; //当前订单-最大可退张数
            this.ruleForm.number = taninfo.number //当前订单-最大可退张数
            this.ruleForm.remark = ''
            this.ruleForm.admin_remark = ''
            this.ruleForm.reply_img = ''
            this.ruleForm.reply_img_list = []
            this.ruleForm.refund_pages = taninfo.pages - taninfo.cumu_pages //当前订单-最大可退张数
            this.ruleForm.price = taninfo.price / 100 //当前订单-单价（分转元）
            this.ruleForm.thirdparty = taninfo.thirdparty //当前订单-平台来源
            this.ruleForm.trade_no = taninfo.trade_no //当前订单-订单编号
            this.ruleForm.lastmoney = ((taninfo.refund_money - taninfo.cumu_refund) / 100).toFixed(2) // 剩余最大可退金额

            // 弹窗-上传图片需携带参数
            this.changeMainimgesup = {
                id: taninfo.id,
                thirdparty: taninfo.thirdparty
            }
        },
        // 点击上传图片按钮
        changeMainimgesupEvent(datas) {
            this.myimgesup.data = { id: datas.id, thirdparty: datas.thirdparty }
        },
        // 弹出框[处理-按钮]-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            // console.log("当前获取的回传数据：" + JSON.stringify(data));
            let onedata = {
                id: data.file_info.id,
                name: data.name,
                url: data.file_info.url
            }
            this.imges.push(onedata)
            this.wechatimg.push(data.wechat_image)
        },
        //弹出框[处理-按钮]-删除上传的文件-事件（upload组件）
        deluploadfileEvent(data) {
            // console.log("当前删除事件-返回的数据：" + JSON.stringify(data));
            if (data.length > 0) {
                let dataarray = []
                data.forEach((element) => {
                    let onedata = {
                        id: element.response.result.file_info.id,
                        name: element.name,
                        url: element.response.result.file_info.url
                    }
                    dataarray.push(onedata)
                })
                this.ruleForm.reply_img_list = dataarray
                this.imges = dataarray //维护imges字段
            } else {
                this.ruleForm.reply_img_list = []
                this.imges = [] //维护imges字段
                this.wechatimg = [] //维护wechatimg字段
            }
        },
        //弹出框[处理-按钮]-提交表单
        submitForm(formName, typeide) {
            // 前端防抖
            this.mydisabled = true

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model
                    let ajaxurl = ''
                    let ajaxdata = {}
                    // typeide：1同意2驳回
                    if (typeide == 1) {
                        let imgesarrary = []
                        let imagesid = []
                        // getdatas.reply_img_list = getdatas.reply_img_list.concat(this.imges);
                        getdatas.reply_img_list = this.imges
                        console.log(getdatas.reply_img_list)
                        getdatas.reply_img_list.forEach((element) => {
                            imgesarrary.push(element.url)
                            imagesid.push(element.id)
                        })
                        getdatas.reply_img = imgesarrary.join(',')
                        getdatas.reply_img_ids = imagesid.join(',')
                        ajaxurl = '/shop/ShareQuestion/returnMoney'
                        ajaxdata = {
                            thirdparty: getdatas.thirdparty,
                            trade_no: getdatas.trade_no,
                            status: getdatas.type,
                            remark: getdatas.remark,
                            admin_remark: '',
                            money:
                                getdatas.number == getdatas.refund_pages
                                    ? getdatas.lastmoney
                                    : getdatas.number * getdatas.price > getdatas.lastmoney
                                    ? getdatas.lastmoney
                                    : getdatas.number * getdatas.price,
                            number: getdatas.number,
                            reply_img: getdatas.reply_img_ids,
                            wechat_img: this.wechatimg.length > 0 ? this.wechatimg.join(',') : []
                        }
                    } else {
                        let imgesarrary = []
                        let imagesid = []
                        // getdatas.reply_img_list = getdatas.reply_img_list.concat(this.imges);
                        getdatas.reply_img_list = this.imges
                        getdatas.reply_img_list.forEach((element) => {
                            imgesarrary.push(element.url)
                            imagesid.push(element.id)
                        })
                        getdatas.reply_img = imgesarrary.join(',')
                        getdatas.reply_img_ids = imagesid.join(',')
                        ajaxurl = '/shop/ShareQuestion/reject'
                        ajaxdata = {
                            thirdparty: getdatas.thirdparty,
                            trade_no: getdatas.trade_no,
                            status: getdatas.type,
                            remark: getdatas.remark,
                            admin_remark: '',
                            money:
                                getdatas.number == getdatas.refund_pages
                                    ? getdatas.lastmoney
                                    : getdatas.number * getdatas.price > getdatas.lastmoney
                                    ? getdatas.lastmoney
                                    : getdatas.number * getdatas.price,
                            number: getdatas.number,
                            reply_img: getdatas.reply_img_ids,
                            wechat_img: this.wechatimg.length > 0 ? this.wechatimg.join(',') : []
                        }
                    }
                    axios
                        .post(ajaxurl, ajaxdata, {
                            headers: {
                                Authen: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.reload() //刷新
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.dialogVisible = ref(false) //关闭弹窗
                                this.mydisabled = false
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //弹出框[处理-按钮]-重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        //点击进入路由页面
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转
                if (routeraddr == '/refundwechatinfo') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                id: rowdata.id,
                                thirdparty: rowdata.thirdparty
                            }
                        })
                        .catch((error) => error)
                } else {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { tradeno: rowdata.trade_no }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //获取当前windows页面信息-实时修改【高级筛选】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
                this.searchwidth1 = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
                this.searchwidth1 = '580px'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            // 存储-传参
            this.pagesdatas.id = this.$route.query.id
            this.pagesdatas.thirdparty = this.$route.query.thirdparty

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/shop/ShareQuestion/info',
                    {
                        id: this.$route.query.id, //订单id
                        thirdparty: this.$route.query.thirdparty //平台来源
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.result)
                    this.mainpagedata.datas = response.data.result
                    let getdatas = response.data.result

                    // 本id下的退款信息
                    this.chuliinfo = getdatas
                    //控制微信申诉记录详情按钮
                    this.mainpagedata.details.button.isshow = getdatas.complaint_id === '0' ? false : true
                    this.mainpagedata.details.button.jumpcontent.id = this.$route.query.id
                    this.mainpagedata.details.button.jumpcontent.thirdparty = this.$route.query.thirdparty

                    var content1 = [] //订单信息
                    content1 = [
                        {
                            name: '订单编号:',
                            value: getdatas.trade_no
                        },
                        {
                            name: '平台来源:',
                            value: getdatas.thirdparty_platform
                        },
                        {
                            name: '订单状态:',
                            value: getdatas.ShareOrder.pay_status_name
                        },
                        {
                            name: '打印状态:',
                            value: '',
                            jumpbt: getdatas.ShareOrder.print_status_name + ' >>'
                        },
                        {
                            name: '打印张数:',
                            value: getdatas.ShareOrder.countpages
                        },
                        {
                            name: '打印费(含服务费):',
                            value: (getdatas.ShareOrder.printtotal / 100).toFixed(2)
                        },
                        {
                            name: '文档使用费:',
                            value: (getdatas.ShareOrder.mobetotal / 100).toFixed(2)
                        },
                        {
                            name: '三方服务费:',
                            value: (getdatas.ShareOrder.mattingprice / 100).toFixed(2)
                        },
                        {
                            name: '设备会员:',
                            value: getdatas.ShareOrder.monthlytotal != null ? (getdatas.ShareOrder.monthlytotal / 100).toFixed(2) : ''
                        },
                        {
                            name: '优惠方式:',
                            value: getdatas.ShareOrder.discount_type_name
                        },
                        // {
                        //     name: '优惠金额:',
                        //     // 当优惠方式为 6：权益会员 时，显示合计，其余的正常显示优惠券优惠
                        //     value: getdatas.ShareOrder
                        //         ? getdatas.ShareOrder.discount_type == 6
                        //             ? this.$utils.moneyAndDiscountToExchange(
                        //                   getdatas.ShareOrder.discount_price,
                        //                   getdatas.ShareOrder.mobetotal_discount,
                        //                   getdatas.ShareOrder.printtotal
                        //               )
                        //             : (response.data.result.ShareOrder.discount_price / 100).toFixed(2)
                        //         : ''
                        // },
                        {
                            name: '优惠金额:',
                            value: getdatas.ShareOrder
                                ? this.$utils.moneyAndDiscountToExchange(
                                      getdatas.ShareOrder.discount_type,
                                      getdatas.ShareOrder.discount_price,
                                      getdatas.ShareOrder.mobetotal_discount,
                                      getdatas.ShareOrder.printtotal
                                  )
                                : ''
                        },
                        {
                            name: '实付金额:',
                            value: (getdatas.ShareOrder.sftotal / 100).toFixed(2)
                        },
                        {
                            name: '实付打印费(含设备会员和服务费):',
                            value: (getdatas.refund_money / 100).toFixed(2)
                        },
                        {
                            name: '支付方式:',
                            value: getdatas.ShareOrder.paytype_name
                        },
                        {
                            name: '用户手机号:',
                            value: getdatas.mobile
                        },
                        {
                            name: '用户ID:',
                            value: getdatas.uid
                        },
                        {
                            name: '支付时间:',
                            value: getdatas.ShareOrder.pay_time != null ? this.$utils.formatDate(getdatas.ShareOrder.pay_time) : ''
                        }
                    ]
                    this.mainpagedata.details.content = content1

                    var content2 = [] //反馈信息
                    content2 = [
                        {
                            name: '设备编号:',
                            value: getdatas.ShareClient.client
                        },
                        {
                            name: '申请退款张数:',
                            value: getdatas.number
                        },
                        {
                            name: '申请退款金额:',
                            value: (getdatas.money / 100).toFixed(2)
                        },
                        {
                            name: '退款类型:',
                            value: getdatas.type_name
                        },
                        {
                            name: '反馈详情:',
                            value: getdatas.content
                        }
                    ]
                    this.mainpagedata.details1.content = content2

                    // 处理信息
                    let getchuli = response.data.result.auditsInfo
                    getchuli.forEach((element, key) => {
                        var content3 = []
                        content3 = [
                            {
                                name: '处理状态:',
                                value: element.content.status_name
                            },
                            {
                                name: '处理平台:',
                                value: element.process_platform
                            },
                            {
                                name: '处理人:',
                                value: element.process_name
                            },
                            {
                                name: '处理时间:',
                                value: element.addtime != null ? this.$utils.formatDate(element.addtime) : ''
                            },
                            {
                                name: '实际退款金额:',
                                value: element.content.status == -1 ? '' : (element.content.money / 100).toFixed(2)
                            },
                            {
                                name: '处理回复:',
                                value: element.remark
                            }
                        ]

                        let datas = {
                            showstatus: 1, //确定样式模块
                            title: '处理' + (parseInt(key) + 1),
                            button: {
                                isshow: false,
                                name: ''
                            },
                            content: content3,
                            // 处理-图片
                            imgesdata1: {
                                showstatus: 2,
                                content: []
                            }
                        }

                        // 回复图片
                        let huifuimge = []
                        if (element['content'].reply_img_list) {
                            let preview = []
                            element['content'].reply_img_list.forEach((element1) => {
                                preview.push(element1.file_url)
                                let data = {
                                    url: element1.file_url,
                                    preview: preview
                                }
                                huifuimge.push(data)
                            })
                        }
                        datas.imgesdata1.content = huifuimge

                        this.mainpagedata.details2.push(datas)
                    })

                    // 控制-查看微信投诉详情按钮
                    if (getdatas.complaint_id != '0') {
                        this.mainpagedata.details.button.isshow = true
                    }

                    // 反馈图片
                    let backimge = []
                    if (getdatas.image_list) {
                        let preview = []
                        getdatas.image_list.forEach((element) => {
                            preview.push(element)
                            let data = {
                                url: element,
                                preview: preview
                            }
                            backimge.push(data)
                        })
                    }
                    this.mainpagedata.imgesdata.content = backimge

                    // 显示-弹窗-打印记录信息
                    let printinfo = response.data.result.ShareOrder.ShareOrderFile
                    let nowform = []
                    if (printinfo.length > 0) {
                        printinfo.forEach((element) => {
                            let datas = {
                                file: {
                                    filename: element.file_name,
                                    start: element.start,
                                    end: element.end,
                                    number: element.number
                                },
                                status: element.status_name
                            }
                            nowform.push(datas)
                        })
                    }
                    this.formdata = nowform
                    // 打印记录显示与否判断-维护点击状态字段
                    if (nowform.length > 0) {
                        this.printstatus = 1
                    } else {
                        this.printstatus = 2
                    }

                    this.loading = false
                })
                .catch(function (error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow,
        mainimgesup
    }
})
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px 30px;
    margin-top: 20px;
    margin-bottom: 80px;
}

.box_title {
    line-height: 40px;
    height: 40px;
    margin-top: 10px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.main_one_button {
    float: right;
}

.text_img_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.text_img_box_title {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 20px 20px 0;
}

.money_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.money_box_content {
    margin: 0 10px 10px 0;
}

/* 处理信息 */
.details-box {
    margin-top: 20px;
}
</style>
