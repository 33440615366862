<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <div
                        style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;align-items: center;">
                        <div
                            style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: left;align-items: center;">
                            <!--左侧名称-->
                            <div style="font-size: 14px">数据列表</div>
                            <!-- 设备台数等信息 -->
                            <div v-if="this.isdisplaytotal == 1" class="dvcinfo-box">
                                <div class="dvcinfo-box-div"><span>设备台数:</span><span class="dvcinfo-box-div-color">{{
                                        this.devtotal
                                }}</span><span>台</span></div>
                                <div class="dvcinfo-box-div"><span>订单金额:</span><span
                                        class="dvcinfo-box-div-color">¥&nbsp;{{ this.ordermoney }}</span></div>
                                <div class="dvcinfo-box-div"><span>订单数量:</span><span class="dvcinfo-box-div-color">{{
                                        this.ordernumber
                                }}</span><span>单</span></div>
                            </div>
                        </div>
                        <!--右侧按钮-->
                        <div>
                            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                            <!--<el-button ></el-button>-->
                            <!--导出按钮-点击导出数据-->
                            <el-button type="warning" plain @click="exportDatas()">导出
                            </el-button>
                        </div>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
                        :row-class-name="tableRowClassName" :data="
                            mainthreetableData.slice(
                                (currentPage - lastpage) * PageSize,
                                currentPage * PageSize
                            )
                        " style="width: 100%" min-height="900" border v-loading="loading"
                        element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange"
                        @cell-click="handleOneData" :default-sort="{ prop: 'date', order: 'descending' }"
                        @sort-change="sortchangeEvent">
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                            :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label"
                            :width="mytabletitle.width" :min-width="mytabletitle.minwidth" :sortable="
                                mytabletitle.prop == 'order_num' ||
                                    mytabletitle.prop == 'order_money' ||
                                    mytabletitle.prop == 'return_order_num' ||
                                    mytabletitle.prop == 'last_order_time'
                                    ? 'custom'
                                    : false
                            ">
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="
                                mytabletitle.prop === 'order_num' ||
                                mytabletitle.prop === 'order_money' ||
                                mytabletitle.prop === 'return_order_num' ||
                                mytabletitle.prop === 'last_order_trade_no' ||
                                mytabletitle.prop === 'is_buy' ||
                                mytabletitle.prop === 'last_order_time' ||
                                mytabletitle.prop === 'putintime'
                            ">
                                <!--订单数量-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'order_num'">
                                    <div>
                                        <span style="color: var(--el-color-success);">{{ scope.row.order_num }}</span>
                                    </div>
                                </div>
                                <!--订单金额-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'order_money'">
                                    <div>
                                        <span style="color: var(--el-color-success);">{{ scope.row.order_money }}</span>
                                    </div>
                                </div>
                                <!--退款率-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'return_order_num'">
                                    <span>{{ scope.row.return_order_num }}</span>
                                    <!-- <div
                                        v-if="scope.row.order_num && (parseInt(scope.row.order_num) > parseInt(scope.row.return_order_num ? scope.row.return_order_num : 0))">
                                        {{ parseFloat(parseInt(scope.row.return_order_num ? scope.row.return_order_num :
                                                0) / parseInt(scope.row.order_num)).toFixed(2) * 100
                                                + "%"
                                        }}
                                    </div>
                                    <div v-else>
                                        -
                                    </div> -->
                                </div>
                                <!--最近一次交易日期-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'last_order_time'">
                                    <div>
                                        {{ scope.row.last_order_time && scope.row.last_order_time != null &&
                                                scope.row.last_order_time != 0 ?
                                                this.$utils.formatDate(scope.row.last_order_time) : ''
                                        }}
                                    </div>
                                </div>
                                <!--最近一次交易订单号-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'last_order_trade_no'">
                                    <div class="mytabletext" @click="routeraddrClick('/orderlistinfo', scope.row)">
                                        <span>{{ scope.row.last_order_trade_no }}</span>
                                    </div>
                                </div>
                                <!-- 投放方式-样式等渲染 -->
                                <div v-if="mytabletitle.prop === 'is_buy'">
                                    <div>
                                        {{ scope.row.is_buy && scope.row.is_buy > 0 ?
                                                this.maintwoforminfo.selectleft.select[0].options.filter(n => n.value ==
                                                    scope.row.is_buy)[0]['label'] : ''
                                        }}
                                    </div>
                                </div>
                                <!--投放时间-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'putintime'">
                                    <div>
                                        {{ scope.row.putintime && scope.row.putintime != null && scope.row.putintime !=
                                                0 ? this.$utils.formatDate(scope.row.putintime) :
                                                ''
                                        }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
    name: "vdvcuseinfo",
    data() {
        return {
            mainonebread: [
                {
                    name: "统计",
                },
                {
                    name: "设备使用情况",
                },
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: "0", //框右侧距离
                    titlewidth: "74px", //标题宽度
                    inputwidth: "200px", //input宽度
                    inputinterval: "20px", //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: "输入搜索：",
                            placeholder: "代理商名称/手机号",
                            name: "content",
                            content: "",
                        },
                    ],
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: "none",
                    divinterval: "", //框右侧距离
                    selectwidth: "200px",
                    selectinterval: "20px", //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: "投放方式：",
                            placeholder: "全部",
                            name: "is_buy",
                            value: "",
                            options: [
                                {
                                    label: "全部",
                                    value: "0",
                                },
                                {
                                    label: "公司自投",
                                    value: "1",
                                },
                                {
                                    label: "购买",
                                    value: "2",
                                },
                                {
                                    label: "租赁",
                                    value: "3",
                                },
                            ],
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: "查询时间：",
                            placeholder: "全部",
                            name: "time_day",
                            value: "",
                            options: [
                                {
                                    label: "全部",
                                    value: "0",
                                },
                                {
                                    label: "过去一个月",
                                    value: "1",
                                },
                                {
                                    label: "过去两个月",
                                    value: "2",
                                },
                                {
                                    label: "过去三个月",
                                    value: "3",
                                },
                                {
                                    label: "过去六个月",
                                    value: "6",
                                },
                            ],
                        },
                    ],
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: "0", //框右侧距离
                    titlewidth: "74px", //标题宽度
                    inputwidth: "200px", //input宽度
                    inputinterval: "20px", //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: "设备编号：",
                            placeholder: "请输入设备编号", //此name为键值
                            name: "clientid",
                            content: "",
                            id: "", //此id为给后台的id值
                            url: "/shop/Equipment/searchClient",
                            posttype: 'get',
                            inputval: { client: "" }, //这里记录需要传的参数
                        },
                    ],
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 36, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 50, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: "left",
                    prop: "client",
                    label: "设备编号",
                    width: "100",
                },
                {
                    fixedstatu: false,
                    prop: "shop_user",
                    label: "代理商名称",
                    width: "140",
                },
                {
                    fixedstatu: false,
                    prop: "mobile",
                    label: "手机号",
                    width: "150",
                },
                {
                    fixedstatu: false,
                    prop: "order_num",
                    label: "订单数量",
                    width: "120",
                },
                {
                    fixedstatu: false,
                    prop: "order_money",
                    label: "订单金额",
                    width: "140",
                },
                {
                    fixedstatu: false,
                    prop: "return_order_num",
                    label: "退款率",
                    width: "140",
                },
                {
                    fixedstatu: false,
                    prop: "last_order_time",
                    label: "最近一次\n交易日期",
                    width: "110",
                },
                {
                    fixedstatu: false,
                    prop: "last_order_trade_no",
                    label: "最近一次\n交易订单号",
                    width: "180",
                },
                {
                    fixedstatu: false,
                    prop: "is_buy",
                    label: "投放方式",
                    width: "100",
                },
                {
                    fixedstatu: false,
                    prop: "address",
                    label: "投放位置",
                    width: "",
                    minwidth: "200"
                },
                {
                    fixedstatu: false,
                    prop: "putintime",
                    label: "投放时间",
                    width: "110",
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: "", //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem("userinfo")), //获取存储的用户信息
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
        };
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path;
    },
    methods: {
        // 排序
        sortchangeEvent(e) {
            console.log(e.prop);

            let orderval = "";
            if (e.order == "ascending") {
                orderval = 1;
            } else {
                orderval = -1;
            }

            this.pagesdatas.orderkey = e.prop;
            this.pagesdatas.orderval = orderval;
            //加载loading
            this.loading = true; //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .post("/shop/census/clientRunInfo",
                    {
                        clientid: this.pagesdatas.clientid,
                        content: this.pagesdatas.content,
                        is_buy: this.pagesdatas.is_buy,
                        time_day: this.pagesdatas.time_day,
                        orderkey: e.prop,
                        orderval: orderval,
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1);
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log("this is:" + val);
            this.multipleSelection = val;
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val;
            this.currentPage = 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post("/shop/census/clientRunInfo",
                    {
                        clientid: this.pagesdatas.clientid,
                        content: this.pagesdatas.content,
                        is_buy: this.pagesdatas.is_buy,
                        time_day: this.pagesdatas.time_day,
                        orderkey: this.pagesdatas.orderkey,
                        orderval: this.pagesdatas.orderval,
                        page: 1,
                        page_size: val,
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post("/shop/census/clientRunInfo",
                    {
                        clientid: this.pagesdatas.clientid,
                        content: this.pagesdatas.content,
                        is_buy: this.pagesdatas.is_buy,
                        time_day: this.pagesdatas.time_day,
                        orderkey: this.pagesdatas.orderkey,
                        orderval: this.pagesdatas.orderval,
                        page: val,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo);
            this.tancontent.content = taninfo.content;
            this.tancontent.title = taninfo.title;
        },

        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-订单详情
                if (routeraddr == "/orderlistinfo") {
                    this.$router
                        .push({
                            path: routeraddr, query: {
                                orderno: rowdata.last_order_trade_no,
                                platform: rowdata.thirdparty + '-' + rowdata.platform
                            }
                        })
                        .catch((error) => error);
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error);
            }
        },
        //导出数据
        exportDatas() {
            axios
                .post("/shop/census/clientRunInfo",
                    {
                        clientid: this.pagesdatas.clientid,
                        content: this.pagesdatas.content,
                        is_buy: this.pagesdatas.is_buy,
                        time_day: this.pagesdatas.time_day,
                        orderkey: this.pagesdatas.orderkey,
                        orderval: this.pagesdatas.orderval,
                        export: "1",
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                    // ElMessage({ type: "success", message: response.data.message });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post("/shop/census/clientRunInfo",
                    {
                        clientid: data.clientid,
                        content: data.content,
                        is_buy: data.is_buy,
                        time_day: data.time_day,
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {

            //加载loading
            this.loading = true;

            //通过Ajax向后台获取数据
            axios
                .post("/shop/census/clientRunInfo",
                    {
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    // 获取数据
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    components: {
        mainone,
        maintwo,
    },
});
</script>
  
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

/* 设备台数等信息 */
.dvcinfo-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    color: #333333;
    font-size: 14px;
    margin-left: 40px;
}

.dvcinfo-box-div {
    margin-right: 20px;
}

.dvcinfo-box-div-color {
    color: red;
    margin: 0 5px;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>