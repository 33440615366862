<template>
  <div style="bg_glay">
    <div>
      <mainone :mainonebread="mainonebread"></mainone>
    </div>
    <!-- 第一排的营收详情 -->
    <el-card>
      <div class="sale">
        <div class="sale_item">
          <div class="sale_hd">
            <span>订单数量</span>
            <p>本月</p>
          </div>
          <div class="sale_bd">
            <div class="bd_left">
              <span class="left_num month_order_img">{{
                this.overviewData.monthOrder
              }}</span>
              <span class="left_huanbi"
                >{{ this.orderCountRates }} 环比增长</span
              >
            </div>
            <div class="bd_right">
              <img src="@/assets/imgs/prtintoverview/icon_10.png" alt="" />
            </div>
          </div>
        </div>
        <div class="sale_item">
          <div class="sale_hd">
            <span>收入</span>
            <p>本月</p>
          </div>
          <div class="sale_bd">
            <div class="bd_left">
              <span class="left_num in_comrate_img">{{
                this.overviewData.monthInCome
              }}</span>
              <span class="left_huanbi"
                >{{ this.orderCountRates }} 环比增长</span
              >
            </div>
            <div class="bd_right">
              <img src="@/assets/imgs/prtintoverview/icon_12.png" alt="" />
            </div>
          </div>
        </div>
        <div class="sale_item">
          <div class="sale_hd">
            <span>客单价</span>
            <p>本月</p>
          </div>
          <div class="sale_bd">
            <div class="bd_left">
              <span class="left_num">{{ this.overviewData.monthUser }}</span>
            </div>
            <div class="bd_right">
              <img src="@/assets/imgs/prtintoverview/icon_14.png" alt="" />
            </div>
          </div>
        </div>
        <div class="sale_item">
          <div class="sale_hd">
            <span>营收总览</span>
            <p>本月</p>
          </div>
          <div class="sale_bd">
            <div class="bd_left">
              <span class="left_num">{{ this.overviewData.totalmoney }}</span>
            </div>
            <div class="bd_right">
              <img src="@/assets/imgs/prtintoverview/icon_16.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 订单统计 -->
    <el-card class="box-card">
      <div class="clearfix">
        <span>订单统计</span>
      </div>
      <mainwechat
        ref="linechart"
        :linegradualisshow="linegradualisshow"
        :linegradualdata="linegradual"
        @getData="orderline"
        @setDay="orderline"
        @setWeek="orderline"
        @setMonth="orderline"
        @setYear="orderline"
      >
      </mainwechat>
    </el-card>
    <!-- 在线时长图 -->
    <el-card class="box-card">
      <div class="clearfix">
        <span>在线时长图</span>
      </div>
      <mainwechat
        ref="OnlinCharts"
        :columnisshow="columnisshow"
        :columndata="column"
        @getData="getOnlinDuration"
        @setyestoday="getOnlinDuration"
        @setDay="getOnlinDuration"
        @setWeek="getOnlinDuration"
        @setMonth="getOnlinDuration"
        @setYear="getOnlinDuration"
      >
      </mainwechat>
    </el-card>
    <!-- 订单金额分布情况 -->
    <el-card class="box-card">
      <div class="clearfix">
        <span>订单金额分布情况</span>
      </div>
      <mainwechat
        ref="clientAmountDist"
        :filletcakeisshow="filletcakeisshow"
        :filletcakedata="filletcake"
        @getData="getClientAmountDist"
        @setyestoday="getClientAmountDist"
        @setDay="getClientAmountDist"
        @setWeek="getClientAmountDist"
        @setMonth="getClientAmountDist"
        @setYear="getClientAmountDist"
      >
      </mainwechat>
    </el-card>
    <!-- 打印价目表 -->
    <el-card class="box-card">
      <div class="clearfix">
        <span>打印价目表</span>
      </div>
      <div style="width: 100%; margin-top: 10px" id="price_css">
        <el-form
          ref="pricetabledata"
          :model="this.set_price"
          class="demo-ruleForm"
        >
          <el-form-item label="价格设置：">
            <div>
              <el-table
                :header-cell-style="{ background: '#F5F7FA' }"
                :data="this.set_price.formdata"
                style="width: 100%"
                border
              >
                <el-table-column label="打印介质" fixed="left">
                  <template #default="scope">
                    {{ scope.row }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="price_range"
                  label="价格区间"
                  width="320px"
                >
                  <template #default="scope">
                    最低
                    <el-input
                      v-model="scope.row"
                      placeholder=""
                      style="width: 100px"
                    ></el-input>
                    ~
                    <el-input
                      v-model="scope.row"
                      placeholder=""
                      style="width: 100px"
                    ></el-input>
                    最高
                  </template>
                </el-table-column>
                <el-table-column
                  v-for="(mytabletitle, index) in this.set_price.titledata"
                  :key="index"
                  :prop="mytabletitle.prop"
                  :label="mytabletitle.label"
                  :width="mytabletitle.width"
                >
                  <template #default="scope">
                    <div
                      v-for="(childrendata, ide) in scope.row.children"
                      :key="ide"
                    >
                      <div v-if="mytabletitle.prop === 'page_range'">
                        <div style="margin: 5px 0">
                          >=
                          <el-input
                            style="width: 100px"
                            v-model="childrendata.page_range"
                            placeholder=""
                          ></el-input>
                          页
                        </div>
                      </div>
                      <div v-if="mytabletitle.prop === 'bw_price_along'">
                        <div style="margin: 5px 0">
                          <el-input
                            v-model="childrendata.bw_price_along"
                            placeholder=""
                          ></el-input>
                        </div>
                      </div>
                      <div v-if="mytabletitle.prop === 'bw_price_double'">
                        <div style="margin: 5px 0">
                          <el-input
                            v-model="childrendata.bw_price_double"
                            placeholder=""
                          ></el-input>
                        </div>
                      </div>
                      <div v-if="mytabletitle.prop === 'colour_price_along'">
                        <div style="margin: 5px 0">
                          <el-input
                            v-model="childrendata.colour_price_along"
                            placeholder=""
                          ></el-input>
                        </div>
                      </div>
                      <div v-if="mytabletitle.prop === 'colour_price_double'">
                        <div style="margin: 5px 0">
                          <el-input
                            v-model="childrendata.colour_price_double"
                            placeholder=""
                          ></el-input>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainwechat from "./components/Mainecharts.vue";
import * as echarts from "echarts";
import axios from "axios";
import { defineComponent } from "vue";
import dayjs from "dayjs";
export default defineComponent({
  name: "vaccount",

  data() {
    return {
      overviewData: {},
      // 打印价目表
      set_price: {
        titledata: [
          {
            prop: "page_range",
            label: "纸张范围",
            width: "180px",
          },
          {
            prop: "bw_price_along",
            label: "黑白价格-单面/页",
            width: "180px",
          },
          {
            prop: "bw_price_double",
            label: "黑白价格-双面/页",
            width: "180px",
          },
          {
            prop: "colour_price_along",
            label: "彩色价格-单面/页",
            width: "180px",
          },
          {
            prop: "colour_price_double",
            label: "彩色价格-双面/页",
            width: "180px",
          },
        ],
        formdata: [],
      },

      dataArray: [],
      mytoken: localStorage.getItem("token"), //获取存储的token
      mainonebread: [
        {
          name: "统计",
        },
        {
          name: "设备订单排行",
        },
        {
          name: "设备概览",
          // path: "/account",
        },
      ], //
      paytimeStr: "",
      account: {
        total_in: 0,
        print_in: 0,
        cash_draw: 0,
        course_in: 0,
        advert_in: 0,
        plus_in: 0,
        shop_in: 0,
        shop_out: 0,
        platform: "1",
        paytype: "1",
        paytime: "",
        month: [],
      },
      queryparam: {
        platform: "1",
        paytype: "1",
        paytime: "",
        platform2: "1",
      },
      platforms: [
        {
          value: "1",
          label: "微信自助版",
        },
        {
          value: "2",
          label: "支付宝自助版",
        },
        {
          value: "3",
          label: "微信企业版",
        },
        {
          value: "4",
          label: "微信家庭版",
        },
      ],
      paytypes: [
        { value: "", label: "不限支付方式" },
        {
          value: "1",
          label: "微信",
        },
        {
          value: "2",
          label: "余额",
        },
        {
          value: "3",
          label: "支付宝",
        },
        {
          value: "5",
          label: "余额代付",
        },
      ],

      linegradualisshow: true, //折现图-渐变堆叠
      linegradual: [
        {
          idname: "linegradual",
          width: "100%",
          height: "400px",
          text: "", //折线图-标题
          maincolor: ["#80FFA5", "#00DDFF"], //定义主色调，且有几个模块，定义几个，[数组string类型]
          legenddata: ["total", "count"], //定义模块，[数组strin
          subtextx: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], //图表X轴-标题
          gridleft: "10%", //图表距离左侧距离
          gridright: "10%", //图表距离右侧距离
          gridbottom: "10%", //图表距离底部距离
          data: [
            {
              name: "total", //与上方模块定义的名对应
              type: "line",
              stack: "total",
              smooth: true,
              lineStyle: {
                width: 0,
              },

              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(128, 255, 165)", //渐变的颜色1
                  },
                  {
                    offset: 1,
                    color: "rgba(1, 191, 236)", //渐变的颜色2
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: [140, 232, 101, 264, 90, 340, 250], //对应图表X轴上的数据
            },
            {
              name: "count",
              type: "line",
              stack: "count",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(0, 221, 255)",
                  },
                  {
                    offset: 1,
                    color: "rgba(77, 119, 255)",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: [120, 282, 111, 234, 220, 340, 310],
            },
          ],
        },
      ],

      columnisshow: true, //柱状图
      column: [
        {
          idname: "column",
          height: "400px",
          text: "", //柱状图表-标题
          subtext: "在线时长（分钟）", //柱状图表-Y轴名
          subtextx: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ], //柱状图表-X轴名[数组string类型]
          subdata: [
            9.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
          ], //柱状图表-data数据[数组int类型]
          subcolor: "#002EB7",
          content: {},
        },
      ],
      filletcakeisshow: true, //饼状图-圆角
      filletcake: [
        {
          idname: "filletcake",
          height: "400px",

          data: [
            { value: 1048, name: "Search Engine" },
            { value: 735, name: "Direct" },
            { value: 580, name: "Email" },
            { value: 484, name: "Union Ads" },
            { value: 300, name: "Video Ads" },
          ],
        },
      ],
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 2, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 320,
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "client",
          label: "打印类型",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "client",
          label: "订单金额（扣除退款)",
          width: "240",
        },
        {
          fixedstatu: false,
          prop: "name",
          label: "订单数量",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "address",
          label: "打印张数",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "putintime",
          label: "支出单数",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "分佣金额",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      // loading: false, //初始化-loading加载动效，默认是false关闭
      sreenheight: "",
      sreenwidth: "",
      result: {},
      search_time: "",
      ordertime: "",
    };
  },
  computed: {
    // 订单数量环比增长
    orderCountRates() {
      if (this.overviewData.lastMonthOrder == 0) {
        return 0;
      } else {
        return (
          ((this.overviewData.monthOrder - this.overviewData.lastMonthOrder) /
            this.overviewData.lastMonthOrder) *
          100
        ).toFixed(0);
      }
    },
    //本月收入增长环比
    inComMonthRates() {
      if (this.overviewData.lastMonthInCome == 0) {
        return 0;
      } else {
        return (
          ((this.overviewData.monthInCome - this.overviewData.lastMonthInCome) /
            this.overviewData.lastMonthInCome) *
          100
        ).toFixed(0);
      }
    },
  },
  methods: {
    // 第一排的本月设备信息概览
    getoverview() {
      console.log("----paytime");
      console.log(this.paytimeStr);
      if (this.paytimeStr != "") {
        this.queryparam.paytime = this.getdefaultmonth(
          new Date(this.paytimeStr)
        );
      } else {
        this.queryparam.paytime = this.getdefaultmonth(new Date());
      }
      console.log(this.queryparam.paytime);

      axios
        .get("/shop/Statistics/clientInfo", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {
            clientid: this.$route.query.clientid,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            this.overviewData = response.data.result;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getdefaultday(now) {
      var nowYear = now.getFullYear(); //当前年
      var nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
      var nowday = now.getDate();
      var firstDay = new Date(nowYear, nowMonth, nowday).getTime() / 1000; // 本月开始时间
      var LastDay = new Date(nowYear, nowMonth, nowday + 1).getTime() / 1000; // 本月结束时间
      console.log("---current day:---");
      console.log(firstDay);
      console.log(LastDay);
      return firstDay.toString() + " ~ " + LastDay.toString();
    },
    getdefaultmonth(now) {
      var nowYear = now.getFullYear(); //当前年
      var nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
      var firstDay = new Date(nowYear, nowMonth, 1).getTime() / 1000; // 本月开始时间
      var LastDay = new Date(nowYear, nowMonth + 1, 0).getTime() / 1000; // 本月结束时间
      console.log("---current month:---");
      console.log(firstDay);
      console.log(LastDay);
      return firstDay.toString() + " ~ " + LastDay.toString();
    },
    // 绑定各个图示的数据
    bindline(val) {
      if (val === "onlinetime") {
        this.column[0].subtextx = this.orderresult.xdate;
        this.column[0].subdata = this.orderresult.ydate;

        this.$refs.OnlinCharts.echartInit(this.column, 0);
      } else if (val === "getClientAmountDist") {
        const datamap = [];
        this.orderresult.map((val) => {
          datamap.push({ value: val.count, name: val.name });
        });
        this.filletcake[0].data = datamap;
        this.$refs.clientAmountDist.echartFillet(this.filletcake, 0);
      } else if (val === "printLine") {
        this.linegradual[0].subtextx = this.orderresult.show;
        this.linegradual[0].data[0].data = this.orderresult.total;
        this.linegradual[0].data[1].data = this.orderresult.count;
        this.$refs.linechart.echartGradual(this.linegradual, 0);
      }
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth * 0.8;
    },
    // 订单统计
    orderline(data) {
      if (this.ordertime == "") {
        this.ordertime = this.getdefaultday(new Date());
      } else {
        this.ordertime = data.currentDay;
      }

      axios
        .post(
          "/shop/census/printLine",
          {
            clientid: this.$route.query.client,
            search_time: this.ordertime,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            this.orderresult = response.data.result;

            this.bindline("printLine");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //设备在线时长
    getOnlinDuration(e) {
      console.log(e);
      let onlinetime = e && e.currentDay ? e.currentDay : "";
      if (onlinetime == "") {
        onlinetime = dayjs().format("YYYY-MM-DD");
      } else {
        onlinetime = e.currentDay;
      }

      axios
        .get("/shop/Equipment/onlinDuration", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {
            id: this.$route.query.clientid,
            timetype: e && e.timeType ? e.timeType : "1",
            time: onlinetime,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            this.orderresult = response.data.result;
            this.bindline("onlinetime");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示设备状态按钮，且devstatus为1表示使用&在线，2表示空闲&在线，3表示空闲&离线
        if (newdata[i].printstatus === 1 && newdata[i].is_online === 1) {
          newdata[i].devstatus = 1;
        } else {
          if (newdata[i].printstatus === 0 && newdata[i].is_online === 1) {
            newdata[i].devstatus = 2;
          } else {
            if (newdata[i].printstatus === 0 && newdata[i].is_online === -1) {
              newdata[i].devstatus = 3;
            } else {
              newdata[i].devstatus = 0;
            }
          }
        }

        //转换投放时间
        if (newdata[i].putintime) {
          if (newdata[i].putintime === 0 || newdata[i].putintime === null) {
            newdata[i].putintime = "无添加时间";
          } else {
            newdata[i].putintime = this.$utils.formatDate(newdata[i].putintime);
          }
        } else {
          newdata[i].putintime = "无添加时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
    // 获取设备订单金额分布情况
    getClientAmountDist(data) {
      const orderSpreadTime = data
        ? data.currentDay
        : this.getdefaultday(new Date());

      axios
        .get("/shop/Statistics/clientAmountDist", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {
            clientid: this.$route.query.clientid,
            search_time: orderSpreadTime,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            this.orderresult = response.data.result;
            this.bindline("getClientAmountDist");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 获取设备价目表
    getClientPriceList() {
      axios
        .get("/shop/Statistics/clientPriceList", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {
            // clientid:'5370',
            clientid: this.$route.query.clientid,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            // this.set_price.formdata=response.data.result
            // console.log(JSON.parse(JSON.stringify(this.set_price.formdata)));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  created() {
    this.getoverview();
    this.orderline(); //订单统计
    this.getOnlinDuration(); //设备在线时长
    this.getClientAmountDist(); //设备订单金额分布情况
    this.getClientPriceList(); //设备订单金额分布情况
  },
  mounted() {
    console.log(this.$route.query);
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      // this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据

      // this.getentryBill();
    }
  },
  components: {
    mainone,
    mainwechat,
  },
});
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.sale {
  display: flex;
  height: 180px;
  justify-content: space-between;
}
.sale_item {
  border: 1px red solid;
  width: 360px;
  padding: 30px;
  background: #002eb7;
  opacity: 1;
  border-radius: 18px;
}
.sale_hd {
  height: 25px;
}
.sale_hd span {
  float: left;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.sale_hd p {
  float: right;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 24px;
  color: #94a3d2;
  opacity: 1;
}
.sale_bd {
  position: relative;
  height: 108px;
}
.bd_left {
  float: left;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.left_num {
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}
.left_huanbi {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
}
.left_num::after {
  content: "";
  position: absolute;
  width: 19px;
  height: 19px;
  background: url("../../../assets/imgs/prtintoverview/icon_up.png") no-repeat;
}

.bd_right {
  float: right;
  height: 100%;
  position: absolute;
  right: -12px;
  bottom: -23px;
}

:deep(.btn) {
  margin: 0 15px;
}
:deep(.btn_group) {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 195px;
}
.el-card {
  margin-bottom: 12px;
}
:deep(.first .second) {
  padding-top: 56px;
}
</style>
