<template>
  <!--本页流程使用说明：-->
  <!--1、在template中添加以子页面命名的过度组件，eg、usertest文件，引用组件的文件名也为usertest,便于后期维护！！！-->
  <!--2、在setup中，添加组件判断-->
  <!--3、对应的getData.myisshow为该组件的显示条件-->
  <!--4、getspecialinfo为子页面传回的数据，并通过getSpecialinfoEvent函数接收！-->
  <div>
    <voverview v-if="getData.myisshow === 202" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></voverview> 
    <vmemberdvcbuy v-if="getData.myisshow === 102" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberdvcbuy>
    <vcoupon v-if="getData.myisshow === 161" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoupon>
    <vcouponadd v-if="getData.myisshow === 52" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcouponadd>
    <vcouponinfo v-if="getData.myisshow === 53" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcouponinfo>
    <vcoupondata v-if="getData.myisshow === 54" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoupondata>
    <vcouponedit v-if="getData.myisshow === 55" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcouponedit>
    <vaccountset v-if="getData.myisshow === 176" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vaccountset>
    <vexportlist v-if="getData.myisshow === 213" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vexportlist>  
    <vdvclist v-if="getData.myisshow === 107" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvclist>
    <vsharedvcmap v-if="getData.myisshow === 113" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcmap>
    <vorderlist v-if="getData.myisshow === 127" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vorderlist>
    <orderlistinfo v-if="getData.myisshow === 7" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></orderlistinfo>
    <vrefundapplication v-if="getData.myisshow === 128" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrefundapplication>
    <refundinfo v-if="getData.myisshow === 8" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></refundinfo>
    <refwechatinfo v-if="getData.myisshow === 9" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></refwechatinfo>
    <vagentlist v-if="getData.myisshow === 19" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentlist>
    <vagentlistadd v-if="getData.myisshow === 17" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vagentlistadd>
    <vagentlistedit v-if="getData.myisshow === 175" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentlistedit>
    <vagentlistinfo v-if="getData.myisshow === 188" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentlistinfo>
    <vwithdrawalrecord v-if="getData.myisshow === 145" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vwithdrawalrecord>
    <vagentcensus v-if="getData.myisshow === 20" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentcensus>
    <vdvcorderranking v-if="getData.myisshow === 168" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcorderranking>
    <vdvcisonline v-if="getData.myisshow === 500" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcisonline>
    <vdvcedit v-if="getData.myisshow === 501" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcedit>
    <vaccount v-if="getData.myisshow === 502" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vaccount>
    <vhandwithdrawal v-if="getData.myisshow === 503" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vhandwithdrawal>
    <vchfn v-if="getData.myisshow === 504" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vchfn>
    <vstatementdetails v-if="getData.myisshow === 505" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vstatementdetails>
    <vtradedetailed v-if="getData.myisshow === 506" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vtradedetailed>
    <vdvcfrp v-if="getData.myisshow === 507" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcfrp>
    <vdvcprintcommission v-if="getData.myisshow === 508" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcprintcommission>
    <!-- 新页面从400开始 -->
    <vreferralcode v-if="getData.myisshow === 400" :mainpagedata="mainpagedata"  v-on:getspecialinfo="getSpecialinfoEvent"></vreferralcode>
    <vjunioragent v-if="getData.myisshow === 401" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vjunioragent>
    <vsetcount v-if="getData.myisshow === 402" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsetcount>
    <vagentordermony v-if="getData.myisshow === 403" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentordermony>
    <vagentordercount v-if="getData.myisshow === 404" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentordercount>
    <vdvcoverprint v-if="getData.myisshow === 405" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcoverprint>
    <vdvcuseinfo v-if="getData.myisshow === 406" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcuseinfo>
    <vdvclfamilyedit v-if="getData.myisshow === 407" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvclfamilyedit>
    <vdvcfanscount v-if="getData.myisshow === 408" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcfanscount>
    <!-- 打印新粉统计 -->
    <vnewFansCount v-if="getData.myisshow === 409" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vnewFansCount>
    <!-- 开锁记录 -->
    <vdeviceOpenLockLog v-if="getData.myisshow === 410" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdeviceOpenLockLog>

  </div>
</template>

<script>
import { reactive } from "vue";
import voverview from "./index/printoverview/voverview"; //首页-打印概览统计
import vmemberdvcbuy from "./index/memberdvcbuy/vmemberdvcbuy"; // 首页-设备会员
import vcoupon from "./index/coupon/vcoupon"; // 首页-优惠券
import vreferralcode from "./index/referralcode/vreferralcode"; // 首页-推荐码
import vcouponadd from "./index/coupon/vcouponadd"; // 首页-优惠券-添加
import vcouponinfo from "./index/coupon/vcouponinfo"; // 首页-优惠券-信息
import vcoupondata from "./index/coupon/vcoupondata"; // 首页-优惠券-data
import vcouponedit from "./index/coupon/vcouponedit"; // 首页-优惠券-编辑
import vaccountset from "./index/accountset/vaccountset"; // 首页-账户设置
import vexportlist from "./index/exportlist/vexportlist"; // 首页-导出列表
import vdvclist from "./device/dvclist/vdvclist"; // 设备-设备列表
import vdvcprintcommission from "./device/dvcprintcommission/vdvcprintcommission"; // 设备-设备列表
import vdvcfrp from "./device/dvclist/vdvcfrp"; // 设备-设备列表
import vsharedvcmap from "./device/sharedvcmap/vsharedvcmap"; // 设备-设备地图
import vorderlist from "./order/orderlist/vorderlist"; // 订单-订单列表
import orderlistinfo from "./order/orderlist/orderinfo"; // 订单-订单列表-详情
import vrefundapplication from "./order/refundapplication/vrefundapplication"; // 订单-退款申请
import refundinfo from "./order/refundapplication/refundinfo"; // 订单-退款申请-详情
import refwechatinfo from "./order/refundapplication/refwechatinfo"; // 订单-退款申请-微信
import vagentlist from  "./agent/agentlist/vagentlist" // 代理商-代理商列表
import vagentlistadd from  "./agent/agentlist/vagentlistadd"; // 代理商-代理商列表-添加
import vagentlistedit from './agent/agentlist/vagentlistedit' // 代理商-代理商列表-编辑
import vagentlistinfo from './agent/agentlist/vagentlistinfo' // 代理商-代理商列表-详情
import vwithdrawalrecord from './finance/withdrawal/vwithdrawalrecord' // 财务-提现记录
import vagentcensus from "./census/agentcensus/vagentcensus" //统计-代理商统计
import vdvcisonline from "./device/dvclist/vdvcisonline" // 设备-设备列表-在线时长列表
import vaccount from "./finance/account/vaccount" // 设备-设备列表-在线时长列表
import vdvcedit from "./device/dvclist/vdvcedit" // 设备-设备列表-在线时长列表
import vhandwithdrawal from "./finance/account/vhandwithdrawal" // 财务-财务总览-手动提现
import vchfn from "./finance/account/vchfn" // 财务-财务总览-修改账户信息
import vtradedetailed from "./finance/account/vtradedetailed" // 财务-财务总览-交易记录
import vstatementdetails from "./finance/statementdetails/vstatementdetails" // 财务-财务总览-修改账户信息
import vdvcorderranking from './census/dvcorderranking/vdvcorderranking' // 统计-设备订单排行
import vjunioragent from './census/agentcensus/vjunioragent.vue' // 统计-设备订单排行
import vsetcount from './census/agentcensus/vsetcount.vue' // 统计-设备订单排行
import vagentordermony from './census/agentcensus/vagentordermony.vue' // 统计-设备订单排行
import vagentordercount from './census/agentcensus/vagentordercount.vue' // 统计-设备订单排行
import vdvcoverprint from './census/dvcorderranking/vdvcoverprint.vue' // 统计-设备订单排行
import vdvcuseinfo from './census/dvcuseinfo/vdvcuseinfo' // 统计-设备使用情况
import vdvclfamilyedit from './device/dvclist/vdvclfamilyedit' // 统计-设备使用情况
import vdvcfanscount from './census/wecom/dvcfanscount/vdvcfanscount' // 统计-用户加企微统计
import vnewFansCount from './census/newFansCount/vnewFansCount' // 统计-打印新粉统计
import vdeviceOpenLockLog from './device/openLock/vdeviceOpenLockLog' // 设备-开锁记录


export default {
  name: "special",
  props: ["mainpagedata"],
  setup(props) {
    const mainPagedata = reactive(props["mainpagedata"]);

    //定义数据数组等
    let getData = reactive({
      myisshow: 0,
    });
    //判断mycontrol是否定义
    if (mainPagedata.showpages.mycontrol) {
      //将mainPagedata.showpages.mycontrol的值赋予getData.myisshow控制页面走向
      getData.myisshow = mainPagedata.showpages.mycontrol;
    }

    return {
      mainPagedata,
      getData,
    };
  },
  methods: {
    getSpecialinfoEvent(data) {
      //获取XX特殊页面返回的数据
      console.log("获取XX特殊页面返回的数据:" + JSON.stringify(data));
      //将页数传向父组件
      this.$emit("getspecialinfo", data);
    },
    searchFormEvent(data) {
      //获取Maintwo-高级筛选-开始检索返回的数据
      console.log(
        "高级筛选-开始检索返回数据(pagedata):" + JSON.stringify(data)
      );
      //将数据传向父组件
      this.$emit("searchForm", data);
    },
    getSeachinfoEvent(data) {
      //获取Maintwo-搜索-返回的数据
      console.log("搜索-返回的数据:" + JSON.stringify(data));
      //将数据传向父组件
      this.$emit("getsearchinfo", data);
    },
    getPageinfoEvent(data) {
      //获取Mainthree-数据列表-分页选择的数据
      console.log("页面-分页选择的数据:" + JSON.stringify(data));
      //将页数传向父组件
      this.$emit("getpageinfo", data);
    },
    searchFormtanEvent(data) {
      //获取弹框-返回的数据
      console.log(
        "special-获取单框提交的参数与数据等：" + JSON.stringify(data)
      );
      //将数据传向父组件
      this.$emit("searchFormtan", data);
    },
  },
  components: {
    vdvcoverprint,
    vagentordercount,
    vagentordermony,
    vsetcount,
    vjunioragent,
    vreferralcode,
    voverview,
    vmemberdvcbuy,
    vcoupon,
    vcouponadd,
    vcouponinfo,
    vcoupondata,
    vcouponedit,
    vaccountset,
    vexportlist,
    vdvclist,
    vdvcprintcommission,
    vdvcfrp,
    vsharedvcmap,
    vorderlist,
    orderlistinfo,
    vrefundapplication,
    refundinfo,
    refwechatinfo,
    vagentlist,
    vagentlistadd,
    vagentlistedit,
    vagentlistinfo,
    vwithdrawalrecord,
    vaccount,
    vstatementdetails,
    vhandwithdrawal,
    vchfn,
    vtradedetailed,
    vagentcensus,
    vdvcisonline,
    vdvcedit,
    vdvcorderranking,
    vdvcuseinfo,
    vdvclfamilyedit,
    vdvcfanscount,
    vnewFansCount,
    vdeviceOpenLockLog,
  },
};
</script>
