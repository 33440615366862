<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo
      :maintwoiconshow="maintwoiconshow"
      :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow"
      :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow"
      :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow"
      :myinput="myinput"
      :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull"
      :myselectisshow="myselectisshow"
      :myselect="myselect"
      :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity"
      :myselectcityleftisshow="maintwoforminfo.myselectcityleftisshow"
      :myselectcityleft="maintwoforminfo.myselectcityleft"
      v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"
    ></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div
        style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        "
      >
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表ordercount</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button
              type="warning"
              plain
              @click="exportDatas(mainthreetableData)"
              >导出</el-button
            >
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table
            :header-cell-style="{ background: '#F5F7FA' }"
            ref="multipleTable"
            :row-class-name="tableRowClassName"
            :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            "
            style="width: 100%"
            min-height="900"
            border
            v-loading="loading"
            element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange"
            @cell-click="handleOneData"
          >
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column
              type="selection"
              width="55"
              v-if="mainthreeisshowset"
            />
            <el-table-column
              v-for="(mytabletitle, i) in mainthreetabletitle"
              :key="i"
              :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop"
              :label="mytabletitle.label"
              :width="mytabletitle.width"
              :sortable="mytabletitle.isSortable"
            >
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template
                #default="scope"
                v-if="
                  mytabletitle.prop === 'status' ||
                  mytabletitle.prop === 'images' ||
                  mytabletitle.prop === 'id' ||
                  mytabletitle.prop === 'two_num' ||
                  mytabletitle.prop === 'client_num' ||
                  mytabletitle.prop === 'order_num' ||
                  mytabletitle.prop === 'level' ||
                  mytabletitle.prop === 'order_count'
                "
              >
                <div v-if="mytabletitle.prop === 'level'">
                  <div
                    v-if="scope.row.level > 0"
                    style="cursor: pointer"
                    @click="routeraddrClick('/junioragent', scope.row)"
                  >
                    <span style="color: #465eff">{{ scope.row.level }}</span>
                  </div>
                  <div v-if="scope.row.level == 0">
                    <span>{{ scope.row.level }}</span>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'images'">
                  <div v-if="scope.row.images">
                    <el-image
                      class="imagesshow"
                      :src="scope.row.images"
                      :preview-src-list="[scope.row.images]"
                      fit="cover"
                    ></el-image>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch
                      v-model="scope.row.status"
                      active-text="开启"
                      inactive-text="关闭"
                      :active-value="1"
                      :inactive-value="-1"
                      active-color="#13ce66"
                      inactive-color="#cbcbcb"
                    >
                    </el-switch>
                  </div>
                  <div v-if="scope.row.textstatus">
                    <span>{{ scope.row.textstatus }}</span>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'id'">
                  <div
                    v-if="scope.row.id"
                    style="cursor: pointer"
                    @click="routeraddrClick('/agentordermont', scope.row)"
                  >
                    <span style="color: #465eff">{{ scope.row.id }}</span>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'two_num'">
                  <div
                    v-if="scope.row.two_num > 0"
                    style="cursor: pointer"
                    @click="routeraddrClick('/agentcensustwo', scope.row)"
                  >
                    <span style="color: #465eff">{{ scope.row.two_num }}</span>
                  </div>
                  <div v-if="scope.row.two_num == 0">
                    <span>{{ scope.row.two_num }}</span>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'client_num'">
                  <div
                    v-if="scope.row.client_num > 0"
                    style="cursor: pointer"
                    @click="routeraddrClick('/userlist', scope.row)"
                  >
                    <span style="color: #465eff">{{
                      scope.row.client_num
                    }}</span>
                  </div>
                  <div v-if="scope.row.client_num == 0">
                    <span>{{ scope.row.client_num }}</span>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'order_num'">
                  <div
                    v-if="scope.row.order_num > 0"
                    style="cursor: pointer"
                    @click="routeraddrClick('/setcount', scope.row)"
                  >
                    <span style="color: #465eff">{{
                      scope.row.order_num
                    }}</span>
                  </div>
                  <div v-if="scope.row.order_num == 0">
                    <span>{{ scope.row.order_num }}</span>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'order_count'">
                  <div
                    v-if="scope.row.order_count > 0"
                    style="cursor: pointer"
                    @click="routeraddrClick('/userlist', scope.row)"
                  >
                    <span style="color: #465eff">{{
                      scope.row.order_count
                    }}</span>
                  </div>
                  <div v-if="scope.row.order_count == 0">
                    <span>{{ scope.row.order_count }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column
              fixed="right"
              label="操作"
              :width="mainthreehandle"
              v-if="mainthreeisshowope"
              prop="myoperation"
              id="operation"
            >
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-代理商列表-->
                <div v-if="mainthreetableope === 1">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="routeraddrClick('/agentdvclist')"
                    >设备
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="routeraddrClick('/agentlistedit')"
                  >
                    编辑
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    v-if="scope.row.opeidupgrade === 1"
                  >
                    升级为{{ scope.row.opeidupgrade }}代
                  </el-button>
                  <el-button type="primary" size="mini">登录</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="pageSizes"
              :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog
        v-model="dialogVisible"
        :title="tancontent.title"
        :width="this.searchwidth"
        :before-close="handleCloseSenior"
      >
        <el-form ref="seniorform" :model="seniorform">
          <div
            style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              padding-left: 5%;
              margin: 20px 0;
            "
          >
            <el-form-item>
              <!--以下是左侧字-->
              <maininput
                :myinputoneisshow="tancontent.content.myinputoneisshow"
                :myinputone="tancontent.content.myinputone"
              ></maininput>
              <!--以下是上方字-->
              <maininput
                :myinputishow="tancontent.content.myinputishow"
                :myinput="tancontent.content.myinput"
              ></maininput>
              <mainselect
                :myselectisshow="tancontent.content.myselectisshow"
                :myselect="tancontent.content.myselect"
              ></mainselect>
              <mainselect
                :myselectcityisshow="tancontent.content.myselectcityisshow"
                :myselectcity="tancontent.content.myselectcity"
              ></mainselect>
            </el-form-item>
          </div>
          <el-form-item>
            <div style="text-align: center" class="main_one_button">
              <el-button
                @click="
                  resetFormSenior(
                    tancontent.content.myinputone,
                    tancontent.content.myinput,
                    tancontent.content.myselect,
                    tancontent.content.myselectcity
                  )
                "
              >
                <i class="el-icon-refresh-left"></i> 重置
              </el-button>
              <el-button
                type="primary"
                @click="
                  onSubmitSenior(
                    tancontent.content.myinputone,
                    tancontent.content.myinput,
                    tancontent.content.myselect,
                    tancontent.content.myselectcity
                  )
                "
                class="main_two_search"
                >确定
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import maininput from "@/components/Maininput";
import mainselect from "@/components/Mainselect";
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vagentcensus",
  data() {
    return {
      mainonebread: [
        {
          name: "统计",
        },
        {
          name: "代理商统计排行",
          path: "/agentcensus",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "代理商名称/手机号",
              name: "keywords",
              content: "",
            },
          ],
        },
        myselectcityleftisshow: true, //筛选查询-selectcity选框
        myselectcityleft: {
          selectwidth: "100%",
          select: {
            ismust: false,
            title: "选择区域：",
            name: "area_code",
            valuesheng: "",
            valueshi: "",
            valuequ: "",
            disshi: true,
            disqu: true,
          },
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "name",
          label: "订单编号",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "username",
          label: "设备编号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "level",
          label: "店铺名",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "order_num",
          label: "实付分佣总额",
          width: "",
          isSortable: true,
        },
        {
          fixedstatu: false,
          prop: "id",
          label: "分佣金额",
          width: "",
          isSortable: true,
        },
        {
          fixedstatu: false,
          prop: "order_count",
          label: "用户手机号",
          width: "",
          isSortable: true,
        },
        {
          fixedstatu: false,
          prop: "area_code_name",
          label: "打印介质",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status_name",
          label: "打印类型",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "支付方式",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "订单状态",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "打印状态",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "付款时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "代理商名称/手机号",
            name: "keywords",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "状态:",
            placeholder: "全部",
            name: "status",
            value: "",
            options: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "启用",
              },
              {
                value: "-1",
                label: "禁止",
              },
            ],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "添加时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselectcityisshow: true, //高级筛选-selectcity选框
      myselectcity: {
        selectwidth: "100%",
        select: {
          ismust: false,
          name: "area_code",
          valuesheng: "",
          valueshi: "",
          valuequ: "",
          disshi: true,
          disqu: true,
        },
      },
      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  setup() {
    const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done();
    };

    return {
      dialogVisible,
      handleCloseSenior,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      console.log(row); //获取当前行数据
      console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用

      //获取所点击按钮的id值-并根据不同的id值进行跳转&传值等
      if (event.path[1].id) {
        //由操作-合作-公司列表-向-公司关联设备页面传值
        if (event.path[1].id === "companydvcid") {
          this.$router
            .push({
              path: "/companydvclist",
              query: {
                id: row.id,
                name: row.name,
              },
            })
            .catch((error) => error);
        }
        //由操作-合作-设备合伙人列表-向-合伙人关联设备页面传值
        if (event.path[1].id === "partnerdvcid") {
          this.$router
            .push({
              path: "/partnerdvclist",
              query: {
                id: row.id,
              },
            })
            .catch((error) => error);
        }
      }

      console.log("当前的column.property 是：" + column.property);
      if (column.property === "secondagent") {
        //跳转二级代理
        this.$router.push("/agentcensustwo").catch((error) => error);
      }
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/shopOrder", {
          headers: {
            Authentication: this.mytoken,
          },
          params: {
            page: 1,
            page_size: val,
            export: 0,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;
      //判断-添加时间段状态
      var getaddtime = null;
      if (this.pagesdatas.addtime.start == "") {
        getaddtime = "";
      } else {
        if (this.pagesdatas.addtime.end == "") {
          getaddtime =
            this.pagesdatas.addtime.start +
            " ~ " +
            this.pagesdatas.addtime.start;
        } else {
          getaddtime =
            this.pagesdatas.addtime.start + " ~ " + this.pagesdatas.addtime.end;
        }
      }
      //判断-支付时间段状态
      var getpaytime = null;
      if (this.pagesdatas.paytime.start == "") {
        getpaytime = "";
      } else {
        if (this.pagesdatas.paytime.end == "") {
          getpaytime =
            this.pagesdatas.paytime.start +
            " ~ " +
            this.pagesdatas.paytime.start;
        } else {
          getpaytime =
            this.pagesdatas.paytime.start + " ~ " + this.pagesdatas.paytime.end;
        }
      }
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/shopOrder", {
          headers: {
            Authentication: this.mytoken,
          },
          params: {
            keyword: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            area_code: this.pagesdatas.area_code,
            addtime: getaddtime,
            paytime: getpaytime,
            page: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch((error) => error);
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //弹出框-提交表单
    onSubmitSenior(myinputone, myinputform, myselectform, myselectcityform) {
      this.dialogVisible = false; //隐藏高级筛选模块
      //获取输入框字段与字段名并合成json数据
      var seniorformlist = {};

      if (myinputone) {
        for (var m = 0; m < myinputone.input.length; m++) {
          seniorformlist[myinputone.input[m].name] =
            myinputone.input[m].content;
        }
      }
      if (myinputform) {
        for (var i = 0; i < myinputform.input.length; i++) {
          seniorformlist[myinputform.input[i].name] =
            myinputform.input[i].content;
        }
      }
      if (myselectform) {
        for (var j = 0; j < myselectform.select.length; j++) {
          seniorformlist[myselectform.select[j].name] =
            myselectform.select[j].value;
          if (
            myselectform.select[j].selecttimeduanisshow === true &&
            myselectform.select[j].value.valuestart !== ""
          ) {
            if (myselectform.select[j].value.valueend === "") {
              seniorformlist[myselectform.select[j].name] = "";
              ElMessage({
                message: "错误：结束时间不能为空",
                type: "error",
                offset: 70,
              });
            }
          }
        }
      }
      if (myselectcityform) {
        // !myselectcityform【!+参数 在if条件中表示检测该参数未定义】
        if (myselectcityform.select.valuequ !== "") {
          if (
            myselectcityform.select.valuequ === "0" ||
            myselectcityform.select.valuequ === "暂无相关县/区"
          ) {
            //这里的判断根据Maintwo中的valuequ值而判断
            seniorformlist[myselectcityform.select.name] =
              myselectcityform.select.valueshi;
          } else {
            seniorformlist[myselectcityform.select.name] =
              myselectcityform.select.valuequ;
          }
        } else {
          if (myselectcityform.select.valueshi !== "") {
            if (
              myselectcityform.select.valueshi === "0" ||
              myselectcityform.select.valueshi === "暂无相关市"
            ) {
              seniorformlist[myselectcityform.select.name] =
                myselectcityform.select.valuesheng;
            } else {
              seniorformlist[myselectcityform.select.name] =
                myselectcityform.select.valueshi;
            }
          } else {
            seniorformlist[myselectcityform.select.name] =
              myselectcityform.select.valuesheng;
          }
        }
      }
      //将数据传向父组件
      this.$emit("searchFormtan", seniorformlist);
      console.log(JSON.stringify(seniorformlist));
    },
    //弹出框-重置
    resetFormSenior(myinputone, myinputform, myselectform, myselectcityform) {
      if (myinputone) {
        for (var m = 0; m < myinputone.input.length; m++) {
          myinputone.input[m].content = "";
        }
      }
      if (myinputform) {
        for (var i = 0; i < myinputform.input.length; i++) {
          myinputform.input[i].content = "";
        }
      }
      if (myselectform) {
        for (var j = 0; j < myselectform.select.length; j++) {
          if (myselectform.select[j].selecttimeduanisshow === true) {
            myselectform.select[j].value.valuestart = "";
            myselectform.select[j].value.valueend = "";
            myselectform.select[j].value.endstatus = true;
          } else {
            myselectform.select[j].value = "";
          }
        }
      }
      if (myselectcityform) {
        // !myselectcityform【!+参数 在if条件中表示检测该参数未定义】
        console.log(myselectcityform);
        myselectcityform.select.valuesheng = "";
        myselectcityform.select.valueshi = "";
        myselectcityform.select.valuequ = "";
        myselectcityform.select.disshi = true;
        myselectcityform.select.disqu = true;
      }
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-添加时间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime = data.addtime.start + " ~ " + data.addtime.start;
        } else {
          getaddtime = data.addtime.start + " ~ " + data.addtime.end;
        }
      }
      //判断-支付时间段状态
      var getpaytime = null;
      if (data.paytime.start == "") {
        getpaytime = "";
      } else {
        if (data.paytime.end == "") {
          getpaytime = data.paytime.start + " ~ " + data.paytime.start;
        } else {
          getpaytime = data.paytime.start + " ~ " + data.paytime.end;
        }
      }
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/shopOrder", {
          headers: {
            Authentication: this.mytoken,
          },
          params: {
            keyword: data.keywords,
            status: data.status,
            area_code: data.area_code,
            addtime: getaddtime,
            paytime: getpaytime,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-添加时间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime = data.addtime.start + " ~ " + data.addtime.start;
        } else {
          getaddtime = data.addtime.start + " ~ " + data.addtime.end;
        }
      }
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/shopOrder", {
          headers: {
            Authentication: this.mytoken,
          },
          params: {
            keyword: data.keywords,
            status: data.status,
            addtime: getaddtime,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
        // 将省市区分割
        var arrcity = newdata[i].area_code.split(",");
        newdata[i].province = arrcity[0];
        newdata[i].city = arrcity[1];
        newdata[i].county = arrcity[2];
        //转换添加时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无添加时间";
          } else {
            // console.log('添加时间：' + newdata[i].addtime)
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无添加时间";
        }
        // 转换状态
        if (newdata[i].status) {
          if (newdata[i].status == 1) {
            newdata[i].status_name = "启用";
          } else {
            newdata[i].status_name = "禁用";
          }
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  watch: {
    //检测父组件传来的currentpagenum值变化
    currentpagenum: function () {
      // console.log('检测到的数据：' + this.currentpagenum)
      if (this.currentpagenum) {
        this.currentPage = this.currentpagenum;
      } else {
        this.currentPage = 1;
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/shopOrder", {
          headers: {
            Authentication: this.mytoken,
          },
          params: {},
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
    maininput,
    mainselect,
  },
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>
