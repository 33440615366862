<template>
    <div>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        >
            <template v-slot:searchModel>
                <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; color: #000; font-size: 14px; margin-right: 10px">
                    <div><span>时间：</span></div>
                    <!-- type: monthrange||daterange -->
                    <datePicker
                        :SelectTimesCustom="back_SelectTimesCustom"
                        :Date_Disabled="true"
                        :Button_Change="{ chat: '请选择', type: 'daterange' }"
                        :Out_Time="{ time_size: 8035200, erro_message: '选中的时间差不能超过 93 天' }"
                        @change-data="changeDataSearchEvent"
                    />
                </div>
            </template>
        </maintwo>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import maintwo from '@/components/Maintwo'

export default defineComponent({
    name: 'selectOne',
    inject: ['reload'],
    data() {
        return {
            maintwoiconshow: false,
            maintwoforminfo: {
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '180px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号：',
                            placeholder: '请输入设备编号', //此name为键值
                            name: 'cid',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/shop/Equipment/searchClient',
                            posttype: 'get',
                            inputval: { client: '' } //这里记录需要传的参数
                        }
                    ]
                }
            },

            back_SelectTimesCustom: ''
        }
    },
    methods: {
        changeDataSearchEvent(data) {
            this.back_SelectTimesCustom = [data[0] * 1000, data[1] * 1000]
            this.$emit('getdatasearchinfo', data)
        },
        getSeachinfoEvent(data) {
            this.$emit('getsearchinfo', data)
        }
    },
    components: {
        maintwo
    }
})
</script>

<style scoped></style>
