<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px;height: 60px; border-bottom: 1px solid rgb(235, 238, 245);">
          <!--左侧名称-->
          <div style="font-size: 14px">手机号申请推荐码</div>
        </div>

        <div class="el_pic">
          <div class="refercode">
            <img :src="qrUrl" alt="加载图片失败" />
          </div>
          <div class="confirm">
            <a href="" id="downrefercode">
              <el-button type="primary">下载推荐码</el-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import QRCode from "qrcode";
// import QRCode from "qrcodejs2";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vreferralcode",
  data() {
    return {
      mainonebread: [
        {
          name: "首页",
        },
        {
          name: "推荐码",
          path: "/referralcode",
        },
      ],

      qrUrl: null,
      qrcodeurl: "",
      mytoken: localStorage.getItem("token"),
    };
  },
  methods: {
    creatQrCode() {
      const _this = this;
      QRCode.toDataURL(_this.qrcodeurl, (err, url) => {
        _this.qrUrl = url;
        let a = document.getElementById("downrefercode");
        a.href = url;
        a.setAttribute("download", "二维码.jpeg");
      });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/ShopSet/code",
          {},
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          console.log(response);
          this.qrcodeurl = response.data;
          this.qrcodeurl.replaceAll(/"\\"/g, "");
          this.$nextTick(() => {
            this.creatQrCode();
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  mounted() { },
  components: {
    mainone,
    //   maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.el_pic {
  width: 100%;
  min-height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.refercode {
  margin-top: 50px;
  border: 1px solid rgb(235, 238, 245);
  border-radius: 8px;
  padding: 5px;
}

.refercode img {
  width: 300px;
  height: 300px;
  border-radius: 10px;
}

.confirm {
  margin-top: 40px;
}
</style>