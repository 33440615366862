<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">优惠券详情</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->

                <div class="box_content">
                    <div class="box_title_font">基本信息</div>
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="6">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="10">
                            <el-form
                                    ref="ruleForm"
                                    :model="ruleForm"
                                    :rules="rules"
                                    label-width="200px"
                                    class="demo-ruleForm"
                            >
                                <el-form-item label="适用渠道：" prop="type_name">
                                    <el-input v-model="ruleForm.type_name" placeholder=""
                                              maxlength="10" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="优惠券名称：" prop="title">
                                    <el-input v-model="ruleForm.title" placeholder="如国庆节优惠券，最多10个字"
                                              maxlength="10" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="名称备注：" prop="name">
                                    <el-input v-model="ruleForm.name" placeholder="仅内部可见，最多10个字"
                                              maxlength="10" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="总发行量：" prop="number">
                                    <el-input v-model="ruleForm.number" placeholder="只能输入正整数，最大为10000" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="面额：" prop="money">
                                    <el-input v-model="ruleForm.money_name" :disabled="true">
                                        <!--<template #append>元</template>-->
                                    </el-input>
                                    <div style="height: 20px; line-height: 20px">
                                        <span style="font-size: 12px; color: #999999">面值只能是数值，0.01-1000，限2位小数</span>
                                    </div>
                                </el-form-item>
                                <el-form-item label="使用门槛：">
                                    <el-input v-model="ruleForm.full_name" placeholder="" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="仅限新用户：">
                                    <el-input v-model="ruleForm.is_new_name" placeholder="" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="每人限领：">
                                    <el-input v-model="ruleForm.limits_name" placeholder="" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="用券时间：">
                                    <el-input v-model="ruleForm.use_time" placeholder="" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="适用设备(仅限直属设备)：" v-if="ruleForm.type == 1">
                                    <!--<el-input v-model="ruleForm.use_range" placeholder="" :disabled="true" v-if="ruleForm.is_all == 1"></el-input>-->
                                    <!--<el-input v-model="ruleForm.use_range" placeholder="" :disabled="true"></el-input>-->

                                    <el-input
                                            type="textarea"
                                            :rows="2"
                                            placeholder=""
                                            v-model="ruleForm.use_range"
                                            :disabled="true"
                                           >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="领券中心：">
                                    <el-input v-model="ruleForm.is_show" placeholder="" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="显示平台：">
                                    <el-input v-model="ruleForm.thirdparty_name" placeholder="" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="备注：">
                                    <el-input
                                            type="textarea"
                                            :rows="2"
                                            placeholder=""
                                            v-model="ruleForm.remark"
                                            :disabled="true">
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mainone from "@/components/Mainone";
    // import mainimgesup from "@/components/Mainimgesup.vue";
    // import {ref} from "vue";
    import {ElMessage} from "element-plus";
    // import {ElMessageBox, ElMessage} from "element-plus";
    import axios from "axios";

    export default {
        name: "vcouponinfo",
        data() {
            return {
                value2: [],  //多选设备存储id值
                mainonebread: [
                    {
                        name: '运营',

                    },
                    {
                        name: '优惠券',
                        path: '/coupon'
                    },
                    {
                        name: '优惠券详情',
                        // path: '/luckydraw'
                    }
                ], // 当前页-【面包屑导航】
                ruleForm: {
                    type: 1,
                    title: "",
                    name: "",
                    number: "",
                    money: "",
                    full: 0,
                    fullN: "",
                    is_new: -1,
                    limits: 0,
                    limitsN: "",
                    time_type: 1,
                    use_time: "",
                    begin_time: "",
                    end_time: "",
                    fixed_days: "",
                    is_all: 1,
                    partake_list: "",
                    is_show: -1,
                    thirdParty: [1],
                    remark: ""
                }, //form表单
                rules: {
                    title: [
                        {
                            required: true,
                            message: "请填写活动名称",
                            trigger: "blur",
                        },
                    ],
                    number: [
                        {
                            required: true,
                            message: "请填写总发行量",
                            trigger: "blur",
                        },
                    ],
                    money: [
                        {
                            required: true,
                            message: "请填写优惠券面额",
                            trigger: "blur",
                        },
                    ],
                }, //form表单-必填规则


                tagshownum: 10, //tag显示数量，默认是10条
                push_alldvc: [], //获取的投放设备-与tableseniorForm中的alldvc同步，通过弹窗【确认】获得

                //弹框-查询设备-form表单数据
                seniorForm: {
                    search: "",
                    area_code: "",
                },
                dialogVisible: false,
                searchwidth: "1024px",
                //弹窗中-selectcity选框
                myselectcityisshow: true,
                myselectcity: {
                    selectwidth: "100%",
                    select: {
                        ismust: false,
                        name: "area_code",
                        valuesheng: "",
                        valueshi: "",
                        valuequ: "",
                        disshi: true,
                        disqu: true,
                    },
                },
                //弹窗中-table表单
                tableData: [],
                tableseniorForm: {
                    dvcids: [],
                    alldvc: [],
                    is_all: 0,
                },
                //弹窗中-table表单-分页器
                totalCount: 0, //数据列表中-数据总条数
                currentpage: 1, //数据列表中-数据当前页数
                lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
                currentPage: 1,
                pageSizes: [10, 20, 50, 100, 1000],
                PageSize: 10,

                loading: false,
                mytoken: localStorage.getItem("token"), //获取存储的token
                pagesdatas: [], //存储最近一次的筛选条件，供分页使用
                changeAllDvc: [], //等待全选的所有数据

                uploadParams: {},


            };
        },
        created() {
            if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
                this.isshow = false;
                this.$router.push("/").catch((error) => error);
            } else {
                axios.post('/shop/coupon/see',
                    {
                        id: this.$route.query.id,
                        // page:1,
                        // page_size:20
                    },
                    {
                        headers: {
                            'Authen': this.mytoken
                        }
                    })
                    .then(response => {
                        // this.myaxiosfunc(response.data.result)
                        console.log(response.data.result)
                        this.ruleForm = response.data.result;
                        // this.ruleForm.time = response.data.result.time_type == 1 ? this.$utils.formatDate(response.data.result.begin_time) + this.$utils.formatDate(response.data.result.end_time) : '领取后' + response.data.result.fixed_days + '天后';
                        // this.ruleForm.time = response.data.result.time_type == 1 ?  response.data.result.tuse_time : '领取后' + response.data.result.fixed_days + '天后';
                        this.ruleForm.is_show = this.ruleForm.is_show == 1 ? '显示' : '不显示'
                    })
                    .catch(function (error) {
                        console.log(error)
                    })

            }
        },
        mounted() {
        },
        methods: {
            //点击关闭-弹窗
            handleCloseSenior(done) {
                done();
                if (this.value2.length == 0) {
                    this.ruleForm.is_all = 1;
                }
            },
            //弹窗中-分页器中-当选择条数时触发该函数
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.PageSize = val;
                this.currentPage = 1;
            },
            //弹窗中-分页器中-当选择分页时触发该函数
            handleCurrentChange(val) {
                console.log(`当前页: 第${val}页`);
                this.currentPage = val;

                this.loading = true;
                axios
                    .post(
                        "/admin/ClientAdvert/device",
                        {
                            search: this.pagesdatas.search,
                            area_code: this.pagesdatas.area_code,
                            page_size: this.PageSize,
                            page: val,
                        },
                        {
                            headers: {
                                Authen: localStorage.getItem("token"),
                            },
                        }
                    )
                    .then((response) => {
                        var successdata = response.data.result.res;
                        var newdata = successdata.data;
                        for (var i = 0; i < newdata.length; i++) {
                            //渲染-状态-该字段表示设备状态按钮
                            newdata[i].devstatus = 1;
                        }
                        this.tableData = newdata;
                        this.totalCount = successdata.total;
                        this.currentpage = successdata.current_page; //当前页面页数
                        this.lastpage = successdata.last_page;
                        this.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            //弹窗中-table表单-多选按钮
            handleSelectionChange(val) {
                console.log("获取的 val is: " + val);

                // 存储所选择的设备
                this.tableseniorForm.alldvc = val;
            },
            //弹窗中-查询按钮-提交
            onSubmitTan(formName) {
                var getdatas = this.$refs[formName].model;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.pagesdatas = getdatas; //将本次的筛选条件存储在pagesdatas中，便于切换pages时携带参数！
                        this.loading = true;
                        axios
                            .post(
                                "/admin/ClientAdvert/device",
                                {
                                    search: getdatas.search,
                                    area_code: getdatas.area_code,
                                    page_size: 10,
                                    page: 1,
                                },
                                {
                                    headers: {
                                        Authen: localStorage.getItem("token"),
                                    },
                                }
                            )
                            .then((response) => {
                                var successdata = response.data.result.res;
                                var newdata = successdata.data;
                                for (var i = 0; i < newdata.length; i++) {
                                    //渲染-状态-该字段表示设备状态按钮
                                    newdata[i].devstatus = 1;
                                }
                                this.tableData = newdata;
                                this.totalCount = successdata.total;
                                this.currentpage = successdata.current_page; //当前页面页数
                                this.lastpage = successdata.last_page;
                                this.loading = false;
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            //获取当前windows页面信息-实时修改【弹窗】的宽度
            getwindowinfo() {
                this.sreenheight = document.documentElement.clientHeight;
                this.sreenwidth = document.documentElement.clientWidth;
                if (this.sreenwidth < 1100) {
                    this.searchwidth = this.sreenwidth * 0.8 + "px";
                } else {
                    this.searchwidth = "1024px";
                }
            },
            //去重方法
            unique(arr, val) {
                const res = new Map();
                return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1));
            },
            //弹出框-选择设备-确认
            onSubmitSenior(formData) {
                console.log("queren:" + JSON.stringify(formData.alldvc));
                let arrdata = formData.alldvc;
                if (this.value2.length == 0) {
                    this.push_alldvc = formData.alldvc;
                    let arrNew = []
                    formData.alldvc.forEach(item => {
                        arrNew.push(item.id)
                    })
                    this.value2 = arrNew
                } else {
                    // for(var i=0;i<arrdata.length;i++){
                    //     this.push_alldvc.push(arrdata[i])
                    // }
                    let newdata = [...this.push_alldvc, ...arrdata]
                    let arr2 = []
                    arr2 = this.unique(newdata, 'id');
                    this.push_alldvc = arr2;
                    let arrNew = []
                    arr2.forEach(item => {
                        arrNew.push(item.id)
                    })
                    this.value2 = arrNew
                }

            },
            //弹出框-选择设备-取消
            resetFormSenior(datas) {
                // this.$refs["multipleTable"].clearSelection();
                console.log("this.tableseniorForm:" + JSON.stringify(datas));
                if (this.value2.length == 0) {
                    this.ruleForm.is_all = 1;
                }

                this.seniorForm = {
                    search: "",
                    area_code: "",
                };
                this.myselectcity.select = {
                    ismust: false,
                    name: "area_code",
                    valuesheng: "",
                    valueshi: "",
                    valuequ: "",
                    disshi: true,
                    disqu: true,
                };
            },
            allChange(e) {
                console.log(e)
                if (e == 2) {
                    console.log(e)
                    this.loading = true;
                    axios
                        .post(
                            "/admin/ClientAdvert/device",
                            {
                                search: "",
                                area_code: "",
                                page_size: this.PageSize,
                                page: 1,
                            },
                            {
                                headers: {
                                    Authen: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            var successdata = response.data.result.res;
                            var newdata = successdata.data;
                            for (var i = 0; i < newdata.length; i++) {
                                //渲染-状态-该字段表示设备状态按钮
                                newdata[i].devstatus = 1;
                            }
                            this.tableData = newdata;
                            this.totalCount = successdata.total;
                            this.currentpage = successdata.current_page; //当前页面页数
                            this.lastpage = successdata.last_page;
                            this.loading = false;
                            this.changeAllDvc = newdata;
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    this.dialogVisible = true
                }
            },
            //多选下拉框监听
            eleChange(e) {
                console.log(e)
                if (e.length == 0) {
                    this.ruleForm.is_all = 1;
                    this.value2 = [];
                    this.push_alldvc = [];
                }
            },
            //选择时间并转换
            changeTimes(data, chart) {
                console.log("当前获取的时间：" + data);
                this.ruleForm[chart] = new Date(data).getTime();
                // console.log((new Date(data).getTime())/ 1000)
                //比较开始时间与结束时间
                if (this.ruleForm.begin_time == "") {
                    ElMessage({
                        type: "warning",
                        message: "请先选择开始时间！",
                    });
                    this.ruleForm.end_time = "";
                } else {
                    if (this.ruleForm.end_time != "") {
                        if (this.ruleForm.begin_time > this.ruleForm.end_time) {
                            ElMessage({
                                type: "warning",
                                message: "选择的结束时间必须大于开始时间！",
                            });
                            this.ruleForm.end_time = "";
                        }
                    }
                }
            },
            //提交form表单-并判断是否必填
            submitForm(formName) {
                var formdatas = this.$refs[formName].model;
                var datas = ""
                // console.log(formdatas)
                // console.log(this.value2)
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        console.log(formdatas)
                        // var datas = null;
                        // if (formdatas.begin_time == 0) {
                        //     formdatas.begin_time = "";
                        //     formdatas.my_begin_time = "";
                        // } else {
                        //     formdatas.my_begin_time = formdatas.begin_time / 1000;
                        // }
                        // if (formdatas.end_time == 0) {
                        //     formdatas.end_time = "";
                        //     formdatas.my_end_time = "";
                        // } else {
                        //     formdatas.my_end_time = formdatas.end_time / 1000;
                        // }
                        // console.log("提交的form表单：" + JSON.stringify(formdatas));

                        if (formdatas.full == 1 && !formdatas.fullN) {
                            this.$message({
                                message: '请设置使用门槛',
                                type: 'warning'
                            });
                        } else if (formdatas.limits == 1 && !formdatas.limitsN) {
                            this.$message({
                                message: '请设置每人限领次数',
                                type: 'warning'
                            });
                        } else if (formdatas.time_type == 1 && !formdatas.begin_time) {
                            this.$message({
                                message: '请选择开始时间',
                                type: 'warning'
                            });
                        } else if (formdatas.time_type == 1 && !formdatas.end_time) {
                            this.$message({
                                message: '请选择结束时间',
                                type: 'warning'
                            });
                        } else if (formdatas.time_type == 2 && !formdatas.fixed_days) {
                            this.$message({
                                message: '请设置固定天数',
                                type: 'warning'
                            });
                        } else if (formdatas.type == 1 && formdatas.is_all == 2 && this.value2.length == 0) {
                            this.$message({
                                message: '请选择优惠券适用设备',
                                type: 'warning'
                            });
                        } else {
                            let times = formdatas.begin_time / 1000 + ' ~ ' + formdatas.end_time / 1000
                            datas = {
                                type: formdatas.type,
                                title: formdatas.title,
                                name: formdatas.name,
                                number: formdatas.number,
                                money: formdatas.money,
                                full: formdatas.full == 0 ? 0 : formdatas.fullN,
                                is_new: formdatas.is_new,
                                limits: formdatas.limits == 0 ? 0 : formdatas.limitsN,
                                time_type: formdatas.time_type,
                                use_time: times,
                                fixed_days: formdatas.fixed_days,
                                is_all: formdatas.is_all,
                                partake_list: this.value2.join(),
                                is_show: formdatas.is_show,
                                thirdParty: formdatas.thirdParty.join(),
                                remark: formdatas.remark,
                            };
                            console.log(datas);
                            this.subDatas(datas);
                        }


                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            //重置form表单中填写的数据
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            //提交API-函数
            subDatas(datas) {
                axios
                    .post("/admin/Coupon/add", datas, {
                        headers: {
                            Authen: localStorage.getItem("token"),
                        },
                    })
                    .then((response) => {
                        if (response.data.code === 0) {
                            ElMessage({
                                type: "success",
                                message: "添加成功！",
                                duration: 1000,
                                onClose: () => {
                                    this.$router.push("/coupon").catch((error) => error);
                                },
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
        components: {
            mainone,
            // mainimgesup,
        },
    };
</script>

<style scoped>
    .box {
        background-color: #ffffff;
        border-radius: 4px;
        color: #333333;
        padding: 10px 20px;
    }

    .box_title {
        line-height: 40px;
        height: 40px;
    }

    .box_title_font {
        float: left;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
    }

    .box_title_button {
        float: right;
        font-weight: 400;
        font-size: 14px;
        color: #94a3d2;
    }

    .box_title_button:hover {
        cursor: pointer;
    }

    .box_content {
        border-top: 1px solid #eeeeee;
        margin-top: 10px;
    }
</style>