<template>
  <div>
    <div>
      <mainone :mainonebread="mainonebread"></mainone>
    </div>
    <!-- 整页搜索 -->
    <div class="allselect">
      <div class="allselect-box">
        <div>
          <el-select v-model="this.yinsouselect.platform" @change="(data) => orderline(data, this.changetime)"
            placeholder="全部设备">
            <el-option v-for="item in platforms" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button type="primary" size="small" @click="orderline(this.yinsouselect.platform, '昨日')">昨日</el-button>
          <el-button type="primary" size="small" @click="orderline(this.yinsouselect.platform, '今日')">今日</el-button>
          <el-button type="primary" size="small" @click="orderline(this.yinsouselect.platform, '本月')">本月</el-button>
        </div>
        <div style="margin-top: 20px">
          <mainselect :myselectleftisshow="myselectleftisshow" :myselectleft="myselectleft"
            v-on:gettimesrange="(data) => myorderline(data, this.yinsouselect.platform)"></mainselect>
        </div>
      </div>
    </div>
    <!-- 打印数据概览 -->
    <div class="yinsou-box">
      <div class="yinsou-box-title">
        <div class="yinsou-box-title-text">打印数据概览</div>
        <div class="yinsou-box-title-select"></div>
      </div>
      <!-- 样式-card -->
      <div class="yinsou-box-card">
        <div class="yinsou-box-card-box" v-for="(item, index) in this.yinsoudata" :key="index">
          <div class="card-box-icon">
            <el-image :src="
              require('@/assets/imgs/prtintoverview/' + item.icon + '.jpg')
            "></el-image>
          </div>
          <div class="card-box-content">
            <div class="card-box-content-title">{{ item.title }}</div>
            <div class="card-box-content-money">
              <div>
                <span v-if="item.money">¥ {{ parseFloat(item.money).toFixed(2) }} /
                </span>
                <span v-if="item.number1">{{ item.number1 }} / </span>
                <span v-if="item.number">{{ item.number }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 打印订单统计 -->
    <div class="yinsou-box">
      <div class="yinsou-box-title">
        <div class="yinsou-box-title-text">打印订单统计</div>
        <div class="yinsou-box-title-select"></div>
      </div>
      <!-- 样式-card -->
      <div>
        <mainwechat ref="linechart" :linegradualisshow="linegradualisshow" :linegradualdata="linegradual"></mainwechat>
      </div>
    </div>

    <!--打印介质统计-->
    <div>
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px 20px 20px;
        ">
        <div style="line-height: 50px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">介质统计</div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainwechat from "@/components/Mainecharts.vue";
import mainselect from "@/components/Mainselect";
import * as echarts from "echarts";
import axios from "axios";
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "vaccount",
  data() {
    return {
      mainonebread: [
        {
          name: "首页",
        },
        {
          name: "打印概览",
          path: "/account",
        },
      ],

      // 【打印概览】-设备下拉
      platforms: [],
      // 【打印概览】-时间段
      myselectleftisshow: true, //筛选查询-左侧字+select单框||select时间
      myselectleft: {
        width: "none",
        divinterval: "", //框右侧距离
        selectwidth: "150px",
        selectinterval: "", //select右侧距离
        select: [
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "ordertime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },

      // 【打印数据概览】-卡片数据
      yinsoudata: [
        {
          icon: "icon1",
          title: "支付金额/笔数",
          explain: "平台所有的支付方式收入总览",
          money: "0",
          number: "0",
        },
        {
          icon: "icon2",
          title: "分佣金额/笔数",
          explain: "打印支付的订单扣除打印退款的订单",
          money: "0",
          number: "0",
        },
        {
          icon: "icon3",
          title: "打印张数/退款张数",
          number1: "0",
          number: "0",
        },
        {
          icon: "icon4",
          title: "退款金额/笔数",
          money: "0",
          number: "0",
        },
      ],
      // 【打印数据概览】-搜索字段
      yinsouselect: {
        platform: "",
        paytype: "",
        paytime: "",
      },
      // 【打印数据概览】-选中的时间字段
      changetime: "今日",

      // 【打印订单统计】
      linegradualisshow: true, //折现图-渐变堆叠
      linegradual: [
        {
          idname: "linegradual",
          width: "95%",
          height: "350px",
          text: "", //折线图-标题
          maincolor: ["#80FFA5", "#00DDFF"], //定义主色调，且有几个模块，定义几个，[数组string类型]
          legenddata: ["金额(元)", "笔数"], //定义模块，[数组strin
          subtextx: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], //图表X轴-标题
          gridleft: "10%", //图表距离左侧距离
          gridright: "10%", //图表距离右侧距离
          gridbottom: "10%", //图表距离底部距离
          data: [
            {
              name: "金额(元)", //与上方模块定义的名对应
              type: "line",
              stack: "total",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(128, 255, 165)", //渐变的颜色1
                  },
                  {
                    offset: 1,
                    color: "rgba(1, 191, 236)", //渐变的颜色2
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: [], //对应图表X轴上的数据
            },
            {
              name: "笔数",
              type: "line",
              stack: "count",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(0, 221, 255)",
                  },
                  {
                    offset: 1,
                    color: "rgba(77, 119, 255)",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: [],
            },
          ],
        },
      ],

      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "name",
          label: "打印类型",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "total",
          label: "订单金额（扣除退款)",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "count",
          label: "订单数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "printpapernum",
          label: "打印张数",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,

      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  methods: {
    // 时间函数-事件
    getTimeEvent(flag) {
      // 获取今天的时间戳 10位
      let toData = new Date(new Date().toLocaleDateString()).getTime() / 1000;

      // 获取-本日-时间段
      if (flag == 1) {
        let todayStart = toData; //开始时间
        let todayEnd = todayStart + 86400 - 1; //结束时间
        console.log(todayStart, todayEnd);
        let timeduan = todayStart + " ~ " + todayEnd;
        return timeduan;
      }
      // 获取-最近7日-时间段
      if (flag == 7) {
        let past7daysStart = toData - 7 * 3600 * 24;
        let past7daysEnd = toData - 1;
        console.log(past7daysStart, past7daysEnd);
        let timeduan = past7daysStart + " ~ " + past7daysEnd;
        return timeduan;
      }
      // 获取-最近30日-时间段
      if (flag == 30) {
        let past30daysStart = toData - 30 * 3600 * 24;
        let past30daysEnd = toData - 1;
        console.log(past30daysStart, past30daysEnd);
        let timeduan = past30daysStart + " ~ " + past30daysEnd;
        return timeduan;
      }
      // 获取-最近1年-时间段
      if (flag == 365) {
        let past365daysStart = toData - 365 * 3600 * 24;
        let past365daysEnd = toData - 1;
        console.log(past365daysStart, past365daysEnd);
        let timeduan = past365daysStart + " ~ " + past365daysEnd;
        return timeduan;
      }
    },
    // 打印数据概览||打印订单统计||介质统计-自定义时间筛选
    myorderline(data, client) {
      let start = data.valuestart.valueOf() / 1000;
      let end = data.valueend.valueOf() / 1000;

      var getordertime = "";
      if (start == "") {
        getordertime = "";
      } else {
        if (end == "") {
          getordertime = start + " ~ " + (start + 86400 - 1);
        } else {
          getordertime = start + " ~ " + (end + 86400 - 1);
        }
      }
      this.orderline(client, getordertime);
    },
    // 打印数据概览||打印订单统计||介质统计-获取接口数据
    orderline(client, searchtime) {
      this.changetime = searchtime;
      let mysearchtime = searchtime;
      // 昨日
      if (searchtime == -1) {
        mysearchtime = this.getTimeEvent(-1);
      }
      // 今日
      if (searchtime == 1) {
        mysearchtime = this.getTimeEvent(1);
      }
      // 本月
      if (searchtime == 30) {
        mysearchtime = this.getTimeEvent(30);
      }

      // 获取【打印数据概览】信息
      axios
        .post(
          "/shop/census/printInfo",
          {
            clientid: client,
            search_time: mysearchtime,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            let datas = response.data.result
            this.yinsoudata[0].money = datas.total.toString(); //支付金额
            this.yinsoudata[0].number = datas.printordernum.toString(); //支付笔数
            this.yinsoudata[1].money = datas.commissiontotal.toString(); //分佣金额
            this.yinsoudata[1].number = datas.commissionnum.toString(); //分佣笔数
            this.yinsoudata[2].number1 = datas.printpapernum.toString(); //打印张数
            this.yinsoudata[2].number = datas.refundpapernum.toString(); //退款张数
            this.yinsoudata[3].money = datas.refundamount.toString(); //退款金额
            this.yinsoudata[3].number = datas.refundordernum.toString(); //退款笔数
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // 获取【打印订单统计】信息
      axios
        .post(
          "shop/census/printLine",
          {
            clientid: client,
            search_time: mysearchtime,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.linegradual[0].subtextx = res.data.result.show;
            this.linegradual[0].data[0].data = res.data.result.total;
            this.linegradual[0].data[1].data = res.data.result.count;
            this.$refs.linechart.echartGradual(this.linegradual, 0);
          } else {
            ElMessage({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // 【打印介质】table模块的加载动画
      this.loading = true;
      // 获取【打印介质】信息
      axios
        .post(
          "/shop/census/printCate",
          {
            clientid: client,
            search_time: mysearchtime,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            this.mainthreetableData = response.data.result;
            this.loading = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 设备下拉列表显示
    getdvcinfoEvent() {
      axios
        .post(
          "/shop/client/clientBelong",
          {},
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.result.length > 0) {
            let configinfo = response.data.result;
            var alldatas = Array();
            var num = 0;
            alldatas[num] = { value: '', label: "全部设备" };
            for (var key in configinfo) {
              num = num + 1;
              alldatas[num] = {
                value: configinfo[key].id,
                label: configinfo[key].client,
              };
            }
            this.platforms = alldatas;
          }
        });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.$router.push("/").catch((error) => error);
    } else {
      // 设备下拉列表显示
      this.getdvcinfoEvent();

      // 获取今日数据
      this.orderline('', '今日');
    }
  },
  components: {
    mainone,
    mainwechat,
    mainselect,
  },
});
</script>

<style scoped>
/* -------------------整页搜索----------------------- */
.allselect {
  background-color: #ffffff;
  padding: 0 20px;
  border-radius: 4px;
}

.allselect-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.allselect-box div {
  margin: 10px 10px 10px 0;
}

/* -----------------打印数据概览-------------------- */
.yinsou-box {
  background-color: #ffffff;
  margin: 10px 0;
  padding: 20px 0 10px 20px;
  border-radius: 4px;
}

.yinsou-box-title,
.yinsou-box-card,
.yinsou-box-card-box,
.yinsou-box-title-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.yinsou-box-title {
  align-items: center;
}

.yinsou-box-title-text {
  font-size: 14px;
  color: #333333;
  margin-right: 20px;
}

.yinsou-box-title-select div {
  margin-right: 10px;
}

.yinsou-box-card {
  margin: 10px 0;
  justify-content: space-between;
}

.yinsou-box-card-box {
  border: 1px solid #eaedf7;
  padding: 30px;
  border-radius: 8px;
  min-width: 300px;
  margin: 10px 0;
  flex-grow: 1;
  margin: 10px 20px 10px 0;
}

.card-box-icon {
  margin-right: 30px;
}

.card-box-icon {
  width: 60px;
  height: 60px;
}

.card-box-content-title {
  font-size: 16px;
  color: #999999;
  font-weight: 400;
  margin-bottom: 8px;
}

.card-box-content-money {
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  display: flex;
  flex-direction: row;
}

.card-box-content-money .el-image {
  margin-left: 20px;
}
</style>
