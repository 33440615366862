<template>
    <div id="mymain">
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">
                        <span style="margin-right: 40px"> 代理商详情</span>
                    </div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <!--信息-->
                    <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px"></maindetails>
                    <!--身份证信息-->
                    <div class="idcard_no_box">
                        <div class="box-list-on">
                            <div class="box-list-on-title">
                                <span>身份证号:</span>
                            </div>
                            <div>
                                <span>{{ this.mainpagedata.details.idcard_no }}</span>
                            </div>
                        </div>
                        <div class="box-list-on">
                            <div class="box-list-on-title">
                                <span>身份证正面照:</span>
                            </div>
                            <div>
                                <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_on"></mainimgesshow>
                            </div>
                        </div>
                        <div class="box-list-on">
                            <div class="box-list-on-title">
                                <span>身份证反面照:</span>
                            </div>
                            <div>
                                <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_off"></mainimgesshow>
                            </div>
                        </div>
                    </div>
                    <div v-if="is_show_pull">
                        <el-divider />
                        <maindetails :detailsdata="mainpagedata.details1" style="margin-top: 20px"></maindetails>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
import axios from 'axios'

export default {
    name: 'vagentlistinfo',
    data() {
        return {
            mainpagedata: {
                mainonebread: [
                    {
                        name: '代理商'
                    },
                    {
                        name: '代理商列表',
                        path: '/agentlist'
                    },
                    {
                        name: '代理商详情',
                        path: '/agentlistinfo'
                    }
                ], // 当前页-【面包屑导航】

                details: {
                    showstatus: 1, //确定样式模块
                    title: '基本信息',
                    titlewidth: '150px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '姓名:',
                            value: ''
                        },
                        {
                            name: '手机号:',
                            value: ''
                        },
                        {
                            name: '负责设备:',
                            value: ''
                        },
                        {
                            name: '佣金比例:',
                            value: ''
                        },
                        {
                            name: '所属地区:',
                            value: ''
                        },
                        {
                            name: '价格参数修改权限:',
                            value: ''
                        },
                        {
                            name: '是否自动提现:',
                            value: ''
                        },
                        {
                            name: '机器广告图修改权限:',
                            value: ''
                        },
                        {
                            name: '订单退款权限:',
                            value: ''
                        },
                        {
                            name: '提现微信:',
                            value: ''
                        },
                        {
                            name: '提现支付宝:',
                            value: ''
                        },
                        {
                            name: '状态:',
                            value: ''
                        }
                    ],
                    idcard_no: '', // 身份证号
                    imgesdata_on: {
                        showstatus: 2,
                        fit: 'contain',
                        content: [
                            // {
                            //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                            //   preview: [
                            //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                            //   ],
                            // },
                        ]
                    },
                    imgesdata_off: {
                        showstatus: 2,
                        fit: 'contain',
                        content: [
                            // {
                            //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                            //   preview: [
                            //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                            //   ],
                            // },
                        ]
                    }
                }, //关于详情信息的组件

                // 介绍人
                details1: {
                    showstatus: 1, //确定样式模块
                    title: '介绍人',
                    titlewidth: '150px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '介绍人:',
                            value: ''
                        },
                        {
                            name: '分佣设备:',
                            value: ''
                        },
                        {
                            name: '介绍人佣金比例:',
                            value: ''
                        },
                        {
                            name: '分佣有效期:',
                            value: ''
                        },
                        {
                            name: '分佣状态:',
                            value: ''
                        }
                    ]
                } //关于详情信息的组件
            }, // 当前Pagedata所需的data数据与控制
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            loading: false
        }
    },
    methods: {
        tableRowClassName({ rowIndex }) {
            if ((rowIndex + 1) % 2 === 0) {
                return 'dabuleque'
            }
            return ''
        },
        routeraddrClick(routeraddr) {
            //点击进入路由页面
            this.$router.push(routeraddr).catch((error) => error)
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/shop/shopUser/view',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data)
                    var getdatas = response.data.result
                    // 渲染-广告信息
                    this.mainpagedata.details.content = [
                        {
                            name: '姓名:',
                            value: getdatas.name
                        },
                        {
                            name: '手机号:',
                            value: getdatas.username
                        },
                        {
                            name: '负责设备:',
                            value: ''
                        },
                        {
                            name: '打印佣金比例:',
                            value: getdatas.ratio
                        },
                        {
                            name: '流量变现佣金比例:',
                            value: getdatas.flow_ratio
                        },
                        {
                            name: '所属地区:',
                            value: getdatas.area_code_show
                        },
                        {
                            name: '价格参数修改权限:',
                            value: getdatas.is_save_attr == 1 ? '有' : '无'
                        },
                        {
                            name: '是否自动提现:',
                            value: getdatas.is_forthwith == 1 ? '是' : '否' + ' [每月提现：' + getdatas.month_draw_times + '次，限制金额：' + getdatas.month_draw_minmoney + '元]'
                        },
                        {
                            name: '机器广告图修改权限:',
                            value: getdatas.is_save_adv == 1 ? '有' : '无'
                        },
                        {
                            name: '订单退款权限:',
                            value: getdatas.refund_audit == 1 ? '有' : '无'
                        },
                        {
                            name: '提现微信:',
                            value: getdatas.wechat_user_id ? getdatas.wechat_user_id : ''
                        },
                        {
                            name: '提现支付宝:',
                            value: getdatas.alipaycwechat?.ali_nickname +'-'+ getdatas.alipaycwechat?.ali_user_id
                        },
                        {
                            name: '状态:',
                            value: getdatas.status == 1 ? '启用' : '禁用'
                        }
                    ]

                    // 反显-身份证相关
                    this.mainpagedata.details.idcard_no = getdatas.idcard_no
                    this.mainpagedata.details.imgesdata_on.content =
                        getdatas.idcard_pic_front && getdatas.idcard_pic_front != null
                            ? [
                                  {
                                      url: getdatas.idcard_pic_front,
                                      preview: [getdatas.idcard_pic_front]
                                  }
                              ]
                            : []
                    this.mainpagedata.details.imgesdata_off.content =
                        getdatas.idcard_pic_back && getdatas.idcard_pic_back != null
                            ? [
                                  {
                                      url: getdatas.idcard_pic_back,
                                      preview: [getdatas.idcard_pic_back]
                                  }
                              ]
                            : []

                   // 介绍人
                   this.is_show_pull = getdatas.pull_user == null ? false : true
                    if (getdatas.pull_user != null) {
                        let pull_status = { '-1': '已清除', 1: '分佣中', 2: '已过期' }
                        this.mainpagedata.details1.content = [
                            {
                                name: '介绍人:',
                                value: getdatas.pull_user.name
                            },
                            {
                                name: '分佣设备:',
                                value: getdatas.pull_user.client
                            },
                            {
                                name: '介绍人佣金比例:',
                                value: getdatas.pull_user.ratio
                            },
                            {
                                name: '分佣有效期:',
                                value: getdatas.pull_user.valid_month == 0 ? '永久' : getdatas.pull_user.valid_month + '个月'
                            },
                            {
                                name: '分佣状态:',
                                value: pull_status[getdatas.pull_user.status]
                            }
                        ]
                    }

                    // 渲染-负责设备
                    let clients = []
                    if (getdatas.client_ids.length > 0) {
                        getdatas.client_ids.forEach((element) => {
                            clients.push(element.client)
                        })
                    }
                    this.mainpagedata.details.content[2].value = clients.join(',')

                    this.loading = false
                })
                .catch(function (error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.idcard_no_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: flex-start;
}

.box-list-on {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: flex-start;
    gap: 20px;
    width: 500px;
    margin: 10px 0;
}

.box-list-on-title {
    width: 150px;
    text-align: right;
    font-size: 14px;
}
</style>
