<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'submoney' ||
                mytabletitle.prop === 'trade_no'
              ">
                <div v-if="mytabletitle.prop === 'trade_no'">
                  <div v-if="scope.row.trade_no" style="cursor: pointer"
                    @click="routeraddrClick('/orderlistinfo', scope.row)">
                    <span style="color: #465eff">{{ scope.row.trade_no }}</span>
                  </div>
                </div>
                <!-- 分佣金额 -->
                <div v-if="mytabletitle.prop === 'submoney'">
                  <div v-if="this.userinfo.level == 1">
                    {{ scope.row.agentprice / 100 }}
                  </div>
                  <div v-if="this.userinfo.level == 2">
                    {{ scope.row.shopprice / 100 }}
                  </div>
                  <div v-if="this.userinfo.level == 3">
                    {{ scope.row.fshopprice / 100 }}
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭" :active-value="1"
                      :inactive-value="-1" active-color="#13ce66" inactive-color="#cbcbcb">
                    </el-switch>
                  </div>
                  <div v-if="scope.row.textstatus">
                    <span>{{ scope.row.textstatus }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-代理商列表-->
                <div v-if="mainthreetableope === 1">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/agentdvclist')">设备</el-button>
                  <el-button type="primary" size="mini" @click="routeraddrClick('/agentlistedit')">编辑</el-button>
                  <el-button type="primary" size="mini" v-if="scope.row.opeidupgrade === 1">
                    升级为{{ scope.row.opeidupgrade }}代
                  </el-button>
                  <el-button type="primary" size="mini">登录</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" :layout="Layout"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vorderlist",
  data() {
    return {
      mainonebread: [
        {
          name: "订单",
        },
        {
          name: "订单列表",
          path: "/orderlist",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "65px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "店铺名：",
              placeholder: "请输入店铺名",
              name: "storename",
              content: "",
            },
          ],
        },
        myinputonefullisshow: true, //筛选查询-自动建议下拉框
        myinputonefull: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号", //此name为键值
              name: "client",
              content: "",
              id: "", //此id为给后台的id值
              url: "/shop/Equipment/searchClient",
              posttype: 'get',
              inputval: { client: "" }, //这里记录需要传的参数
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间（放input框之后）
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              cascaderisshow: true,
              ismust: false,
              title: "打印类型：",
              placeholder: "请选择打印类型",
              name: "type",
              value: "",
              options: [],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间（放input框之前）
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "platform",
              value: "1-1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1-1", //设置默认值
              options: [],
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 4, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "trade_no",
          label: "订单编号",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "client",
          label: "设备编号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "dianpuname",
          label: "店铺名",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "totalprintpay",
          label: "实付打印费",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "submoney",
          label: "分佣金额",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "printclassify",
          label: "打印介质",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "printtype",
          label: "打印类型",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "paytype_name",
          label: "支付方式",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "pay_status_name",
          label: "订单状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print_status_name",
          label: "打印状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "pay_time",
          label: "付款时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1000, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      Layout: " sizes, prev, pager, next, jumper",

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "店铺名：",
            placeholder: "请输入店铺名",
            name: "storename",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "订单编号：",
            placeholder: "请输入订单编号",
            name: "trade_no",
            content: "",
          },
        ],
      },
      myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
      myinputonefullgao: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%", //input宽度
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "设备编号：",
            placeholder: "请输入设备编号", //此name为键值
            name: "client",
            content: "",
            id: "", //此id为给后台的id值
            url: "/shop/Equipment/searchClient",
            posttype: 'get',
            inputval: { client: "" }, //这里记录需要传的参数
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "订单状态：",
            placeholder: "全部",
            name: "pay_status",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "打印状态:",
            placeholder: "全部",
            name: "print_status",
            value: "",
            options: [],
          },
          {
            cascaderisshow: true,
            ismust: false,
            title: "打印类型：",
            placeholder: "请选择打印类型",
            name: "type",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "支付方式:",
            placeholder: "全部",
            name: "paytype",
            value: "",
            options: [],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "付款时间区间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "timerange",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselecttopisshow: true, //高级筛选-select单框-放input框之前
      myselecttop: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "platform",
            value: "1-1", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "1-1", //设置默认值
            options: [],
          },
        ],
      },

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mytoken: localStorage.getItem("token"), //获取存储的token
      userinfo: JSON.parse(localStorage.getItem("userinfo")), //获取用户信息
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/shareorder/index",
          {
            client: this.pagesdatas.client,
            storename: this.pagesdatas.storename,
            trade_no: this.pagesdatas.trade_no,
            pay_status: this.pagesdatas.pay_status,
            print_status: this.pagesdatas.print_status,
            type: this.pagesdatas.type,
            paytype: this.pagesdatas.paytype,
            timerange: this.pagesdatas.timerange,
            platform: this.pagesdatas.platform,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / this.PageSize == this.lastpage) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage += 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/shareorder/index",
          {
            client: this.pagesdatas.client,
            storename: this.pagesdatas.storename,
            trade_no: this.pagesdatas.trade_no,
            pay_status: this.pagesdatas.pay_status,
            print_status: this.pagesdatas.print_status,
            type: this.pagesdatas.type,
            paytype: this.pagesdatas.paytype,
            timerange: this.pagesdatas.timerange,
            platform: this.pagesdatas.platform,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / val == this.PageSize) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage = 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 携带参数跳转
        if (routeraddr === "/orderlistinfo") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                orderno: rowdata.trade_no,
                platform: this.pagesdatas.platform
                  ? this.pagesdatas.platform
                  : "1-1",
              },
            })
            .catch((error) => error);
        } else {
          this.$router
            .push({
              path: routeraddr,
              query: { orderno: rowdata.orderno },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/shop/shareorder/index",
          {
            client: this.pagesdatas.client,
            storename: this.pagesdatas.storename,
            trade_no: this.pagesdatas.trade_no,
            pay_status: this.pagesdatas.pay_status,
            print_status: this.pagesdatas.print_status,
            type: this.pagesdatas.type,
            paytype: this.pagesdatas.paytype,
            timerange: this.pagesdatas.timerange,
            platform: this.pagesdatas.platform,
            export: 1,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
            console.log(response.data.message)
        //   ElMessage({ type: "success", message: response.data.message });
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      if (data.type.length > 0) {
        this.pagesdatas.type = data.type[data.type.length - 1]; //将-打印类型-的最后一个元素取出，并赋值给type
      }
      //加载loading
      this.loading = true;
      //判断-申请时间段状态
      var gettimerange = null;
      if (data.timerange.start == "") {
        gettimerange = "";
      } else {
        if (data.timerange.end == "") {
          gettimerange =
            data.timerange.start + " ~ " + (data.timerange.start + 86400 - 1);
        } else {
          gettimerange =
            data.timerange.start + " ~ " + (data.timerange.end + 86400 - 1);
        }
      }
      this.pagesdatas.timerange = gettimerange;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/shareorder/index",
          {
            client: data.client,
            storename: data.storename,
            trade_no: data.trade_no,
            pay_status: data.pay_status,
            print_status: data.print_status,
            type: this.pagesdatas.type,
            paytype: data.paytype,
            timerange: gettimerange,
            platform: data.platform,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      if (data.type.length > 0) {
        this.pagesdatas.type = data.type[data.type.length - 1]; //将-打印类型-的最后一个元素取出，并赋值给type
      }
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/shareorder/index",
          {
            client: data.client,
            storename: data.storename,
            type: this.pagesdatas.type,
            platform: data.platform,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata, flag) {
      var newdata = successdata.data;

      for (var i = 0; i < newdata.length; i++) {
        // 设备编号
        newdata[i].client = newdata[i].ShareClient
          ? newdata[i].ShareClient.client
          : "";

        // 店铺名
        newdata[i].dianpuname = newdata[i].ShareClient.storeinfo_name
          ? newdata[i].ShareClient.storeinfo_name.storename
          : "";

        // 实付打印费
        // newdata[i].totalprintpay = newdata[i].printtotal;
        newdata[i].totalprintpay =
          (parseInt(newdata[i].companyprice) +
            parseInt(newdata[i].agentprice) +
            parseInt(newdata[i].shopprice) +
            parseInt(newdata[i].fshopprice)) /
          100;

        // 打印介质
        newdata[i].printclassify = newdata[i].SharePrintCate != null ? newdata[i].SharePrintCate.printtype_name : '';

        // 打印类型
        newdata[i].printtype = newdata[i].SharePrintCate != null ? newdata[i].SharePrintCate.aliasname : '';

        //转换-付款时间
        if (newdata[i].pay_time) {
          if (newdata[i].pay_time === 0 || newdata[i].pay_time === null) {
            newdata[i].pay_time = "无付款时间";
          } else {
            newdata[i].pay_time = this.$utils.formatDate(newdata[i].pay_time);
          }
        } else {
          newdata[i].pay_time = "无付款时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      // this.totalCount = successdata.total;
      // this.currentpage = successdata.current_page; //当前页面页数
      // this.lastpage = successdata.last_page;
      this.loading = false;

      if (successdata.total == null) {
        // 数据小于当前页数时判断
        if (newdata.length < this.PageSize) {
          if (newdata.length != 0 && this.currentPage == 1) {
            this.totalCount = this.PageSize - 1;
          } else {
            if (newdata.length == 0) {
              // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
              // this.totalCount = this.PageSize * (this.currentPage - 1)
              this.totalCount = this.PageSize * this.currentPage;
            } else {
              this.totalCount = this.PageSize * this.currentPage;
              this.lastpage = this.currentPage
            }
          }
        } else {
          if (this.totalCount < this.PageSize) {
            // 重设置-初始值
            this.totalCount = 1000;
            this.Layout = "sizes, prev, pager, next, jumper";
          } else {
            if (flag == 1) {
              this.currentPage = 1;
            }
          }
        }
      } else {
        this.totalCount = successdata.total;
        this.currentpage = successdata.current_page; //当前页面页数
        this.lastpage = successdata.last_page;
        this.Layout = "total, sizes, prev, pager, next, jumper";
      }
    },

    // 订单状态-加载
    getpaystatus(configinfo) {
      //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      alldatas[num] = { value: '', label: "全部" };
      for (var key in configinfo) {
        num = num + 1;
        alldatas[num] = { value: key, label: configinfo[key] };
      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.myselect.select[0].options = alldatas;
    },
    // 支付方式-加载
    getpaytype(configinfo) {
      //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      alldatas[num] = { value: '', label: "全部" };
      for (var key in configinfo) {
        num = num + 1;
        alldatas[num] = { value: key, label: configinfo[key] };
      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.myselect.select[3].options = alldatas;
    },
    // 打印状态-加载
    getprintstatus(configinfo) {
      //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      alldatas[num] = { value: '', label: "全部" };
      for (var key in configinfo) {
        num = num + 1;
        alldatas[num] = { value: key, label: configinfo[key] };
      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.myselect.select[1].options = alldatas;
    },
    // 打印类型-加载
    getprinttype(configinfo) {
      //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
      let alldatas = Array();
      let num = 0;
      Object.keys(configinfo).forEach((element) => {
        if (configinfo[element].children) {
          let child = this.getrecursion(configinfo[element].children);
          alldatas[num] = {
            value: configinfo[element].id,
            label: configinfo[element].name,
            children: child,
          };
        } else {
          alldatas[num] = {
            value: configinfo[element].id,
            label: configinfo[element].name,
          };
        }
        num = num + 1;
      });
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.maintwoforminfo.selectleft.select[0].options = alldatas;
      this.myselect.select[2].options = alldatas;
    },
    // 打印类型-递归函数
    getrecursion(recursion) {
      let allchilddatas = Array();
      let num = 0;
      Object.keys(recursion).forEach((element) => {
        if (recursion[element].children) {
          let child = this.getrecursion(recursion[element].children);
          allchilddatas[num] = {
            value: recursion[element].id,
            label: recursion[element].name,
            children: child,
          };
        } else {
          allchilddatas[num] = {
            value: recursion[element].id,
            label: recursion[element].name,
          };
        }
        num = num + 1;
      });
      return allchilddatas;
    },
    // 平台来源-加载
    getplatform(configinfo) {
      //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      // alldatas[num] = { value: num, label: "全部" };
      for (var key in configinfo) {
        alldatas[num] = {
          value: configinfo[key].value,
          label: configinfo[key].name,
        };
        num = num + 1;
      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.maintwoforminfo.selecttop.select[0].options = alldatas;
      this.myselecttop.select[0].options = alldatas;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // 获取-搜索框-下拉内容
      axios
        .get("/shop/shareorder/index", {
          headers: {
            Authen: localStorage.getItem("token"),
          },
          params: {},
        })
        .then((response) => {
          // console.log(response.data.result);
          let datas = response.data.result;
          this.getpaystatus(datas.pay_status);
          this.getpaytype(datas.paytype);
          this.getprintstatus(datas.print_status);
          this.getprinttype(datas.printtype);
          this.getplatform(datas.platform);
        })
        .catch(function (error) {
          console.log(error);
        });

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      // console.log(this.$route.query.tradeno);

      // 初始化-pagesdatas-维护字段
      // 定义
      this.pagesdatas = {
        platform: "1-1",
        page: 1,
        page_size: this.PageSize,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      // 平台来源
      if (routerinfo.thirdparty) {
        this.pagesdatas["platform"] = routerinfo.thirdparty;
        this.maintwoforminfo.selecttop.select[0].value = routerinfo.thirdparty;
        this.myselecttop.select[0].value = routerinfo.thirdparty;
      }
      // 订单编号
      if (routerinfo.tradeno) {
        this.pagesdatas["trade_no"] = routerinfo.tradeno;
        this.myinput.input[1].content = routerinfo.tradeno;
      }
      // 设备编号
      if (routerinfo.clientnum) {
        this.maintwoforminfo.myinputonefull.input[0].content = routerinfo.clientnum;
        this.myinputonefullgao.input[0].content = routerinfo.clientnum;
        axios
          .get("/shop/Equipment/searchClient", {
            headers: {
              Authen: localStorage.getItem("token"),
            },
            params: { client: routerinfo.clientnum },
          })
          .then((response) => {
            if (response.data.code == 0) {
              let getdata = response.data.result.data;
              this.pagesdatas["client"] = getdata[0].id;
              this.maintwoforminfo.myinputonefull.input[0].id = getdata[0].id;
              this.myinputonefullgao.input[0].id = getdata[0].id;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      // 支付时间
      if (routerinfo.paytime) {
        this.pagesdatas["timerange"] = routerinfo.paytime;
      }

      setTimeout(() => {
        //通过Ajax向后台获取数据
        axios
          .post("/shop/shareorder/index", this.pagesdatas, {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.myaxiosfunc(response.data.result);
          })
          .catch(function (error) {
            console.log(error);
          });
      }, 500);
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>