import { createApp } from 'vue'
import axios from 'axios'
import QiweiQrcode from './QiweiQrcode'

let instance
let dom

const qrcode = {
    load() {
        axios.post('/shop/User/corpWechat', undefined, { headers: { Authen: localStorage.getItem('token') } }).then((payload) => {
            const { code, result } = payload.data
            if (code == 0) {
                if (result.wecom_url) {
                    if (!instance) qrcode.show(result.wecom_url)
                } else qrcode.close()
            }
        })
    },

    show(qrcodeUrl) {
        instance = createApp(QiweiQrcode, { qrcodeUrl, refresh: qrcode.load })
        dom = document.createElement('div')
        instance.mount(dom)
        document.body.append(dom)
    },

    close() {
        if (instance) {
            instance.unmount()
            instance = null
        }
        if (dom) {
            document.body.removeChild(dom)
            dom = null
        }

    }
}

export default qrcode
