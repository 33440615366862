<template>
  <div id="mymain">
    <div id="mymaintwo" style="
        background-color: #ffffff;
        border-radius: 4px;
        color: #97a0c3;
        line-height: 60px;
        padding: 0 20px;
      ">
      <!--筛选查询-->
      <div v-show="show">
        <el-form :label-position="labelPosition" :inline="true" :model="formInline" ref="formInline">
          <el-row>
            <el-col :span="24">
              <div class="flexbox-one">
                <div class="flexbox-one-select">
                  <mainselect :myselectleftisshow="myselecttopisshow" :myselectleft="myselecttop"></mainselect>
                  <maininput :myinputonefullisshow="myinputonefullisshow" :myinputonefull="myinputonefull"></maininput>
                  <maininput :myinputoneisshow="myinputoneisshow" :myinputone="myinputone"></maininput>
                  <mainselect :myselectleftisshow="myselectleftisshow" :myselectleft="myselectleft"></mainselect>
                  <mainselect :myselectcityleftisshow="myselectcityleftisshow" :myselectcity="myselectcityleft">
                  </mainselect>
                  <slot name="searchModel"></slot>
                </div>
                <div class="flexbox-one-button">
                  <el-button type="primary"
                    @click="onSubmit(myinputone, myselectleft, myinputonefull, myselecttop, myselectcityleft)">查询
                  </el-button>
                  <el-button type="primary" @click="clickGaojiEvent()" v-if="maintwoiconshow">高级检索</el-button>
                  <el-button
                    @click="resetForm(myinputone, myselectleft, myinputonefull, myselecttop, myselectcityleft)">
                    重置</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>

    <!--弹出框-高级筛选-->
    <el-dialog v-model="dialogVisible" title="高级筛选" :width="this.searchwidth" :before-close="handleCloseSenior">
      <el-form ref="seniorform" :model="seniorform">
        <div style="
            max-height: 550px;
            overflow: hidden;
            overflow-y: visible;
            margin-bottom: 20px;
          ">
          <el-form-item>
            <mainselect :myselectisshow="myselectuptopisshow" :myselect="myselectuptop"></mainselect>
            <maininput :myinputonefullgaoisshow="myinputonefullgaoisshow" :myinputonefullgao="myinputonefullgao">
            </maininput>
            <maininput :myinputishow="myinputishow" :myinput="myinput"></maininput>
            <mainselect :myselectisshow="myselectisshow" :myselect="myselect"></mainselect>
            <mainselect :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity"></mainselect>
          </el-form-item>
        </div>
        <el-form-item style="text-align: center">
          <el-button type="primary"
            @click="onSubmitSenior(myinput, myselect, myselectcity, myinputonefullgao, myselectuptop)"
            class="main_two_search" style="width: 40%; margin-right: 5px">开始检索
          </el-button>
          <el-button type="info" plain
            @click="resetFormSenior(myinput, myselect, myselectcity, myinputonefullgao, myselectuptop)"><i
              class="el-icon-refresh-left"></i> 重置
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import maininput from "@/components/Maininput";
import mainselect from "@/components/Mainselect";
// import {ElMessage} from "element-plus";

export default defineComponent({
  name: "mymaintwo",
  inject: ["reload"],
  data() {
    return {
      seniorform: {},
      arrowicon: "el-icon-arrow-up",
      arrowname: "收起筛选",
      show: true,
      labelPosition: "left",
      formInline: {
        info: null,
      },
      searchwidth: "580px",

      dialogVisible: false,
    };
  },
  props: [
    "maintwoiconshow",
    "maintwoforminfo",
    "myinputishow",
    "myinput",
    "myinputoneisshow",
    "myinputone",
    "myselectleftisshow",
    "myselectleft",
    "myselecttopisshow",
    "myselecttop",
    "myselectisshow",
    "myselect",
    "myselectuptopisshow",
    "myselectuptop",
    "myselectcityisshow",
    "myselectcity",
    "myinputonefullisshow",
    "myinputonefull",
    "myinputonefullgaoisshow",
    "myinputonefullgao",
    "myselectcityleftisshow",
    "myselectcityleft",
  ],
  setup() {
    // const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done();
    };
    return {
      // dialogVisible,
      handleCloseSenior,
    };
  },
  mounted() {
    this.formInline.info = this.maintwoforminfo;
  },
  methods: {
    clickGaojiEvent(data) {
      if (data) {
        this.dialogVisible = false
      } else {
        this.dialogVisible = true
      }
    },
    // 点击enter-触发查询
    onkeydownEvent() {
      document.onkeydown = (e) => {
        e = window.event || e;
        if (e.keyCode === 13 && e.target.parentNode.className != "el-input el-input--mini el-pagination__editor is-in-pagination") {
          if (this.dialogVisible != true) {
            this.onSubmit(this.myinputone, this.myselectleft, this.myinputonefull, this.myselecttop, this.myselectcityleft)
          } else {
            setTimeout(()=>{
              this.onSubmitSenior(this.myinput, this.myselect, this.myselectcity, this.myinputonefullgao, this.myselectuptop)
            },500)
          }
        }
      };
    },


    showclose(show) {
      if (show === true) {
        this.show = false;
        this.arrowicon = "el-icon-arrow-down";
        this.arrowname = "展开筛选";
      } else {
        this.show = true;
        this.arrowicon = "el-icon-arrow-up";
        this.arrowname = "收起筛选";
      }
    },
    onSubmit(myinputone, myselectleft, myinputonefull, myselecttop, myselectcityleft) {
      //获取输入框字段与字段名并合成json数据
      var formlist = {};
      if (myinputone) {
        for (var i = 0; i < myinputone.input.length; i++) {
          formlist[myinputone.input[i].name] = myinputone.input[i].content;
        }
      }
      if (myselectleft) {
        for (var j = 0; j < myselectleft.select.length; j++) {
          if (
            JSON.stringify(myselectleft.select[j].value.valueOf()).length === 13
          ) {
            //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
            formlist[myselectleft.select[j].name] =
              myselectleft.select[j].value.valueOf() / 1000;
          } else {
            formlist[myselectleft.select[j].name] =
              myselectleft.select[j].value;
          }
          if (
            myselectleft.select[j].selecttimeduanisshow === true &&
            myselectleft.select[j].value.valuestart !== ref("")
          ) {
            if (
              JSON.stringify(myselectleft.select[j].value.valuestart.valueOf())
                .length === 13
            ) {
              //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
              formlist[myselectleft.select[j].name]["start"] =
                myselectleft.select[j].value.valuestart.valueOf() / 1000;
            } else {
              formlist[myselectleft.select[j].name]["start"] =
                myselectleft.select[j].value.valuestart;
            }
            if (
              JSON.stringify(myselectleft.select[j].value.valueend.valueOf())
                .length === 13
            ) {
              //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
              formlist[myselectleft.select[j].name]["end"] =
                myselectleft.select[j].value.valueend.valueOf() / 1000;
            } else {
              formlist[myselectleft.select[j].name]["end"] =
                myselectleft.select[j].value.valueend;
            }
          }
        }
      }
      if (myselecttop) {
        for (var k = 0; k < myselecttop.select.length; k++) {
          if (
            JSON.stringify(myselecttop.select[k].value.valueOf()).length === 13
          ) {
            //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
            formlist[myselecttop.select[k].name] =
              myselecttop.select[k].value.valueOf() / 1000;
          } else {
            formlist[myselecttop.select[k].name] =
              myselecttop.select[k].value;
          }
          if (
            myselecttop.select[k].selecttimeduanisshow === true &&
            myselecttop.select[k].value.valuestart !== ref("")
          ) {
            if (
              JSON.stringify(myselecttop.select[k].value.valuestart.valueOf())
                .length === 13
            ) {
              //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
              formlist[myselecttop.select[k].name]["start"] =
                myselecttop.select[k].value.valuestart.valueOf() / 1000;
            } else {
              formlist[myselecttop.select[k].name]["start"] =
                myselecttop.select[k].value.valuestart;
            }
            if (
              JSON.stringify(myselecttop.select[k].value.valueend.valueOf())
                .length === 13
            ) {
              //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
              formlist[myselecttop.select[k].name]["end"] =
                myselecttop.select[k].value.valueend.valueOf() / 1000;
            } else {
              formlist[myselecttop.select[k].name]["end"] =
                myselecttop.select[k].value.valueend;
            }
          }
        }
      }
      if (myinputonefull) {
        for (var p = 0; p < myinputonefull.input.length; p++) {
          if (myinputonefull.input[p].content === "") {
            formlist[myinputonefull.input[p].name] = "";
          } else {
            formlist[myinputonefull.input[p].name] = myinputonefull.input[p].id;
          }
        }
      }
      if (myselectcityleft) {
        // !myselectcityleft【!+参数 在if条件中表示检测该参数未定义】
        // console.log("有定义" + myselectcityleft);
        if (myselectcityleft.select.valuequ !== "") {
          if (
            myselectcityleft.select.valuequ === "0" ||
            myselectcityleft.select.valuequ === "暂无相关县/区"
          ) {
            //这里的判断根据Maintwo中的valuequ值而判断
            formlist[myselectcityleft.select.name] =
              myselectcityleft.select.valueshi;
          } else {
            formlist[myselectcityleft.select.name] =
              myselectcityleft.select.valuequ;
          }
        } else {
          if (myselectcityleft.select.valueshi !== "") {
            if (
              myselectcityleft.select.valueshi === "0" ||
              myselectcityleft.select.valueshi === "暂无相关市"
            ) {
              formlist[myselectcityleft.select.name] =
                myselectcityleft.select.valuesheng;
            } else {
              formlist[myselectcityleft.select.name] =
                myselectcityleft.select.valueshi;
            }
          } else {
            formlist[myselectcityleft.select.name] =
              myselectcityleft.select.valuesheng;
          }
        }
      }
      //将数据传向父组件
      this.$emit("getsearchinfo", formlist);
    },
    resetForm(myinputone, myselectleft, myinputonefull, myselecttop, myselectcityleft) {
      if (myinputone) {
        for (var i = 0; i < myinputone.input.length; i++) {
          myinputone.input[i].content = "";
        }
      }
      if (myselectleft) {
        for (var j = 0; j < myselectleft.select.length; j++) {
          if (myselectleft.select[j].selecttimeduanisshow === true) {
            myselectleft.select[j].value.valuestart = "";
            myselectleft.select[j].value.valueend = "";
            myselectleft.select[j].value.endstatus = true;
          } else {
            if (myselectleft.select[j].valueisshow === true) {
              myselectleft.select[j].value =
                myselectleft.select[j].valuedefault; //重置后，默认值赋给显示值
            } else {
              myselectleft.select[j].value = "";
            }
          }
        }
      }
      if (myselecttop) {
        for (var m = 0; m < myselecttop.select.length; m++) {
          if (myselecttop.select[m].selecttimeduanisshow === true) {
            myselecttop.select[m].value.valuestart = "";
            myselecttop.select[m].value.valueend = "";
            myselecttop.select[m].value.endstatus = true;
          } else {
            if (myselecttop.select[m].valueisshow === true) {
              myselecttop.select[m].value =
                myselecttop.select[m].valuedefault; //重置后，默认值赋给显示值
            } else {
              myselecttop.select[m].value = "";
            }
          }
        }
      }
      if (myinputonefull) {
        for (var k = 0; k < myinputonefull.input.length; k++) {
          myinputonefull.input[k].content = "";
          myinputonefull.input[k].id = "";
        }
      }
      if (myselectcityleft) {
        // !myselectcityleft【!+参数 在if条件中表示检测该参数未定义】
        myselectcityleft.select.valuesheng = "";
        myselectcityleft.select.valueshi = "";
        myselectcityleft.select.valuequ = "";
        myselectcityleft.select.disshi = true;
        myselectcityleft.select.disqu = true;
      }

      this.reload(); //刷新
    },
    onSubmitSenior(myinputformdata, myselectformdata, myselectcityformdata, myinputonefullgao, myselectuptop) {
      //高级筛选-开始检索
      var myinputform = myinputformdata;
      var myselectform = myselectformdata;
      var myselectcityform = myselectcityformdata;

      this.dialogVisible = false; //隐藏高级筛选模块
      //获取输入框字段与字段名并合成json数据
      var seniorformlist = {};
      if (myinputform) {
        for (var i = 0; i < myinputform.input.length; i++) {
          seniorformlist[myinputform.input[i].name] =
            myinputform.input[i].content;
        }
      }
      if (myselectform) {
        for (var j = 0; j < myselectform.select.length; j++) {
          if (
            JSON.stringify(myselectform.select[j].value.valueOf()).length === 13
          ) {
            //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
            seniorformlist[myselectform.select[j].name] =
              myselectform.select[j].value.valueOf() / 1000;
          } else {
            seniorformlist[myselectform.select[j].name] =
              myselectform.select[j].value;
          }
          if (
            myselectform.select[j].selecttimeduanisshow === true &&
            myselectform.select[j].value.valuestart !== ref("")
          ) {
            if (
              JSON.stringify(myselectform.select[j].value.valuestart.valueOf())
                .length === 13
            ) {
              //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
              seniorformlist[myselectform.select[j].name]["start"] =
                myselectform.select[j].value.valuestart.valueOf() / 1000;
            } else {
              seniorformlist[myselectform.select[j].name]["start"] =
                myselectform.select[j].value.valuestart;
            }
            if (
              JSON.stringify(myselectform.select[j].value.valueend.valueOf())
                .length === 13
            ) {
              //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
              seniorformlist[myselectform.select[j].name]["end"] =
                myselectform.select[j].value.valueend.valueOf() / 1000;
            } else {
              seniorformlist[myselectform.select[j].name]["end"] =
                myselectform.select[j].value.valueend;
            }
          }
        }
      }
      if (myselectuptop) {
        for (var m = 0; m < myselectuptop.select.length; m++) {
          if (
            JSON.stringify(myselectuptop.select[m].value.valueOf()).length === 13
          ) {
            //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
            seniorformlist[myselectuptop.select[m].name] =
              myselectuptop.select[m].value.valueOf() / 1000;
          } else {
            seniorformlist[myselectuptop.select[m].name] =
              myselectuptop.select[m].value;
          }
          if (
            myselectuptop.select[m].selecttimeduanisshow === true &&
            myselectuptop.select[m].value.valuestart !== ref("")
          ) {
            if (
              JSON.stringify(myselectuptop.select[m].value.valuestart.valueOf())
                .length === 13
            ) {
              //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
              seniorformlist[myselectuptop.select[m].name]["start"] =
                myselectuptop.select[m].value.valuestart.valueOf() / 1000;
            } else {
              seniorformlist[myselectuptop.select[m].name]["start"] =
                myselectuptop.select[m].value.valuestart;
            }
            if (
              JSON.stringify(myselectuptop.select[m].value.valueend.valueOf())
                .length === 13
            ) {
              //此判断value值是否为13位的时间戳，若是/1000，若不是则直接返回当前value值
              seniorformlist[myselectuptop.select[m].name]["end"] =
                myselectuptop.select[m].value.valueend.valueOf() / 1000;
            } else {
              seniorformlist[myselectuptop.select[m].name]["end"] =
                myselectuptop.select[m].value.valueend;
            }
          }
        }
      }
      if (myselectcityform) {
        // !myselectcityform【!+参数 在if条件中表示检测该参数未定义】
        // console.log("有定义" + myselectcityform);
        if (myselectcityform.select.valuequ !== "") {
          if (
            myselectcityform.select.valuequ === "0" ||
            myselectcityform.select.valuequ === "暂无相关县/区"
          ) {
            //这里的判断根据Maintwo中的valuequ值而判断
            seniorformlist[myselectcityform.select.name] =
              myselectcityform.select.valueshi;
          } else {
            seniorformlist[myselectcityform.select.name] =
              myselectcityform.select.valuequ;
          }
        } else {
          if (myselectcityform.select.valueshi !== "") {
            if (
              myselectcityform.select.valueshi === "0" ||
              myselectcityform.select.valueshi === "暂无相关市"
            ) {
              seniorformlist[myselectcityform.select.name] =
                myselectcityform.select.valuesheng;
            } else {
              seniorformlist[myselectcityform.select.name] =
                myselectcityform.select.valueshi;
            }
          } else {
            seniorformlist[myselectcityform.select.name] =
              myselectcityform.select.valuesheng;
          }
        }
      }
      if (myinputonefullgao) {
        for (var k = 0; k < myinputonefullgao.input.length; k++) {
          if (myinputonefullgao.input[k].content === "") {
            seniorformlist[myinputonefullgao.input[k].name] = "";
          } else {
            seniorformlist[myinputonefullgao.input[k].name] = myinputonefullgao.input[k].id;
          }
        }
      }
      //将数据传向父组件
      this.$emit("searchForm", seniorformlist);
    },
    resetFormSenior(myinputform, myselectform, myselectcityform, myinputonefullgao, myselectuptop) {
      //高级筛选-重置
      console.log('reset');
      if (myinputform) {
        for (var i = 0; i < myinputform.input.length; i++) {
          myinputform.input[i].content = "";
        }
      }
      if (myselectform) {
        for (var j = 0; j < myselectform.select.length; j++) {
          if (myselectform.select[j].selecttimeduanisshow === true) {
            myselectform.select[j].value.valuestart = "";
            myselectform.select[j].value.valueend = "";
            myselectform.select[j].value.endstatus = true;
          } else {
            if (myselectform.select[j].valueisshow === true) {
              myselectform.select[j].value =
                myselectform.select[j].valuedefault; //重置后，默认值赋给显示值
            } else {
              myselectform.select[j].value = "";
            }
          }
        }
      }
      if (myselectuptop) {
        for (var m = 0; m < myselectuptop.select.length; m++) {
          if (myselectuptop.select[m].selecttimeduanisshow === true) {
            myselectuptop.select[m].value.valuestart = "";
            myselectuptop.select[m].value.valueend = "";
            myselectuptop.select[m].value.endstatus = true;
          } else {
            if (myselectuptop.select[m].valueisshow === true) {
              myselectuptop.select[m].value =
                myselectuptop.select[m].valuedefault; //重置后，默认值赋给显示值
            } else {
              myselectuptop.select[m].value = "";
            }
          }
        }
      }
      if (myselectcityform) {
        // !myselectcityform【!+参数 在if条件中表示检测该参数未定义】
        myselectcityform.select.valuesheng = "";
        myselectcityform.select.valueshi = "";
        myselectcityform.select.valuequ = "";
        myselectcityform.select.disshi = true;
        myselectcityform.select.disqu = true;
      }
      if (myinputonefullgao) {
        for (var k = 0; k < myinputonefullgao.input.length; k++) {
          myinputonefullgao.input[k].content = "";
          myinputonefullgao.input[k].id = "";
        }
      }
    },
    getwindowinfo() {
      //获取当前windows页面信息-实时修改【高级筛选】的宽度
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },
  },
  created() {
    window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
    this.getwindowinfo();

    // 点击回车-查询
    this.onkeydownEvent();
  },
  components: {
    maininput,
    mainselect,
  },
});
</script>

<style scoped>
.el-form--inline .el-form-item {
  display: flex;

}

.flexbox-one {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexbox-one-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexbox-one-button {
  min-width: 160px;
}
</style>
