<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">修改账户信息</div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :md="4" :lg="5" :xl="6">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :md="16" :lg="12" :xl="10">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="180px"
                class="demo-ruleForm"
              >
                <!--姓名-->
                <el-form-item label="姓名：" prop="name">
                  <el-input v-model="ruleForm.name" disabled></el-input>
                </el-form-item>
                <!--手机号-->
                <el-form-item label="手机号：" prop="username">
                  <el-input
                    v-model="ruleForm.username"
                    placeholder="用于登录系统用户名"
                    disabled
                  ></el-input>
                </el-form-item>
                <!--提现微信-->
                <el-form-item label="提现微信：" prop="wechat_user_id">
                  <mainwechat
                    style="float: left"
                    ref="wechat"
                    v-model="ruleForm.wechat_user_id"
                    :mycashoutisshow="mycashoutisshow"
                    :mycashout="mycashout"
                    v-on:getpayaccount="
                      (data) => getzhanghuCodeEvent(data, 'wechat')
                    "
                    v-on:getchangepaytype="getChangePaytypeEvent"
                    v-on:getisshow="changeGetisshowEvent"
                  ></mainwechat>
                </el-form-item>
				<el-form-item label="提现支付宝：" prop="alipay_user_ids">
					<div class="edit-box-user-info" style="color:black">
							<div class="edit-box-user-content">
								<div class="edit-box-user-title">支付宝名称：{{ ruleForm.alipaycwechat.ali_nickname }}</div>
							</div>
							<div class="edit-box-user-content">
								<div class="edit-box-user-title">支付宝账号/手机号：{{ ruleForm.alipaycwechat.ali_user_id }}</div>
							</div>
					</div>
				</el-form-item>
                <!--提现支付宝-->
                <el-form-item label="提现支付宝：" prop="alipay_user_id" style="display: none;">
                  <mainwechat
                    style="float: left"
                    ref="paychat"
                    v-model="ruleForm.alipay_user_id"
                    :mycashoutisshow="mycashoutisshow"
                    :mycashout="mycashout1"
                    v-on:getpayaccount="
                      (data) => getzhanghuCodeEvent(data, 'paychat')
                    "
                    v-on:getchangepaytype="getChangePaytypeEvent"
                    v-on:getisshow="changeGetisshowEvent"
                  ></mainwechat>
                </el-form-item>
                <!--button按钮-->
                <el-form-item style="margin-top: 50px">
                  <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                    style="width: 40%"
                    >提交</el-button
                  >
                  <el-button @click="resetForm('ruleForm')">取消</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :md="4" :lg="7" :xl="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
import mainwechat from "@/components/Mainwechat.vue";
import { ElMessageBox } from "element-plus";
import axios from "axios";

export default {
  name: "vchfn",
  data() {
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "账户总览",
          path: "/account",
        },
        {
          name: "修改账户信息",
          path: "/agentlistadd",
        },
      ], // 当前页-【面包屑导航】

      mycashoutisshow: true, //form表单-提现微信/支付宝（单个组件）
      //提现微信
      mycashout: {
        ide: "wechat", // 必填标识：微信（'wechat'）,支付宝（'alipay'）
        titlewidth: "180px",
        cashout: [
          {
            title: "提现微信：",
            ismust: true, //是否显示必填红标
            isshowedit: false, //控制-首次进入页面时-编辑用户信息模块
            isshowadd: true, //控制-首次进入页面时-添加提现信息的模块
            avatarinfo: {
              id: 1,
              avatar:
                "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
              nickname: "用户张三",
              mobile: "18738573256",
            }, //显示-首次进入页面时-提现用户信息
            tantitle: "请选择提现微信", //弹窗-title名称
            tantable: {
              mainnick: "微信昵称",
              searchpder: "请输入 微信昵称 / 手机号 查询",
            }, //弹窗-table中的需改变的中文字段
            addtitle: "添加提现微信", //添加-按钮的中文
            addcolor: "#04BE02", //添加-按钮的颜色
            paytype: 1, //默认提现方式 - 0表示无，1微信，2支付宝
            paytypeisshow: false, //默认提现方式显示 -默认显示false，不显示true
          },
        ],
      },
      //提现支付宝
      mycashout1: {
        ide: "alipay",
        titlewidth: "180px",
        cashout: [
          {
            title: "提现支付宝：",
            ismust: true, //是否显示必填红标
            isshowedit: false, //控制-首次进入页面时-编辑用户信息模块
            isshowadd: true, //控制-首次进入页面时-添加提现信息的模块
            avatarinfo: {
              id: 1,
              avatar:
                "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
              nickname: "用户李四",
              mobile: "18738573256",
            }, //显示-首次进入页面时-提现用户信息
            tantitle: "请选择提现支付宝", //弹窗-title名称
            tantable: {
              mainnick: "支付宝昵称",
              searchpder: "请输入 支付宝昵称 / 手机号 查询",
            }, //弹窗-table中的需改变的中文字段
            addtitle: "添加提现支付宝", //添加-按钮的中文
            addcolor: "#0571dc", //添加-按钮的颜色
            paytype: 2, //默认提现方式 - 0表示无，1微信，2支付宝
            paytypeisshow: false, //默认提现方式显示 -默认显示false，不显示true
          },
        ],
      },

      //form表单数据
      ruleForm: {
        name: "",
        username: "",
        wechat_user_id: "",
        alipay_user_id: "",
        paytype: 1, //默认提现方式 1微信，2支付宝
      },
      //form表单填写规则-前端判断是否必填
      rules: {
        name: [
          {
            required: true,
            message: "代理商姓名为必填项",
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: "手机号/账号为必填项",
            trigger: "blur",
          },
        ],
        wechat_user_id: [
          {
            required: false,
            message: "提现微信必填项",
            trigger: "blur",
          },
        ],
        alipay_user_id: [
          {
            required: false,
            message: "提现支付宝必填项",
            trigger: "blur",
          },
        ],
      },

      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  mounted() {},
  methods: {
    //删除后切换默认提现方式
    getChangePaytypeEvent(val) {
      console.log("回传的值：" + val);
      if (
        this.mycashout.cashout[0].isshowadd == true &&
        this.mycashout1.cashout[0].isshowadd == true
      ) {
        this.ruleForm.paytype = 0;
        this.mycashout.cashout[0].paytype = 0;
        this.mycashout1.cashout[0].paytype = 0;
      } else {
        this.ruleForm.paytype = val;
        this.mycashout.cashout[0].paytype = val;
        this.mycashout1.cashout[0].paytype = val;
      }
      console.log("之后：" + this.ruleForm.paytype);
    },
    //判断默认提现方式是否都为添加状态
    changeGetisshowEvent(val) {
      console.log("changeGetisshowEvent:" + val);
      if (val.ide == "wechat") {
        this.mycashout.cashout[0].isshowedit = false;
        this.mycashout.cashout[0].isshowadd = true;
      } else {
        this.mycashout1.cashout[0].isshowedit = false;
        this.mycashout1.cashout[0].isshowadd = true;
      }
    },
    //获取支付账号公共组件
    getzhanghuCodeEvent(data, payaccount) {
      if (payaccount == "wechat") {
        console.log("获取返回的（微信）数据：" + JSON.stringify(data));
        this.ruleForm.wechat_user_id = data.id;
      } else {
        console.log("获取返回的（支付宝）数据：" + JSON.stringify(data));
        this.ruleForm.alipay_user_id = data.id;
      }
    },
    //提交form表单-并校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(
            "当前提交成功-获取的form表单值：" +
              JSON.stringify(this.$refs[formName].model)
          );
          var getdatas = this.$refs[formName].model;
          
          // 判断是否存在-默认提现方式
          if(getdatas.wechat_user_id != 0 && getdatas.alipay_user_id != 0){
            if(getdatas.paytype == 0){
              getdatas.paytype = 1
            }
          }else{
            if(getdatas.wechat_user_id != 0 && getdatas.alipay_user_id == 0){
              getdatas.paytype = 1
            }
            if(getdatas.wechat_user_id == 0 && getdatas.alipay_user_id != 0){
              getdatas.paytype = 2
            }
          }

          axios
            .post(
              "/shop/ShopSet/accountUpdate",
              {
                default_pay: getdatas.paytype,
                wechat_user_id: getdatas.wechat_user_id,
                alipay_user_id: getdatas.alipay_user_id,
              },
              {
                headers: {
                  Authen: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessageBox.alert(response.data.result, "提示:", {
                  confirmButtonText: "知道了",
                  callback: () => {
                    // 成功之后返回上一级
                    this.$router.go(-1); //返回上一层
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置form表单中填写的数据
    resetForm() {
      this.$router.go(-1); //返回上一层
      // this.$refs[formName].resetFields();
      // // 清空提现微信/支付宝[仅限于一个微信，一个支付宝（待优化）]
      // this.$refs.wechat.delWechatClient(0);
      // this.$refs.paychat.delWechatClient(0);
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      
      //通过Ajax向后台获取数据
      axios
        .post(
          "/shop/ShopSet/accountInfo",
          {},
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.result);
          var routedatas = response.data.result;
          this.ruleForm = response.data.result;
          var ruleform = this.ruleForm;
          ruleform.name = routedatas.name; //通过路由获取-账户名称
          ruleform.username = routedatas.username; //通过路由获取-手机号||账号

          // ruleform-存初始值-默认提现方式
          ruleform.paytype = routedatas.default_pay;

          // 返显-提现-微信&支付宝
          if (routedatas.wechat_user_info) {
            if (routedatas.wechat_user_info == null || routedatas.wechat_user_info == "") {
              this.mycashout.cashout[0].isshowedit = false;
              this.mycashout.cashout[0].isshowadd = true;
            } else {
              this.mycashout.cashout[0].isshowedit = true;
              this.mycashout.cashout[0].isshowadd = false;
              this.mycashout.cashout[0].avatarinfo = routedatas.wechat_user_info;
              this.mycashout.cashout[0].paytype = routedatas.default_pay; // 返显-默认提现方式
            }
          } else {
            this.mycashout.cashout[0].isshowedit = false;
            this.mycashout.cashout[0].isshowadd = true;
          }
          if (routedatas.alipay_user_info) {
            if (routedatas.alipay_user_info == null || routedatas.alipay_user_info == "") {
              this.mycashout1.cashout[0].isshowedit = false;
              this.mycashout1.cashout[0].isshowadd = true;
            } else {
              this.mycashout1.cashout[0].isshowedit = true;
              this.mycashout1.cashout[0].isshowadd = false;
              this.mycashout1.cashout[0].avatarinfo = routedatas.alipay_user_info;
              this.mycashout1.cashout[0].paytype = routedatas.default_pay; // 返显-默认提现方式
            }
          } else {
            this.mycashout1.cashout[0].isshowedit = false;
            this.mycashout1.cashout[0].isshowadd = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    mainwechat,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #97a0c3;
  padding: 5px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #17418f;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
}
</style>
